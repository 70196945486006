.AriaInfo-root-ac26922b116bd99c170b97a18c17d290 {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.AppBar-root-3821bb2040743de7ae630caa84165d1a {
  background-color: #FFFFFF;
  border-bottom: 1px solid var(--palette-divider);
}

.AppBar-container-7fcca427e1c56063c6c125c33e214e4e {
  max-width: 1280px;
  margin: 0 auto;
  height: calc(8 * var(--mini-unit));
  box-sizing: border-box;
}

.AppBar-gutterBegin-298b934644f84cc4beeea140864b1985 {
  padding-left: calc(2 * var(--mini-unit));
}

.AppBar-gutterEnd-cb74aaeb4c7a1de45a6cc750776db07c {
  padding-right: calc(2 * var(--mini-unit));
}

.Begin-root-1cea034ed80f1d6f1d4bc38510552f98 {
  flex-grow: 1;
}

.End-root-323e91bf9ab36c9d7caa44aaf55274ab {
  flex-grow: 0;
  flex-shrink: 0;
}

.Navigation-root-ee7fbd44eb0324bf9af715e186761c25 {
  height: 100%;
}

.Navigation-ul-3c4ae4d1c3d04533c4a6b59433839418 {
  list-style: none;
  padding: 0;
  display: flex;
  height: 100%;
  margin: 0;
}

.NavigationItem-root-46fbb6c29b0693811aef6d033e5dd0be {
}
.NavigationItem-anchor-3ab9eec3d676273da5351304bffb660a {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  text-transform: uppercase;

  color: var(--palette-text-100);
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding: 0 var(--mini-unit);
  text-decoration: none;
}
.NavigationItem-anchor-3ab9eec3d676273da5351304bffb660a:hover {
    cursor: pointer;
    color: var(--palette-text-500);
    background-color: #FED5C0;
  }
.NavigationItem-active-43b689ce4a72232ff7e390528f5d9fef {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-3);
  text-transform: uppercase;

  background-color: #F77160;
  text-decoration: none;
  color: #FFFFFF;
}
.NavigationItem-active-43b689ce4a72232ff7e390528f5d9fef:hover {
    color: #FFFFFF;
    background-color: #F77160;
  }

.Divider-root-972d943fb0dfecda4bb28bdde8b82515 {
  height: 32px;
  border-right: 2px solid var(--palette-grey-400);
}

.Backdrop-root-47982d20736dba7fc685e613781b425f {
  display: none;
  background: black;
  position: fixed;
  /* fix to bottom solves some mobile scrolling issues. */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Backdrop-active-7c744ae718f57822e32b40311ae75290 {
  display: block;
  opacity: 0.5;
}

.BaseButton-root-464189d43068742128bd39653c671d89 {
  /* reset button */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: inherit;
  outline: none;
  border: none;
  touch-action: manipulation;
  padding: 0;
  margin: 0;

  /* Unify anchor and button. */
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  align-items: flex-start;
  vertical-align: middle;
  white-space: nowrap;
  background: transparent;
  font-size: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  -webkit-appearance: none;
}

  .BaseButton-root-464189d43068742128bd39653c671d89:disabled {
    cursor: default;
  }

  .BaseButton-root-464189d43068742128bd39653c671d89::-moz-focus-inner {
    border: 0;
  }

  .BaseButton-root-464189d43068742128bd39653c671d89:-moz-focusring {
    textshadow: 0 0 0 #000;
  }

.BaseButton-keyboardFocus-eeb2169f9422b05f27678691c7c16b78 {
  outline-width: 3px;
  outline-color: Highlight;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
}

.BaseButton-mouseHover-1cc9a93c503103c973666dc01b107de0 {
}

.BrandName-root-673a8b96d6b0466ecc90b08fa46a6fde {

  margin: 0;

  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  color: #F77160;

  font-size: 1.15rem;
  line-height: 1.15rem;
}

.BrandName-md-c2658a5e139ca5df9dd88a5126e6b913 {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.BrandName-lg-94793e30913c9b7eb6adcccbdc443678 {
  font-size: 2.625rem;
  line-height: 2.625rem;
}

.BrandName-alignLeft-5d1c93821c6515eaf4569d73509eed95 {
  text-align: left;
}

.BrandName-alignCenter-78d20514d1ba22b9dc3f9ccfd8608fad {
  text-align: center;
}

.BrandName-alignRight-bc3acfb1b9de7a41ad65cd09518fc240 {
  text-align: right;
}

.BrandName-alignJustify-d1e8db42fdaa117ce76ff159295cd22f {
  text-align: justify;
}

.BrandMark-base-acaff6f43cf161e97c9a8eb34f77fddf {
}

.BrandMark-md-13c56d73ea8fd51a353a566e0f7583fb {
  height: 25px;
  width: 25px;
}

.BrandMark-lg-0b5cceb1e9bd5f3bad5f13d18e9b3dbd {
  height: 58px;
  width: 59px;
}

.LogoHorizontal-base-654a637493c35cdd9117f3733cb950c8 {
  height: 34px;
  width: 89px;
}

.LogoHorizontal-hiddenTitle-3fae90c5f0c69c25775275eaad8b450d { 
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.Box-root-acb8bd0953d79380f99868ba1e9c06f2 {
}

/* Margin */

.Box-ml-1-e580cb9b9b9ba5571c81cff32085fdc7 {
  margin-left: var(--spacing-1) !important;
}

.Box-mr-1-bca7f58748dbd102ba25862b83bceb61 {
  margin-right: var(--spacing-1) !important;
}

.Box-mt-1-e2c47024fc67f4a3437c2e4d2b508d1d {
  margin-top: var(--spacing-1) !important;
}

.Box-mb-1-319a9e772101cc5469f54ceb4dfffb17 {
  margin-bottom: var(--spacing-1) !important;
}

.Box-ml-2-b72c378ba5cf7c7b7a9539281f929bac {
  margin-left: var(--spacing-2) !important;
}

.Box-mr-2-582dba15b426bee1024aa3792fb10666 {
  margin-right: var(--spacing-2) !important;
}

.Box-mt-2-a163042b8ed6d94d4773d637f9639c51 {
  margin-top: var(--spacing-2) !important;
}

.Box-mb-2-9d7a0e8488bdaff7db6da121af9d7f47 {
  margin-bottom: var(--spacing-2) !important;
}

.Box-ml-3-b4c461a61627dee56b8bb17b11c040c9 {
  margin-left: var(--spacing-3) !important;
}

.Box-mr-3-a1658654376f6ce90a8ddba7fab4cddf {
  margin-right: var(--spacing-3) !important;
}

.Box-mt-3-9e189a242826ae17fc4f07573c50e914 {
  margin-top: var(--spacing-3) !important;
}

.Box-mb-3-9860d001cd6c686a62e3c459302ce7e5 {
  margin-bottom: var(--spacing-3) !important;
}

.Box-ml-4-d32be0e9793454b23dfc26f62bd34c56 {
  margin-left: var(--spacing-4) !important;
}

.Box-mr-4-6288aeeab481e5ba836a3cd76913d3ea {
  margin-right: var(--spacing-4) !important;
}

.Box-mt-4-3b67f4f7c35b6fcb401b04e5a2cc93c5 {
  margin-top: var(--spacing-4) !important;
}

.Box-mb-4-42b411c88acfac618c07271087c2d3e4 {
  margin-bottom: var(--spacing-4) !important;
}

.Box-ml-5-a869b12350d593b30fa12b286ae3b5a9 {
  margin-left: var(--spacing-5) !important;
}

.Box-mr-5-a684ff71ba91f6cbcaff2bdcc65b9488 {
  margin-right: var(--spacing-5) !important;
}

.Box-mt-5-ad55ceb34a96d7afd0cd47bf6931dd1f {
  margin-top: var(--spacing-5) !important;
}

.Box-mb-5-6a694127fcabbf0befcf5ee12400d4d5 {
  margin-bottom: var(--spacing-5) !important;
}

.Box-ml-6-f19423df9fca626917fbbbb2918277c6 {
  margin-left: var(--spacing-6) !important;
}

.Box-mr-6-ec4a3fe464e6da60b2232a6905c9850b {
  margin-right: var(--spacing-6) !important;
}

.Box-mt-6-d23bd96cd15a056b22fd80c8a3739c5f {
  margin-top: var(--spacing-6) !important;
}

.Box-mb-6-b19eebdc1fc1b7500cb8e8aab42b8460 {
  margin-bottom: var(--spacing-6) !important;
}

.Box-ml-7-6efab9c37146a427e45b2c84e8bf9231 {
  margin-left: var(--spacing-7) !important;
}

.Box-mr-7-ab3eb8dc591b3d5aacf8d891233d8a09 {
  margin-right: var(--spacing-7) !important;
}

.Box-mt-7-39105006aefd99260d8408f2eecca474 {
  margin-top: var(--spacing-7) !important;
}

.Box-mb-7-247b62aaa96b66156f6a3cedd39bfb87 {
  margin-bottom: var(--spacing-7) !important;
}

.Box-ml-8-661a56d2ae322e4e575ef1fbdb69d909 {
  margin-left: var(--spacing-8) !important;
}

.Box-mr-8-759b578a55cd177e1c62dd22c84e74b8 {
  margin-right: var(--spacing-8) !important;
}

.Box-mt-8-dbd38bc7d3e60210a006888a821a3f9a {
  margin-top: var(--spacing-8) !important;
}

.Box-mb-8-e92864af52b0cd73fc0eeb724460dc86 {
  margin-bottom: var(--spacing-8) !important;
}

.Box-ml-9-35ed8b417b70d55a2a3fd509067a3991 {
  margin-left: var(--spacing-9) !important;
}

.Box-mr-9-0e73b0e49a6d5e5f7000ce11708e2484 {
  margin-right: var(--spacing-9) !important;
}

.Box-mt-9-57cda9e2e25ab1fdc970ce8ed385e312 {
  margin-top: var(--spacing-9) !important;
}

.Box-mb-9-8de55cd67c180eeb50b0612c185e220b {
  margin-bottom: var(--spacing-9) !important;
}

/* Padding */

.Box-pl-1-e486bdee1cc37299449cca7a1bd8b638 {
  padding-left: var(--spacing-1) !important;
}

.Box-pr-1-7ebd90a43e140027e6a28e5211cae961 {
  padding-right: var(--spacing-1) !important;
}

.Box-pt-1-7e23c8664f2d9a8441a36a273f19c360 {
  padding-top: var(--spacing-1) !important;
}

.Box-pb-1-34419e823d0677b61c460c68c85bd54b {
  padding-bottom: var(--spacing-1) !important;
}

.Box-pl-2-b92b876c144cdd135429ba948e4d462f {
  padding-left: var(--spacing-2) !important;
}

.Box-pr-2-6c9643b015a3a3f6c584dcddc1d64ae2 {
  padding-right: var(--spacing-2) !important;
}

.Box-pt-2-b7ac9e77c2629eee2eb8c48f98b03a3c {
  padding-top: var(--spacing-2) !important;
}

.Box-pb-2-1f486e79df33cfcfe8252cea47a5323e {
  padding-bottom: var(--spacing-2) !important;
}

.Box-pl-3-5a2507e4d8239da148ca201714ee197e {
  padding-left: var(--spacing-3) !important;
}

.Box-pr-3-3692465178619ee0c8e3b8d65d369b63 {
  padding-right: var(--spacing-3) !important;
}

.Box-pt-3-bfb0bb1a331a35ff7e492b500bba7031 {
  padding-top: var(--spacing-3) !important;
}

.Box-pb-3-ae67686a54a0cddab1e0b7375367cf4b {
  padding-bottom: var(--spacing-3) !important;
}

.Box-pl-4-0875a669321933d74068944ca5c998e6 {
  padding-left: var(--spacing-4) !important;
}

.Box-pr-4-33a620feec0335357060e53d46fdcafe {
  padding-right: var(--spacing-4) !important;
}

.Box-pt-4-6352f468e5b5b35dc207fd603ce1f55c {
  padding-top: var(--spacing-4) !important;
}

.Box-pb-4-8a1620c45e16153a729b90aa8d4560f9 {
  padding-bottom: var(--spacing-4) !important;
}

.Box-pl-5-0a7b83b4aae854b527cae1cc1d061790 {
  padding-left: var(--spacing-5) !important;
}

.Box-pr-5-3811c7c10fae115527893116315cb180 {
  padding-right: var(--spacing-5) !important;
}

.Box-pt-5-eafd5fc94def3dd7e6672f7deafb31d7 {
  padding-top: var(--spacing-5) !important;
}

.Box-pb-5-b3eb08ac27a85d63a6236c5403a94cd3 {
  padding-bottom: var(--spacing-5) !important;
}

.Box-pl-6-94cbc1b9d02ffabd057de33cee5e9f04 {
  padding-left: var(--spacing-6) !important;
}

.Box-pr-6-4030821a2b7030a7df24f32e3ad150ea {
  padding-right: var(--spacing-6) !important;
}

.Box-pt-6-b2928b6ff96f0fd59cc8e8ed440ff1de {
  padding-top: var(--spacing-6) !important;
}

.Box-pb-6-ce8636c267f324fae430d0b866093354 {
  padding-bottom: var(--spacing-6) !important;
}

.Box-pl-7-2590f11661010138149935d6578c763d {
  padding-left: var(--spacing-7) !important;
}

.Box-pr-7-87f05952c335db6b445e6ecd7220a7ef {
  padding-right: var(--spacing-7) !important;
}

.Box-pt-7-37f21192c145426e7eb28af85dd21202 {
  padding-top: var(--spacing-7) !important;
}

.Box-pb-7-84dfa40c867db3902f8c9d2bd60b6f12 {
  padding-bottom: var(--spacing-7) !important;
}

.Box-pl-8-4efc40c82abe7760266abf675c843ccc {
  padding-left: var(--spacing-8) !important;
}

.Box-pr-8-a3d26ce35582945222dfdbac4efe9beb {
  padding-right: var(--spacing-8) !important;
}

.Box-pt-8-939bf9ea8e3f67b25bdf2a8f57293639 {
  padding-top: var(--spacing-8) !important;
}

.Box-pb-8-e4038b172bf6f7d8f938aa7a68360a3c {
  padding-bottom: var(--spacing-8) !important;
}

.Box-pl-9-982390ce104b5dada06b91618efbd224 {
  padding-left: var(--spacing-9) !important;
}

.Box-pr-9-c675b2b934d254af06f47b56c4b96350 {
  padding-right: var(--spacing-9) !important;
}

.Box-pt-9-2a3c468b56f67761c6accb06e2da79ef {
  padding-top: var(--spacing-9) !important;
}

.Box-pb-9-f19456ed4d834655305d6d02854c6332 {
  padding-bottom: var(--spacing-9) !important;
}

/* Design tokens */

/* flat button */

/* regular button */

/* outline button */

/* text button */

/* Class Definitions */

.Button-root-06dddf3110765e1839bd4713ccb04d57 {
  border-radius: var(--round-corners);
  background-color: transparent;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid transparent;

  transition: 0.2s ease-out background-color;

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  line-height: 1;
}

.Button-root-06dddf3110765e1839bd4713ccb04d57 > * {
    margin: 0 var(--spacing-1) 0 0;
    /* convince safari */
    align-self: center;
  }

.Button-root-06dddf3110765e1839bd4713ccb04d57 > *:last-child {
    margin: 0;
  }

.Button-root-06dddf3110765e1839bd4713ccb04d57.Button-iconLeft-ac4f9819622323168304f462ea9f3c21 i {
    padding-right: var(--spacing-1);
  }

.Button-root-06dddf3110765e1839bd4713ccb04d57.Button-iconRight-fb589c1771fa5b276f963afe60f707ef i {
    padding-left: var(--spacing-1);
  }

.Button-adornmentLeft-bf5c10ff2022b5e9dbb1914eee0b7fff {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Button-adornmentRight-2e12dc18ec115713f5f1055a8823d273 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Button-uppercase-dfe206242baf2c42522aa23e22653677 {
  text-transform: uppercase;
  letter-spacing: 0.042em;
}

.Button-root-06dddf3110765e1839bd4713ccb04d57.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636 {
  opacity: 0.4;
  cursor: default;
}

.Button-fullWidth-9843388778b59b51dc2af9481281c199 {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.Button-sizeSmall-33ac79f977ce4dbaab776128845f4993 {
  font-size: var(--font-size-1);
}

.Button-sizeSmall-33ac79f977ce4dbaab776128845f4993:not(.Button-variantText-d956142608c4a9585747d0630c084cab) {
    padding: var(--spacing-1) var(--spacing-2);
  }

.Button-sizeSmall-33ac79f977ce4dbaab776128845f4993:not(.Button-variantText-d956142608c4a9585747d0630c084cab).Button-iconLeft-ac4f9819622323168304f462ea9f3c21,
    .Button-sizeSmall-33ac79f977ce4dbaab776128845f4993:not(.Button-variantText-d956142608c4a9585747d0630c084cab).Button-iconRight-fb589c1771fa5b276f963afe60f707ef {
      padding-left: var(--spacing-2);
      padding-right: var(--spacing-2);
    }

.Button-sizeRegular-e0a46b1b7772c2d5757f5c4bab1b44d2 {
  font-size: var(--font-size-1);
}

.Button-sizeRegular-e0a46b1b7772c2d5757f5c4bab1b44d2:not(.Button-variantText-d956142608c4a9585747d0630c084cab) {
    padding: var(--spacing-2) var(--spacing-3);
  }

.Button-sizeRegular-e0a46b1b7772c2d5757f5c4bab1b44d2:not(.Button-variantText-d956142608c4a9585747d0630c084cab).Button-iconLeft-ac4f9819622323168304f462ea9f3c21,
    .Button-sizeRegular-e0a46b1b7772c2d5757f5c4bab1b44d2:not(.Button-variantText-d956142608c4a9585747d0630c084cab).Button-iconRight-fb589c1771fa5b276f963afe60f707ef {
      padding-left: var(--spacing-2);
      padding-right: var(--spacing-2);
    }

.Button-sizeLarge-5d4ccdf9c85d54100608dcecae7f4df0 {
  font-size: var(--font-size-2);
}

.Button-sizeLarge-5d4ccdf9c85d54100608dcecae7f4df0:not(.Button-variantText-d956142608c4a9585747d0630c084cab) {
    padding: var(--spacing-3) var(--spacing-5);
  }

.Button-sizeLarge-5d4ccdf9c85d54100608dcecae7f4df0:not(.Button-variantText-d956142608c4a9585747d0630c084cab).Button-iconLeft-ac4f9819622323168304f462ea9f3c21,
    .Button-sizeLarge-5d4ccdf9c85d54100608dcecae7f4df0:not(.Button-variantText-d956142608c4a9585747d0630c084cab).Button-iconRight-fb589c1771fa5b276f963afe60f707ef {
      padding-left: var(--spacing-4);
      padding-right: var(--spacing-4);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3 {
  color: var(--palette-text-000);
}

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:disabled.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a {
      background-color: var(--palette-grey-300);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:disabled.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74 {
      background-color: var(--palette-primary-200);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:disabled.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
      background-color: var(--palette-grey-300);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:disabled.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e {
      background-color: var(--palette-grey-300);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:disabled.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0 {
      background-color: var(--palette-grey-300);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:disabled.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
      background-color: var(--palette-grey-300);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a {
      background-color: #419EA7;
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: #2C7B8C;
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: #1C5B71;
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74 {
      background-color: var(--palette-primary-500);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: var(--palette-primary-600);
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: var(--palette-primary-700);
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
      background-color: var(--palette-grey-500);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: var(--palette-grey-600);
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: var(--palette-grey-700);
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e {
      background-color: var(--palette-error-500);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: var(--palette-error-600);
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: var(--palette-error-700);
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0 {
      background-color: var(--palette-success-500);
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: var(--palette-success-600);
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: var(--palette-success-700);
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05 {
      background-color: #11435D;
    }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:hover,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: #1C5B71;
      }

.Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:active,
      .Button-variantRegular-c5d613da47837370dedd96ee15b890e3:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: #2C7B8C;
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f {
  background-color: inherit;
}

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:disabled,
  .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636 {
    color: var(--palette-grey-400);
  }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a {
      color: #419EA7;
    }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: #E2FAF7;
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: #C2F9EA;
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
      color: var(--palette-grey-500);
    }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: var(--palette-grey-200);
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-active-df0190aa945791b851e57bf12c741798 {
        color: var(--palette-grey-300);
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e {
      color: var(--palette-error-500);
    }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: var(--palette-error-100);
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: var(--palette-error-200);
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0 {
      color: var(--palette-success-500);
    }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: var(--palette-success-100);
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: var(--palette-success-200);
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05 {
      color: #11435D;
    }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:hover,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        background-color: #E2FAF7;
      }

.Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:active,
      .Button-variantFlat-19e6fca3c2ef6585a257d501d477881f:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-active-df0190aa945791b851e57bf12c741798 {
        background-color: #C2F9EA;
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c {
  background-color: inherit;
}

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:disabled,
  .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636 {
    color: var(--palette-text-000);
    background-color: var(--palette-grey-300);
  }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:hover).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-active-df0190aa945791b851e57bf12c741798).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:active).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a {
      color: #419EA7;
      border-color: #419EA7;
    }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:hover).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-active-df0190aa945791b851e57bf12c741798).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:active).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74 {
      color: var(--palette-primary-500);
      border-color: var(--palette-primary-500);
    }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:hover).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-active-df0190aa945791b851e57bf12c741798).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:active).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
      color: var(--palette-grey-500);
      border-color: var(--palette-grey-500);
    }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:hover).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-active-df0190aa945791b851e57bf12c741798).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:active).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e {
      color: var(--palette-error-500);
      border-color: var(--palette-error-500);
    }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:hover).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-active-df0190aa945791b851e57bf12c741798).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:active).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0 {
      color: var(--palette-success-500);
      border-color: var(--palette-success-500);
    }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:hover).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-active-df0190aa945791b851e57bf12c741798).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:active).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05 {
      color: #11435D;
      border-color: #11435D;
    }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
    .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):hover,
    .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
    .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):hover {
      color: var(--palette-text-000);
    }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):hover.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):hover.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a {
        background-color: #419EA7;
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):hover.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):hover.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74 {
        background-color: var(--palette-primary-500);
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):hover.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):hover.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
        background-color: var(--palette-grey-500);
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):hover.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):hover.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e {
        background-color: var(--palette-error-500);
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):hover.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):hover.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0 {
        background-color: var(--palette-success-500);
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):hover.Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e.Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):hover.Button-colorDark-ad7f6f262a06efe65943a0abb5420d05 {
        background-color: #11435D;
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-active-df0190aa945791b851e57bf12c741798,
    .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):active,
    .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-active-df0190aa945791b851e57bf12c741798,
    .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):active {
      color: var(--palette-text-000);
    }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-active-df0190aa945791b851e57bf12c741798.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):active.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-active-df0190aa945791b851e57bf12c741798.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):active.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a {
        background-color: #1C5B71;
        border-color: #1C5B71;
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-active-df0190aa945791b851e57bf12c741798.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):active.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-active-df0190aa945791b851e57bf12c741798.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):active.Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74 {
        background-color: var(--palette-primary-500);
        border-color: var(--palette-primary-500);
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-active-df0190aa945791b851e57bf12c741798.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):active.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-active-df0190aa945791b851e57bf12c741798.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):active.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
        background-color: var(--palette-grey-700);
        border-color: #1C5B71;
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-active-df0190aa945791b851e57bf12c741798.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):active.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-active-df0190aa945791b851e57bf12c741798.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):active.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e {
        background-color: var(--palette-error-700);
        border-color: var(--palette-error-700);
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-active-df0190aa945791b851e57bf12c741798.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):active.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-active-df0190aa945791b851e57bf12c741798.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):active.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0 {
        background-color: var(--palette-success-700);
        border-color: var(--palette-success-700);
      }

.Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-active-df0190aa945791b851e57bf12c741798.Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636):active.Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled).Button-active-df0190aa945791b851e57bf12c741798.Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantOutline-a1f5db4f56fcac815bcfa5bc85b9f67c:not(:disabled):active.Button-colorDark-ad7f6f262a06efe65943a0abb5420d05 {
        background-color: #2C7B8C;
        border-color: #2C7B8C;
      }

.Button-variantText-d956142608c4a9585747d0630c084cab {
  background-color: transparent;
}

.Button-variantText-d956142608c4a9585747d0630c084cab:disabled,
  .Button-variantText-d956142608c4a9585747d0630c084cab.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636 {
    color: var(--palette-text-000);
  }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a, .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a {
      color: #419EA7;
    }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: #2C7B8C;
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-active-df0190aa945791b851e57bf12c741798 {
        color: #1C5B71;
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3, .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
      color: var(--palette-grey-500);
    }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: var(--palette-grey-600);
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-active-df0190aa945791b851e57bf12c741798 {
        color: var(--palette-grey-700);
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0, .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0 {
      color: var(--palette-success-500);
    }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: var(--palette-success-600);
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-active-df0190aa945791b851e57bf12c741798 {
        color: var(--palette-success-700);
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e, .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e {
      color: var(--palette-error-500);
    }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: var(--palette-error-600);
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-active-df0190aa945791b851e57bf12c741798 {
        color: var(--palette-error-700);
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05, .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05 {
      color: #11435D;
    }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: #1C5B71;
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-active-df0190aa945791b851e57bf12c741798 {
        color: #2C7B8C;
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74, .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74 {
      color: var(--palette-primary-500);
    }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74:hover,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: var(--palette-primary-700);
      }

.Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74:active,
      .Button-variantText-d956142608c4a9585747d0630c084cab:not(:disabled).Button-colorStream-1cc54f25e370cd3ee64488d5efb83e74.Button-active-df0190aa945791b851e57bf12c741798 {
        color: var(--palette-primary-700);
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
  border-bottom: 1px solid currentColor;
  padding: 0;
}

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a {
    color: #419EA7;
  }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
    color: var(--palette-grey-500);
  }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a.Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0 {
    color: var(--palette-success-500);
  }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e {
    color: var(--palette-error-500);
  }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a.Button-colorDark-ad7f6f262a06efe65943a0abb5420d05 {
    color: #11435D;
  }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: #2C7B8C;
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:active,
      .Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-active-df0190aa945791b851e57bf12c741798 {
        color: #1C5B71;
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: var(--palette-grey-600);
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:active,
      .Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-active-df0190aa945791b851e57bf12c741798 {
        color: var(--palette-grey-700);
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: var(--palette-success-600);
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0:active,
      .Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlt-1ae4f933866b6c9ec8b1bf1241aa72c0.Button-active-df0190aa945791b851e57bf12c741798 {
        color: var(--palette-success-700);
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: var(--palette-error-600);
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:active,
      .Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-active-df0190aa945791b851e57bf12c741798 {
        color: var(--palette-error-700);
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: #1C5B71;
      }

.Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05:active,
      .Button-variantUnderlined-530128af90ca5ea4c38f61a046638e2a:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorDark-ad7f6f262a06efe65943a0abb5420d05.Button-active-df0190aa945791b851e57bf12c741798 {
        color: #2C7B8C;
      }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c {
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  text-decoration: underline;
}

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c.Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a {
    color: #419EA7;
  }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c.Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3 {
    color: var(--palette-grey-500);
  }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c.Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e {
    color: var(--palette-error-500);
  }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: #2C7B8C;
      }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e > span {
          text-decoration: underline;
        }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorRegular-cc15a1ff7aceb3f4e548acaa6fa6985a:active {
        color: #1C5B71;
      }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: var(--palette-grey-600);
      }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e > span {
          text-decoration: underline;
        }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorMono-d1151a09dbc3f7909e1c47d067c527a3:active {
        color: var(--palette-grey-700);
      }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e {
        color: var(--palette-error-600);
      }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-mouseHover-e33ac63c17beebeff72e47b0de75dd8e > span {
          -webkit-text-decoration: variantOutlined;
                  text-decoration: variantOutlined;
        }

.Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e.Button-active-df0190aa945791b851e57bf12c741798,
      .Button-variantTextUnderlined-d08da4a9cde192f05aa32a5c515c244c:not(.Button-disabled-cc3f26a281bf2b0be769dfa4708cc636).Button-colorAlert-c9ca144da541d5e3181e9ec0fb70379e:active {
        color: var(--palette-error-700);
      }

.Button-linkButton-0f38e1520c184c04d81353399d562d10 {
  text-decoration: none;
}

.Button-underline-36377a752abafec3d60aba72ded6ee11 {
  text-decoration: underline;
}

.Icon-root-c1fc955f8607c3696518db8f02fb9080 {
  font-family: "Material Icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  letter-spacing: 0;
  word-break: initial;

  /* Enable Ligatures */
  font-feature-settings: "liga", normal, "discretionary-ligatures";
  -ms-font-feature-settings: "liga" 1;
  font-variant-ligatures: "discretionary-ligatures";

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Icon-xs-e7e3bf227d0da9014960ca7566cf6a39 {
  font-size: var(--font-size-icon-xs);
  width: var(--font-size-icon-xs);
}

.Icon-sm-b43dbbab3c3f2b00596ba477436b5c63 {
  font-size: var(--font-size-icon-sm);
  width: var(--font-size-icon-sm);
}

.Icon-md-278c3bddcf6cb526b46da9fdfe4bdea7 {
  font-size: var(--font-size-icon-md);
  width: var(--font-size-icon-md);
}

.Icon-lg-52c5b1327595047fd47369f58ace50f4 {
  font-size: var(--font-size-icon-lg);
  width: var(--font-size-icon-lg);
}

.Icon-xl-c959e8b116404d6b69513c7b47a7e667 {
  font-size: var(--font-size-icon-xl);
  width: var(--font-size-icon-xl);
}

.Icon-colorPrimary-3491a6abb5459d337003cd985932b2bb {
  color: #2C7B8C;
}

.Icon-colorError-6ccdbe87118d299b742caeaadbe30517 {
  color: var(--palette-error-500);
}

.Icon-colorSuccess-af154acdcd5e40bb0667f4d42bfb29ce {
  color: var(--palette-success-500);
}

.Icon-colorStream-69f48ba931319a3d4c5388487933f873 {
  color: var(--palette-primary-500);
}

.ButtonIcon-root-f3772aedff8f66848665c45939aa05e6 {
}

.CallOut-root-0d080133451029bfb28571b154e7e23e {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-4);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  word-break: break-word;
}
.CallOut-borderless-e1f1de97c446cf14702c9e4f39393fe8 {
  border-style: none;
}
.CallOut-colorRegular-97e929289cdcbe14d437fa41c6016ea5 {
  background-color: var(--palette-grey-100);
  border-color: var(--palette-grey-300);
  color: var(--palette-text-500);
}
.CallOut-colorPrimary-d4598743b46da1662853e385265eeade {
  background-color: #E2FAF7;
  border-color: #9FECDF;
  color: var(--palette-text-500);
}
.CallOut-colorError-b382efa458d95e351c5c9d3d0763119f {
  background-color: var(--palette-error-100);
  border-color: var(--palette-error-300);
  color: var(--palette-text-500);
}
.CallOut-colorSuccess-69579c0f0985aa88032266b3aaf5036a {
  background-color: var(--palette-success-100);
  border-color: var(--palette-success-300);
  color: var(--palette-text-500);
}
.CallOut-fullWidth-89ff637ae6484d2f4c3abc55032887a9 {
  display: flex;
  width: 100%;
}
.CallOut-inner-ffd3a55e27e7be3986cca2dbba69dea6 {
  width: 100%;
}

.Hidden-root-f624dc6a06a34ed4aae4822ef75b9617 {
  display: none;
}

.Card-root-8f4fdb4b1951acb8d53e779d1ea64724 {
  position: relative;
  padding: var(--spacing-4);
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: var(--round-corners);
  background-color: #FFFFFF;
  /*
    Fallback begin

    Unfortunately Firefox / IE does not support
    `word-break: break-word` yet.
  */
  word-break: break-all;
  /* Fallback end */
  word-break: break-word;
}

.Flex-root-e30c492230d1edb681fe55ee4db5c12e {
}

.Flex-flex-d2ca733e3f89034ac350e74ab398ca2c {
  display: flex;
}

.Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9 > * {
    margin: 0 var(--spacing-1) 0 0 !important;
  }

.Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--spacing-1) !important;
    }

.Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--spacing-1) 0 !important;
    }

.Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--spacing-1) 0 0 0 !important;
    }

.Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9 > *:last-child {
    margin: 0 !important;
  }

.Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357 > * {
    margin: 0 var(--spacing-2) 0 0 !important;
  }

.Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--spacing-2) !important;
    }

.Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--spacing-2) 0 !important;
    }

.Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--spacing-2) 0 0 0 !important;
    }

.Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357 > *:last-child {
    margin: 0 !important;
  }

.Flex-spacing-3-11168378e9a5780a8fe3478017088d33 > * {
    margin: 0 var(--spacing-3) 0 0 !important;
  }

.Flex-spacing-3-11168378e9a5780a8fe3478017088d33.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--spacing-3) !important;
    }

.Flex-spacing-3-11168378e9a5780a8fe3478017088d33.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--spacing-3) 0 !important;
    }

.Flex-spacing-3-11168378e9a5780a8fe3478017088d33.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--spacing-3) 0 0 0 !important;
    }

.Flex-spacing-3-11168378e9a5780a8fe3478017088d33 > *:last-child {
    margin: 0 !important;
  }

.Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656 > * {
    margin: 0 var(--spacing-4) 0 0 !important;
  }

.Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--spacing-4) !important;
    }

.Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--spacing-4) 0 !important;
    }

.Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--spacing-4) 0 0 0 !important;
    }

.Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656 > *:last-child {
    margin: 0 !important;
  }

.Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac > * {
    margin: 0 var(--spacing-5) 0 0 !important;
  }

.Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--spacing-5) !important;
    }

.Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--spacing-5) 0 !important;
    }

.Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--spacing-5) 0 0 0 !important;
    }

.Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac > *:last-child {
    margin: 0 !important;
  }

.Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b > * {
    margin: 0 var(--spacing-6) 0 0 !important;
  }

.Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--spacing-6) !important;
    }

.Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--spacing-6) 0 !important;
    }

.Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--spacing-6) 0 0 0 !important;
    }

.Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b > *:last-child {
    margin: 0 !important;
  }

.Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e > * {
    margin: 0 var(--spacing-7) 0 0 !important;
  }

.Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--spacing-7) !important;
    }

.Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--spacing-7) 0 !important;
    }

.Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--spacing-7) 0 0 0 !important;
    }

.Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e > *:last-child {
    margin: 0 !important;
  }

.Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb > * {
    margin: 0 var(--spacing-8) 0 0 !important;
  }

.Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--spacing-8) !important;
    }

.Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--spacing-8) 0 !important;
    }

.Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--spacing-8) 0 0 0 !important;
    }

.Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb > *:last-child {
    margin: 0 !important;
  }

.Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6 > * {
    margin: 0 var(--spacing-9) 0 0 !important;
  }

.Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--spacing-9) !important;
    }

.Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--spacing-9) 0 !important;
    }

.Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--spacing-9) 0 0 0 !important;
    }

.Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6 > *:last-child {
    margin: 0 !important;
  }

.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d > * {
    margin: 0 calc(0.5 * var(--mini-unit)) 0 0 !important;
  }

.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 calc(0.5 * var(--mini-unit)) !important;
    }

.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 calc(0.5 * var(--mini-unit)) 0 !important;
    }

.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: calc(0.5 * var(--mini-unit)) 0 0 0 !important;
    }

.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d > *:last-child {
    margin: 0 !important;
  }

.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e > * {
    margin: 0 calc(2 * var(--mini-unit)) 0 0 !important;
  }

.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 calc(2 * var(--mini-unit)) !important;
    }

.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 calc(2 * var(--mini-unit)) 0 !important;
    }

.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: calc(2 * var(--mini-unit)) 0 0 0 !important;
    }

.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e > *:last-child {
    margin: 0 !important;
  }

.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
    margin: 0 var(--mini-unit) 0 0 !important;
  }

.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff > * {
      margin: 0 0 0 var(--mini-unit) !important;
    }

.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee > * {
      margin: 0 0 var(--mini-unit) 0 !important;
    }

.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 > * {
      margin: var(--mini-unit) 0 0 0 !important;
    }

.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *:last-child {
    margin: 0 !important;
  }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca {
  flex-wrap: wrap;
}

.Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8 {
  flex-wrap: wrap-reverse;
}

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4:not(:empty) {
      margin-top: calc(-1 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-top: var(--mini-unit) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4:not(:empty) {
      margin-left: calc(-1 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--mini-unit) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d:not(:empty) {
      margin-top: calc(-0.5 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d > * {
      margin-top: calc(0.5 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d:not(:empty) {
      margin-left: calc(-0.5 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d.Flex-halfItemGutter-a202fcab1dcbec6c729d74b586859a0d > * {
      margin-left: calc(0.5 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e:not(:empty) {
      margin-top: calc(-2 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e > * {
      margin-top: calc(2 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e:not(:empty) {
      margin-left: calc(-2 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e.Flex-doubleItemGutter-99ab6a641b6d1c3d2c53f99059362a1e > * {
      margin-left: calc(2 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0:not(:empty) {
      margin-top: calc(-3 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0 > * {
      margin-top: calc(3 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0:not(:empty) {
      margin-left: calc(-3 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0.Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0.Flex-tripleItemGutter-f8d98ebe149197dfc98f6acd365610a0 > * {
      margin-left: calc(3 * var(--mini-unit)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9:not(:empty) {
      margin-top: calc(-1 * var(--spacing-1)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9 > * {
      margin-top: var(--spacing-1) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9:not(:empty) {
      margin-left: calc(-1 * var(--spacing-1)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-1-7bd0624564ba26d6c2a0e5d1150663b9.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--spacing-1) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357:not(:empty) {
      margin-top: calc(-1 * var(--spacing-2)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357 > * {
      margin-top: var(--spacing-2) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357:not(:empty) {
      margin-left: calc(-1 * var(--spacing-2)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-2-3ea2975ed6feee33358cf69b1897a357.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--spacing-2) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-3-11168378e9a5780a8fe3478017088d33:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-3-11168378e9a5780a8fe3478017088d33:not(:empty) {
      margin-top: calc(-1 * var(--spacing-3)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-3-11168378e9a5780a8fe3478017088d33 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-3-11168378e9a5780a8fe3478017088d33 > * {
      margin-top: var(--spacing-3) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-3-11168378e9a5780a8fe3478017088d33:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-3-11168378e9a5780a8fe3478017088d33:not(:empty) {
      margin-left: calc(-1 * var(--spacing-3)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-3-11168378e9a5780a8fe3478017088d33.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-3-11168378e9a5780a8fe3478017088d33.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--spacing-3) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656:not(:empty) {
      margin-top: calc(-1 * var(--spacing-4)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656 > * {
      margin-top: var(--spacing-4) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656:not(:empty) {
      margin-left: calc(-1 * var(--spacing-4)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-4-a022c9ee13e3f7bf864a6cdfaf19a656.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--spacing-4) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac:not(:empty) {
      margin-top: calc(-1 * var(--spacing-5)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac > * {
      margin-top: var(--spacing-5) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac:not(:empty) {
      margin-left: calc(-1 * var(--spacing-5)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-5-aca70bbb76d3d2c6c5031bf50f9b68ac.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--spacing-5) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b:not(:empty) {
      margin-top: calc(-1 * var(--spacing-6)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b > * {
      margin-top: var(--spacing-6) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b:not(:empty) {
      margin-left: calc(-1 * var(--spacing-6)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-6-21874a9c61778276cb52c3f4a3f9040b.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--spacing-6) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e:not(:empty) {
      margin-top: calc(-1 * var(--spacing-7)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e > * {
      margin-top: var(--spacing-7) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e:not(:empty) {
      margin-left: calc(-1 * var(--spacing-7)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-7-f1be9c15b7437d8720a664ca1460618e.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--spacing-7) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb:not(:empty) {
      margin-top: calc(-1 * var(--spacing-8)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb > * {
      margin-top: var(--spacing-8) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb:not(:empty) {
      margin-left: calc(-1 * var(--spacing-8)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-8-9b6f9a5d2f1b0c6001f6bfeecc381cbb.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--spacing-8) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6:not(:empty) {
      margin-top: calc(-1 * var(--spacing-9)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8:not(.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee).Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6 > * {
      margin-top: var(--spacing-9) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6:not(:empty), .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6:not(:empty) {
      margin-left: calc(-1 * var(--spacing-9)) !important;
    }

.Flex-wrap-6d1b6ac029ed4bb936d308545730f2ca.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > *, .Flex-wrapReverse-4515676b541e61ede821ca69e339ebe8.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee.Flex-spacing-9-9d73b24f5ac9401fd68eab76c18e45e6.Flex-itemGutter-877c8524cc53828abc11c7fa63399cc4 > * {
      margin-left: var(--spacing-9) !important;
    }

.Flex-justifyFlexStart-d6426993029bf4702e2eeec226a28230 {
  justify-content: flex-start;
}

.Flex-justifyFlexEnd-d6022468a77727a492ca2de47024ec29 {
  justify-content: flex-end;
}

.Flex-justifyCenter-06ab8634dd41df2cbe6d9e8e16fdbf4b {
  justify-content: center;
}

.Flex-justifySpaceBetween-1e1ecf12d82a80d36b234d1261a4a513 {
  justify-content: space-between;
}

.Flex-justifySpaceAround-c5b7411c40ffd2883140a31f367d863e {
  justify-content: space-around;
}

.Flex-justifySpaceEvenly-f9e4a81d2dbf1e7cd39f54ffc20921e8 {
  justify-content: space-evenly;
}

.Flex-alignFlexStart-776800b267a72ebf925def0a6e8dde5e {
  align-items: flex-start;
}

.Flex-alignFlexEnd-bf4274eddbf909adaee6924aa3ec24ff {
  align-items: flex-end;
}

.Flex-alignCenter-26c1ac1572ede070f23436e4a05f81bd {
  align-items: center;
}

.Flex-alignBaseline-5ce4e5e615b80033b56022c911dac2c7 {
  align-items: baseline;
}

.Flex-alignStretch-5d3cd6031bf744030986b5a2b09f09ba {
  align-items: stretch;
}

.Flex-directionRow-2454f15d2085c2be40d132bad0acd66d {
  flex-direction: row;
}

.Flex-directionColumn-3814850135ac5abf2b425cd4fbc096ee {
  flex-direction: column;
}

.Flex-directionRowReverse-b8de01b599863d41ce78baa4e5a0abff {
  flex-direction: row-reverse;
}

.Flex-directionColumnReverse-5cba268035e009c1dd31c4434d2336c3 {
  flex-direction: column-reverse;
}

.CloseButton-root-53b8bc8ad85371044a1aef680511c6a8 {
  min-height: 0px;
  margin-right: -5px;
}

.CloseButton-icon-c16f94afe5d42bfbc0261cc59d117bcd {
  display: block;
}

.CheckBox-root-d352d0e21d048a3c209f0437a3304d2f {
  position: relative;
}

.CheckBox-input-420b5fdb159c2c86f87fa3e11376666a {
  cursor: pointer;
  position: absolute; /* take it out of document flow */
  opacity: 0; /* hide it */
  top: 0;
}

.CheckBox-label-1f32835697b88e72aeda40443827a9bc {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-left: calc(var(--spacing-3) + 14px);
  color: var(--palette-text-500);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1;
}

.CheckBox-labelSpan-7e523d9fac0ec4e4a4ac472a75698d2a {
  padding-bottom: 1px;
}

.CheckBox-labelLight-daefa1737b71a93b0d5d22b69ac240a8 {
  color: var(--palette-text-000);
}

/* Box. */

.CheckBox-input-420b5fdb159c2c86f87fa3e11376666a + .CheckBox-label-1f32835697b88e72aeda40443827a9bc:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 14px;
  height: 14px;
  background: var(--palette-background-input);
  border: 1px solid var(--palette-text-500);
  border-radius: 2px;
  box-sizing: border-box;
}

/* Box focus */

.CheckBox-label-1f32835697b88e72aeda40443827a9bc.CheckBox-focus-8d92d6a4cdb75fed020dd6f5738a4ef7:before {
  outline-width: 3px;
  outline-color: Highlight;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
}

/* Box checked */

input:checked.CheckBox-default-1e116de4c531eac52e20e38cdcd5e90b + .CheckBox-label-1f32835697b88e72aeda40443827a9bc:before {
  background: #2C7B8C;
  border: 1px solid #2C7B8C;
}

.CheckBox-input-420b5fdb159c2c86f87fa3e11376666a:checked.CheckBox-streamBlue-a49d9aa7379e901b679f9851a522ae8a + .CheckBox-label-1f32835697b88e72aeda40443827a9bc:before {
  background: var(--palette-primary-500);
  border: 1px solid var(--palette-primary-500);
}

input:checked + .CheckBox-label-1f32835697b88e72aeda40443827a9bc {
  font-weight: var(--font-weight-primary-bold);
}

/* Disabled state label. */

.CheckBox-input-420b5fdb159c2c86f87fa3e11376666a:disabled + .CheckBox-label-1f32835697b88e72aeda40443827a9bc {
  cursor: auto;
  opacity: 0.6;
}

/* Disabled box. */

.CheckBox-input-420b5fdb159c2c86f87fa3e11376666a:disabled + .CheckBox-label-1f32835697b88e72aeda40443827a9bc:before {
  box-shadow: none;
  border: 1px solid var(--palette-text-input-disabled);
  background: var(--palette-background-input-disabled);
}

/* Checkmark. Could be replaced with an image */

.CheckBox-input-420b5fdb159c2c86f87fa3e11376666a:checked + .CheckBox-label-1f32835697b88e72aeda40443827a9bc:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 50%;
  margin-top: -1px;
  transform: translateY(-50%);
  color: var(--palette-background-input);
  background: currentColor;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 currentColor, 4px 0 0 currentColor,
    4px -2px 0 currentColor, 4px -4px 0 currentColor, 4px -6px 0 currentColor,
    4px -8px 0 currentColor;
  transform: rotate(45deg);
  box-sizing: border-box;
}

.CheckBox-input-420b5fdb159c2c86f87fa3e11376666a:checked:disabled + .CheckBox-label-1f32835697b88e72aeda40443827a9bc:after {
  color: var(--palette-text-input-disabled);
}

.Counter-root-059168834122b5ae8235928816a9f1ba {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  line-height: 1.14;
  letter-spacing: 0.014285714285714287em;
  border-radius: var(--round-corners);
  padding: 1px 3px 1px 3px;
  color: inherit;
}
.Counter-sizeSmall-505af64cb0607eeb3a90380d193dc6aa {
  font-size: var(--font-size-1);
  padding: 2px 3px;
  line-height: 1.33;
}
.Counter-text-58caffde738d519a54562205d642c007 {
  color: var(--palette-text-000);
}
.Counter-colorInherit-3008e9d04bbe3d2fa188fc4130418e6a {
  background-color: currentColor;
}
.Counter-colorPrimary-be9cdff494e40f9848444644b03f704f {
  background-color: #2C7B8C;
}
.Counter-colorGrey-08e9e2f91287e2db636a055bd5fd8f70 {
  background-color: var(--palette-grey-200);
  box-shadow: inset 0px 0px 0px 1px var(--palette-grey-600);
}
.Counter-colorGrey-08e9e2f91287e2db636a055bd5fd8f70 .Counter-text-58caffde738d519a54562205d642c007 {
    color: var(--palette-grey-600);
  }
.Counter-colorDark-243a6c9a944b3ad2d8644210dcefb1e7 {
  background-color: var(--palette-grey-600);
}
.Counter-colorError-6c809fca95e4f317295e0998cf7a8985 {
  background-color: var(--palette-error-700);
}
.Counter-colorEmphasis-cd939af995d664df23c7c9bb0f60c798 {
  background-color: var(--palette-grey-500);
}
.Counter-colorDefault-bc1a0878ffc2919f3b4d5992523ca439 {
  background-color: var(--palette-grey-500);
}
.Counter-colorAlert-5457ef3dc4563435abf25ad0ac1384fa {
  background-color: var(--palette-grey-500);
}

.Divider-root-5e1a79e3bc7639a4729b5f7376f8ac85 {
  border-top: 0;
  border-bottom: 1px solid var(--palette-grey-300);
  padding: 0;
}

.Divider-spacing1-3a3c7d130656ff3896d346a1a7913fbf {
  margin: var(--spacing-1) 0;
}

.Divider-spacing2-ceb3038d72f43365fb20965d3fc9920f {
  margin: var(--spacing-2) 0;
}

.Divider-spacing3-106d342f8284d8463aea2b8a81f46924 {
  margin: var(--spacing-3) 0;
}

.Divider-spacing4-babc8f13784dd9d125f79f79360d0e5d {
  margin: var(--spacing-4) 0;
}

.Dropdown-root-d1e0c8f4df2be751a552126e8c8d70f4 {
  padding: calc(0.5 * var(--mini-unit)) 0;
}

.Button-root-a1e8df13a16f460e8d185e28fb75ee14 {
  font-size: var(--font-size-3);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-2);
  width: 100%;
  text-align: left;
  line-height: 1;
}

.Button-anchor-60f436c65d8dcb969db75e4b0a39d25c {
  color: #2C7B8C;
  text-decoration: underline;
}

.Button-anchor-60f436c65d8dcb969db75e4b0a39d25c:visited {
    color: #2C7B8C;
  }

.Button-root-a1e8df13a16f460e8d185e28fb75ee14:disabled {
  opacity: 0.6;
}

.Button-root-a1e8df13a16f460e8d185e28fb75ee14:not(:disabled):active {
  background-color: #E2FAF7;
}

.Button-mouseHover-333a3803632a03c82ee914d87bb3a690 {
  background-color: #E2FAF7;
}

.Button-iconBefore-39851269dd5392cc79cc3448047f6424 {
  display: flex;
  justify-content: baseline;
  align-items: center;
  color: var(--palette-grey-500);
  margin-right: var(--spacing-2);
}

.Button-iconAfter-0a71124a82386e2cc251f0d45e9a8840 {
  display: flex;
  align-items: center;
  justify-content: baseline;
  margin-left: var(--spacing-2);
  text-decoration: unset;
}

.Button-iconOpenInNew-114d7b405caf0f5edd0d06c278fc56fb {
  padding-top: 1px;
  color: #2C7B8C;
}

.Button-blankAdornment-9cb3b4943c061ef64f8343c57354928b {
  padding-right: var(--spacing-6);
}

.Divider-root-1bde9eba83b266e61470011bf1c45c40 {
  border: 0;
  border-bottom: 1px solid var(--palette-grey-300);
  margin: 0;
  padding: calc(0.5 * var(--mini-unit)) 0 0 0;
}

.SelectField-root-e94ff23b61eac330857f4e86aa8f66bb {
  position: relative;
  display: inline-block;
}

.SelectField-afterWrapper-fd941e0c52ce0243a5158d14ec7575b7 {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  right: var(--spacing-2);
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

.SelectField-keyboardFocus-77453d10aa39e10a3ca60bcf76f8e8f2:focus {
  outline-width: 3px;
  outline-color: Highlight;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
}

.SelectField-selectFont-64161fe563cd97253639f1f8b852fd68 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.3;
}

.SelectField-selectFont-64161fe563cd97253639f1f8b852fd68 option {
  font-weight: normal;
}

.SelectField-selectColor-8638a5cdc96d89227e5ae33a58c7a72a {
  color: var(--palette-text-500);
}

.SelectField-select-eeb07c97698171b11db487e472670e13 {
  -webkit-appearance: none;
  -moz-appearance: none;

  appearance: none;
  outline: none;
  width: 100%;

  border-radius: var(--round-corners);
  background-color: var(--palette-background-input);
  border: 1px solid var(--palette-grey-500);
  padding: calc(var(--spacing-1) - 0.8px) var(--spacing-6)
    var(--spacing-1) var(--spacing-2);
}

.SelectField-select-eeb07c97698171b11db487e472670e13::-moz-focus-inner {
    border: 0;
  }

.SelectField-select-eeb07c97698171b11db487e472670e13:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

.SelectField-select-eeb07c97698171b11db487e472670e13:disabled {
    background-color: var(--palette-background-input-disabled);
    color: var(--palette-text-input-disabled);
  }

.SelectField-select-eeb07c97698171b11db487e472670e13::-ms-expand {
    display: none;
  }

.SelectField-fullWidth-723a0533f1d6cf09d8b20218ab423b33 {
  width: 100%;
}

.SelectField-afterWrapperDisabled-ec2e8779ad87dd6e983400581a471b24 {
  color: var(--palette-text-500);
}

.TextField-root-f8b24bf4256c3afee58f63935465fdd8 {
  display: flex;
  width: calc(29 * var(--mini-unit));
  align-items: center;
  height: 34px;
}
.TextField-input-4ccddc4e56919be89cd2fc1bb610080f {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;
  position: relative;
  display: block;
  padding: var(--spacing-2);
  box-sizing: border-box;
  background-color: var(--palette-background-input);
  border-radius: var(--round-corners);
  border-style: solid;
  width: 100%;
  align-self: stretch;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--palette-text-500);
}
.TextField-input-4ccddc4e56919be89cd2fc1bb610080f:-moz-read-only {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-grey-400);
    color: var(--palette-text-input-disabled);
  }
.TextField-input-4ccddc4e56919be89cd2fc1bb610080f:read-only,
  .TextField-input-4ccddc4e56919be89cd2fc1bb610080f:disabled {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-grey-400);
    color: var(--palette-text-input-disabled);
  }
.TextField-input-4ccddc4e56919be89cd2fc1bb610080f:focus {
    outline: none;
  }
.TextField-input-4ccddc4e56919be89cd2fc1bb610080f::-moz-placeholder {
    color: var(--palette-text-placeholder);
  }
.TextField-input-4ccddc4e56919be89cd2fc1bb610080f:-ms-input-placeholder {
    color: var(--palette-text-placeholder);
  }
.TextField-input-4ccddc4e56919be89cd2fc1bb610080f::placeholder {
    color: var(--palette-text-placeholder);
  }
.TextField-adornment-d893e17d30edddd6d0d61446f2238735 {
  margin-left: var(--spacing-2);

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;
}
.TextField-colorRegular-76706cb29f20ed8aa17b05aa134006b3 {
  border: 1px solid var(--palette-grey-500);
}
.TextField-colorRegular-76706cb29f20ed8aa17b05aa134006b3:focus {
    border: 1px solid #59C3C3;
    box-shadow: inset 0px 0px 0px 1px #59C3C3;
  }
.TextField-colorStreamBlue-9dde6d2c394e8ef13697306773452115 {
  border: 1px solid var(--palette-grey-500);
}
.TextField-colorStreamBlue-9dde6d2c394e8ef13697306773452115:focus {
    border: 1px solid var(--palette-primary-500);
    box-shadow: inset 0px 0px 0px 1px var(--palette-primary-500);
  }
.TextField-colorDark-9b377dca89005ae277c034f15b167f0d {
  border: 1px solid var(--palette-grey-500);
}
.TextField-colorDark-9b377dca89005ae277c034f15b167f0d:focus {
    border: 1px solid #11435D;
    box-shadow: inset 0px 0px 0px 1px #11435D;
  }
.TextField-colorError-d58d8838b974b45636371c8ed5eba7f1 {
  border: 2px solid var(--palette-error-500);
}
.TextField-fullWidth-abd56beae247bba379c268ceec0b8fdc {
  width: 100%;
}
.TextField-textAlignCenter-5366721f1c60344f7ee7a1129a9dbaa6 {
  text-align: center;
}
.TextField-seamlessAdornment-99bcfda527dc8862c229681fa095334a {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.TextField-seamlessAdornment-99bcfda527dc8862c229681fa095334a:focus {
    border-right: 0;
  }
.TextField-seamlessAdornment-99bcfda527dc8862c229681fa095334a + .TextField-adornment-d893e17d30edddd6d0d61446f2238735 {
    height: 100%;
    margin: 0;
    display: flex;
    align-items: stretch;
  }

.DurationField-value-e072d27820ca4569e5deb2969d907d3d {
  width: calc(6 * var(--mini-unit));
}

.DurationField-select-1d5b9af395600e27216569632752f340 {
  height: 100%;
  min-width: calc(17 * var(--mini-unit));
}

.DurationField-unit-8ddab3bd4e6a56b02a04e1f1c5ef6e66 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 36px;
}

.FieldSet-root-973d228d8250cab8efa10e4bed9f690f {
  border: 0;
  padding: 0;
  margin: 0;
}

.FormField-root-11024a311966543b32b08f9ee0ac6a86 {
}

.FormFieldDescription-root-2adf79840c6338d4496a0c32c4d6eb70 {
  color: var(--palette-text-500);
  font-size: var(--font-size-3);
  line-height: 1.3;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
}

.FormFieldHeader-root-152190829a0d9bdecebdb34775cd0317 {
}

.FormFieldFooter-root-6d90741e19dbd99357f7ce905de1804b {
  color: var(--palette-text-100);
  font-size: var(--font-size-2);
  line-height: 1.14;
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
}

.HelperText-root-9e65fefba8a0fda4a9a63c5d410df4b4 {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.285;
}

.HorizontalGutter-root-42028c0a7886c844bb9f01763cc43000 {
  display: block;
  border: 0;
  padding: 0;
}

  .HorizontalGutter-root-42028c0a7886c844bb9f01763cc43000 > *:last-child {
    margin: 0 !important;
  }

.HorizontalGutter-half-103c5e1607968261e033e654bb4d29a8 > * {
    margin: 0 0 calc(0.5 * var(--mini-unit)) 0 !important;
  }

.HorizontalGutter-full-680598106a6954360bcd94b9d3839ca7 > * {
    margin: 0 0 var(--mini-unit) 0 !important;
  }

.HorizontalGutter-oneAndAHalf-46c5849e8e0ed7bc3ef647b349fd3f5c > * {
    margin: 0 0 calc(1.5 * var(--mini-unit)) 0 !important;
  }

.HorizontalGutter-double-92f3ac140b1da2510f169eee3bb61fbe > * {
    margin: 0 0 calc(2 * var(--mini-unit)) 0 !important;
  }

.HorizontalGutter-triple-337439da5ca3f1c02a18d12fec715cd7 > * {
    margin: 0 0 calc(3 * var(--mini-unit)) 0 !important;
  }

.HorizontalGutter-spacing-1-501699f71948b4578abd62f89437d139 > * {
    margin: 0 0 var(--spacing-1) 0 !important;
  }

.HorizontalGutter-spacing-2-69471e99735f571dd80480d6d540a148 > * {
    margin: 0 0 var(--spacing-2) 0 !important;
  }

.HorizontalGutter-spacing-3-9e13fa38aec0c6101ce383ac54f8bab4 > * {
    margin: 0 0 var(--spacing-3) 0 !important;
  }

.HorizontalGutter-spacing-1-501699f71948b4578abd62f89437d139 > * {
    margin: 0 0 var(--spacing-1) 0 !important;
  }

.HorizontalGutter-spacing-4-0909769f4cce75d5d88e0be5c3d827ab > * {
    margin: 0 0 var(--spacing-4) 0 !important;
  }

.HorizontalGutter-spacing-5-6898226010b75aeadcc5eb8c6eb21682 > * {
    margin: 0 0 var(--spacing-5) 0 !important;
  }

.HorizontalGutter-spacing-6-faaa678ba017696f407245b76eb29dbe > * {
    margin: 0 0 var(--spacing-6) 0 !important;
  }

.HorizontalGutter-spacing-7-098a75efe407ea2936ba9f4edf298a81 > * {
    margin: 0 0 var(--spacing-7) 0 !important;
  }

.HorizontalGutter-spacing-8-80253a5815d226198e86d9d74c33a65a > * {
    margin: 0 0 var(--spacing-8) 0 !important;
  }

.HorizontalGutter-spacing-9-59cdb47e1d587fbd8b8090d9c2745d5c > * {
    margin: 0 0 var(--spacing-9) 0 !important;
  }

.Label-root-010b41691b425b0efbe28bc772c32ea9 {
  color: var(--palette-text-500);
  font-size: var(--font-size-2);
  line-height: 1.14;
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  padding-left: 0;
  display: block;
}

.ListGroup-root-adf0b114797d21929a770a1d76a39bb6 {
  border: 1px solid var(--palette-grey-300);
  border-radius: var(--round-corners);
  overflow-y: auto;
}

.ListGroupRow-root-e6dfab7e82b1df7ede9f4123dbd578a5 {
  border-bottom: 1px solid var(--palette-grey-200);
  padding: var(--spacing-2);
}

  .ListGroupRow-root-e6dfab7e82b1df7ede9f4123dbd578a5:last-child {
    border-bottom: none;
  }

.Marker-root-1f0a1ff2b327bb574b9ae98cb61789b4 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: 0.8125rem;
  letter-spacing: -0.02692307692307692em;

  color: var(--palette-error-500);
  border: 1px solid currentColor;
  border-radius: 20px;
  padding: 1px var(--mini-unit);
  white-space: nowrap;
}
.Marker-colorReported-27cbc1f7ca02265a489337b71ce7a5a3 {
  color: var(--palette-error-500);
}
.Marker-colorPending-5f281bb40b0eb51f5e54b79264145564 {
  color: #2C7B8C;
}
.Marker-variantRegular-7bdd2f0baf9ee9f6a384939fab7350e7 {
}
.Marker-variantFilled-40eae5b960b1b7733ff3f5364b21f6c5 {
  color: var(--palette-text-000);
}
.Marker-variantFilled-40eae5b960b1b7733ff3f5364b21f6c5.Marker-colorReported-27cbc1f7ca02265a489337b71ce7a5a3 {
    background-color: var(--palette-error-500);
    border-color: var(--palette-error-500);
  }
.Marker-variantFilled-40eae5b960b1b7733ff3f5364b21f6c5.Marker-colorPending-5f281bb40b0eb51f5e54b79264145564 {
    background-color: #2C7B8C;
    border-color: #2C7B8C;
  }

.Count-root-496dec0cc4cc4c5ab9302c40048ec2fe {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: 0.8125rem;
  letter-spacing: -0.02692307692307692em;

  border-left: 1px solid currentColor;
  margin-left: calc(0.5 * var(--mini-unit));
  margin-right: calc(-0.25 * var(--mini-unit));
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: calc(0.5 * var(--mini-unit));
  white-space: nowrap;
}

.Message-root-324add18da91b078107c24609653c9d7 {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--spacing-1) var(--spacing-2);
  box-sizing: border-box;
  border-radius: var(--round-corners);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
}
.Message-colorGrey-59d99cec23f0c92431e84a27174dcac0 {
  background-color: inherit;
  border-color: var(--palette-grey-500);
  color: var(--palette-grey-500);
}
.Message-colorDark-8a1b818672f2db2c4c5e8a90da988a69 {
  background-color: var(--palette-text-500);
  border-width: 0px;
  color: var(--palette-text-000);
}
.Message-colorError-65a04a0c96145a7f9f77ab614a579545 {
  background-color: var(--palette-error-500);
  color: var(--palette-text-000);
}
.Message-colorPrimary-bd797a3f2a50e3c72958bec094d398b6 {
  background-color: #9FECDF;
  border-color: #11435D;
  border-width: 0px;
  border-left-width: calc(0.5 * var(--mini-unit));
  color: var(--palette-text-000);
}
.Message-fullWidth-6d690f81e3b0bce0a0bf1a4b7a8730a5 {
  display: flex;
  width: 100%;
}

.MessageIcon-root-da2284f1f5c1e6e3d04f244487c9d80d {
  align-self: flex-start;
  margin-top: 1px;
  flex-shrink: 0;
}
  .MessageIcon-root-da2284f1f5c1e6e3d04f244487c9d80d:first-child {
    margin-right: calc(0.5 * var(--mini-unit));
  }

.NoScroll-noScroll-e3d04b6e68f2b8619fb2a2149f6e718e {
  overflow: hidden;
}

.Modal-root-1c4bb650d75db0309423d140bb24fa0d {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1000;
}

.Modal-baseScroll-1407ca1d08bf84491d57b203cdad9f52 {
  position: relative;

  width: 100%;
  height: 100%;
}

.Modal-noScroll-92644211e463c6ad9b8916f60757697d {
  overflow-y: hidden;
}

.Modal-scroll-5b45457e017d0ab7418706e86fba24f4 {
  overflow-y: auto;
}

.Modal-alignContainer1-6fa71e5a98c256604aee8dfa74a9cbd1 {
  display: table;
  margin: 0 auto;
  height: 100%;
}

.Modal-alignContainer2-d4a2937ef42f1e4258853650f26b42bf {
  display: table-cell;
  vertical-align: middle;
}

.Modal-wrapper-0ab8eea1e3d5c64bd24edc669df7a894 {
  pointer-events: all;
}

.ModalHeader-root-a17d08b2a60bf3755d276bf5ce4fc871 {
  background-color: #EFEFEF;
  padding: var(--spacing-4);
}

.PasswordField-root-214104c7e521ad3d3a60f52598e035b2 {
  width: calc(29 * var(--mini-unit));
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
}


.PasswordField-input-d1bc3adb750ec186fea5730dfdea179e {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;
  display: block;
  padding: var(--spacing-2);
  border-radius: var(--round-corners);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}


.PasswordField-input-d1bc3adb750ec186fea5730dfdea179e:-moz-read-only {
    background-color: var(--palette-background-input-disabled);
  }


.PasswordField-input-d1bc3adb750ec186fea5730dfdea179e:read-only {
    background-color: var(--palette-background-input-disabled);
  }


.PasswordField-input-d1bc3adb750ec186fea5730dfdea179e:disabled {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-text-input-disabled);
  }


.PasswordField-input-d1bc3adb750ec186fea5730dfdea179e:focus {
    outline: none;
  }


.PasswordField-input-d1bc3adb750ec186fea5730dfdea179e::-moz-placeholder {
    color: var(--palette-text-placeholder);
  }


.PasswordField-input-d1bc3adb750ec186fea5730dfdea179e:-ms-input-placeholder {
    color: var(--palette-text-placeholder);
  }


.PasswordField-input-d1bc3adb750ec186fea5730dfdea179e::placeholder {
    color: var(--palette-text-placeholder);
  }


.PasswordField-colorRegular-313d4af078afa075c5a6c7c665d8b7c6 {
  background-color: var(--palette-background-input);
  color: var(--palette-text-500);
  border: 1px solid var(--palette-grey-500);
}


.PasswordField-colorRegular-313d4af078afa075c5a6c7c665d8b7c6:focus {
    border: 1px solid #59C3C3;
    box-shadow: inset 0px 0px 0px 1px #59C3C3;
  }


.PasswordField-colorStreamBlue-157cf4a4a56bdd53b1f35dc94cc5584d {
  background-color: var(--palette-background-input);
  color: var(--palette-text-500);
  border: 1px solid var(--palette-grey-500);
}


.PasswordField-colorStreamBlue-157cf4a4a56bdd53b1f35dc94cc5584d:focus {
    border: 1px solid var(--palette-primary-500);
    box-shadow: inset 0px 0px 0px 1px var(--palette-primary-500);
  }


.PasswordField-colorError-7744dfa3e0e0eaec85efa892ca43f301 {
  background-color: var(--palette-background-input);
  border-color: var(--palette-error-500);
  border: 2px solid var(--palette-error-500);
}


.PasswordField-fullWidth-cf0310ad06dc2fee53f6df7708b444aa {
  width: 100%;
}


.PasswordField-wrapper-3c52383f057c4190da2add12fdf1fea7 {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}


.PasswordField-icon-f4f7385ffb608ffbf07b8e2265b8ed3b {
  position: absolute;
  display: inline-block;
  right: 0px;
  padding: 4px calc(1 * var(--mini-unit));
  cursor: pointer;
  line-height: 0;
}


/* ie11 specific css */


_:-ms-lang(x), .PasswordField-icon-f4f7385ffb608ffbf07b8e2265b8ed3b { top: 8px; }

.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a {
  position: absolute;
  width: 20px;
  height: 20px;
}
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="bottom"] {
    top: 0;
    left: 0;
    margin-top: -6px;
    width: 20px;
    height: 6px;
    filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.1));
  }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="bottom"]::before {
      border-width: 0 10px 6px 10px;
      border-color: transparent transparent var(--palette-grey-400)
        transparent;
    }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="bottom"]::after {
      margin-top: -5px;
      border-width: 0 10px 6px 10px;
      border-color: transparent transparent var(--palette-background-popover)
        transparent;
    }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="bottom"].Arrow-colorDark-634271dc5f80fe23644464db37ab175f::before {
        border-color: transparent transparent var(--palette-background-tooltip)
          transparent;
      }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="bottom"].Arrow-colorDark-634271dc5f80fe23644464db37ab175f::after {
        border-color: transparent transparent var(--palette-background-tooltip)
          transparent;
      }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="top"] {
    bottom: 0;
    left: 0;
    margin-bottom: -6px;
    width: 20px;
    height: 6px;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.1));
  }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="top"]::before {
      border-width: 6px 10px 0 10px;
      border-color: var(--palette-grey-400) transparent transparent
        transparent;
    }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="top"]::after {
      margin-top: -7px;
      border-width: 6px 10px 0 10px;
      border-color: var(--palette-background-popover) transparent transparent
        transparent;
    }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="top"].Arrow-colorDark-634271dc5f80fe23644464db37ab175f::before {
        border-color: var(--palette-background-tooltip) transparent transparent
          transparent;
      }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="top"].Arrow-colorDark-634271dc5f80fe23644464db37ab175f::after {
        border-color: var(--palette-background-tooltip) transparent transparent
          transparent;
      }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="right"] {
    left: 0;
    height: 20px;
    width: 6px;
    margin-left: -6px;
    filter: drop-shadow(-1px 0px 1px rgba(0, 0, 0, 0.1));
  }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="right"]::before {
      border-width: 10px 6px 10px 0;
      border-color: transparent var(--palette-grey-400) transparent
        transparent;
    }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="right"]::after {
      margin-top: -20px;
      margin-right: -1px;
      border-width: 10px 6px 10px 0;
      border-color: transparent var(--palette-background-popover) transparent
        transparent;
    }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="right"].Arrow-colorDark-634271dc5f80fe23644464db37ab175f::before {
        border-color: transparent var(--palette-background-tooltip) transparent
          transparent;
      }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="right"].Arrow-colorDark-634271dc5f80fe23644464db37ab175f::after {
        border-color: transparent var(--palette-background-tooltip) transparent
          transparent;
      }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="left"] {
    right: 0;
    height: 20px;
    width: 6px;
    margin-right: -6px;
    filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.1));
  }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="left"]::before {
      border-width: 10px 0 10px 6px;
      border-color: transparent transparent transparent
        var(--palette-grey-400);
    }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="left"]::after {
      margin-top: -20px;
      margin-left: -1px;
      border-width: 10px 0 10px 6px;
      border-color: transparent transparent transparent
        var(--palette-background-popover);
    }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="left"].Arrow-colorDark-634271dc5f80fe23644464db37ab175f::before {
        border-color: transparent transparent transparent
          var(--palette-background-tooltip);
      }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a[data-placement*="left"].Arrow-colorDark-634271dc5f80fe23644464db37ab175f::after {
        border-color: transparent transparent transparent
          var(--palette-background-tooltip);
      }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
.Arrow-root-140f6d73dc250d90eb1ef3ce9b84b80a::after {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }

.Popover-root-3f2ec388eb9c8afe569f3401d3c59b59 {
}
.Popover-popover-8674f45cabb0312194107205cf248630 {
  background: var(--palette-background-popover);
  border: 1px solid var(--palette-grey-400);
  box-sizing: border-box;
  box-shadow: var(--shadow-popover);
  border-radius: var(--round-corners);
  z-index: 300;
  margin: 2px;
}
.Popover-colorDark-41f7b297a981facb280bfcfbbaa1b7d6 {
  background: var(--palette-background-tooltip);
  border-color: var(--palette-background-tooltip);
}
.Popover-top-00957eceae3776eecfc203b9b9c81c1c {
  margin: calc(0.5 * var(--mini-unit)) 0;
}
.Popover-left-fb51ce8ac38aa51a501c14365fdeeda9 {
  margin: 0 calc(0.5 * var(--mini-unit));
}
.Popover-right-d4e2dee40ea7d84ddb0d17a622480616 {
  margin: 0 calc(0.5 * var(--mini-unit));
}
.Popover-bottom-61cf38ae02c242565e7bf23a379ce12d {
  margin: calc(0.5 * var(--mini-unit)) 0;
}

.RadioButton-root-ef52d6e0657c0e34efc372473c27816c {
}

.RadioButton-input-b85571ee2e3c571e2c0b7b3f2b44760c {
  cursor: pointer;
  position: absolute; /* take it out of document flow */
  opacity: 0; /* hide it */
}

.RadioButton-label-9392584daf2f4fcbd84c3b71a48572eb {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: var(--palette-text-500);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.3;
  align-items: center;
}

.RadioButton-labelChecked-ce4991949a9ebe4878284234a629030b {
  font-weight: var(--font-weight-primary-semi-bold);
}

.RadioButton-labelLight-764c2ddbb28c717f8f2a91dfbafe4b1a {
  color: var(--palette-text-000);
}

/* Box. */

.RadioButton-input-b85571ee2e3c571e2c0b7b3f2b44760c + .RadioButton-label-9392584daf2f4fcbd84c3b71a48572eb:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  width: 14px;
  height: 14px;
  background: var(--palette-background-input);
  border: 1px solid var(--palette-text-500);
  border-radius: 50%;
  box-sizing: border-box;
  flex-shrink: 0;
}

/* Box focus */

.RadioButton-label-9392584daf2f4fcbd84c3b71a48572eb.RadioButton-focus-88e02770046d3a822a448bf84e35d62c:before {
  outline-width: 3px;
  outline-color: Highlight;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
}

/* Box checked */

.RadioButton-input-b85571ee2e3c571e2c0b7b3f2b44760c:checked + .RadioButton-label-9392584daf2f4fcbd84c3b71a48572eb:before {
  border: 1px solid var(--palette-text-500);
  background: var(--palette-background-input);
}

/* Disabled state label. */

.RadioButton-input-b85571ee2e3c571e2c0b7b3f2b44760c:disabled + .RadioButton-label-9392584daf2f4fcbd84c3b71a48572eb {
  cursor: auto;
  opacity: 0.6;
}

/* Disabled box. */

.RadioButton-input-b85571ee2e3c571e2c0b7b3f2b44760c:disabled + .RadioButton-label-9392584daf2f4fcbd84c3b71a48572eb:before {
  box-shadow: none;
  border: 1px solid var(--palette-text-input-disabled);
  background: var(--palette-background-input-disabled);
}

/* Checkmark. Could be replaced with an image */

.RadioButton-input-b85571ee2e3c571e2c0b7b3f2b44760c:checked + .RadioButton-label-9392584daf2f4fcbd84c3b71a48572eb:after {
  content: "";
  background: var(--palette-text-500);
  border-radius: 50%;
  position: absolute;
  left: 3px;
  width: 8px;
  height: 8px;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.RadioButton-input-b85571ee2e3c571e2c0b7b3f2b44760c:checked:disabled + .RadioButton-label-9392584daf2f4fcbd84c3b71a48572eb:after {
  background: var(--palette-background-input-disabled);
}

.RelativeTime-root-d30b4f3b231d2f240c2f63990321529f {
}

.Spinner-spinner-754845770d174ea60db93a12b8fbc1e1 {
  -webkit-animation: Spinner-rotator-8d0d288222d49c50188cc2527cddc0fd 1.4s linear infinite;
          animation: Spinner-rotator-8d0d288222d49c50188cc2527cddc0fd 1.4s linear infinite;
}

@-webkit-keyframes Spinner-rotator-8d0d288222d49c50188cc2527cddc0fd {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes Spinner-rotator-8d0d288222d49c50188cc2527cddc0fd {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.Spinner-path-951477fbc52d11847e0078b856613cc3 {
  stroke: var(--palette-primary-700);
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-animation: Spinner-dash-61ca68de9787a577a3bff747a5852691 1.4s ease-in-out infinite, Spinner-colors-e3e894ed546738a04e876505416666b4 5.6s ease-in-out infinite;
          animation: Spinner-dash-61ca68de9787a577a3bff747a5852691 1.4s ease-in-out infinite, Spinner-colors-e3e894ed546738a04e876505416666b4 5.6s ease-in-out infinite;
}

@-webkit-keyframes Spinner-colors-e3e894ed546738a04e876505416666b4 {
  0% {
    stroke: var(--palette-primary-700);
  }
  100% {
    stroke: var(--palette-primary-300);
  }
}

@keyframes Spinner-colors-e3e894ed546738a04e876505416666b4 {
  0% {
    stroke: var(--palette-primary-700);
  }
  100% {
    stroke: var(--palette-primary-300);
  }
}

@-webkit-keyframes Spinner-dash-61ca68de9787a577a3bff747a5852691 {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

@keyframes Spinner-dash-61ca68de9787a577a3bff747a5852691 {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

@-webkit-keyframes Spinner-fullRotator-7f60504eaa1d66aa8c6d4957575b906e {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes Spinner-fullRotator-7f60504eaa1d66aa8c6d4957575b906e {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hack for IE and Edge as they don't support css animations on SVG elements. */

_:-ms-lang(x),
.Spinner-path-951477fbc52d11847e0078b856613cc3 {
  stroke-dasharray: 160;
}

_:-ms-lang(x),
.Spinner-spinner-754845770d174ea60db93a12b8fbc1e1 {
  animation: Spinner-fullRotator-7f60504eaa1d66aa8c6d4957575b906e 1.4s linear infinite;
}

.SubBar-root-a40e96476c7255ade248216ef2159ee9 {
  background-color: inherit;
  border-bottom: 1px solid var(--palette-grey-300);
}
.SubBar-container-fff39c2293c283f18da9f4445f23db5a {
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.SubBar-gutterBegin-dfe813b47c51f71ac3dffbc7b56f39b1 {
  padding-left: calc(2 * var(--mini-unit));
}
.SubBar-gutterEnd-666aa4301499cb191e1b4fa1410efe16 {
  padding-right: calc(2 * var(--mini-unit));
}

.Navigation-root-7c484da5999a3387a408bce06ed322ae {
  height: 100%;
  padding: 0 calc(1.5 * var(--mini-unit));
}

.Navigation-ul-cdff2fc00c46823a6378a69404cea88d {
  list-style: none;
  padding: 0;
  display: flex;
  height: 100%;
  margin: 0;
  align-items: flex-end;
}

.Navigation-ul-cdff2fc00c46823a6378a69404cea88d > * {
    margin: 0 calc(3 * var(--mini-unit)) 0 0;
  }

.Navigation-ul-cdff2fc00c46823a6378a69404cea88d > *:last-child {
    margin: 0;
  }

.NavigationItem-root-3ec56cb98d0bba2b38a209d273b4fe03 {
  height: 100%;
}
.NavigationItem-anchor-0d89cda3c91c2ca361ca0f8dfc5292cb {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  text-transform: uppercase;
  color: var(--palette-text-100);
  height: 100%;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  padding: var(--spacing-3) 0;
  border-bottom: 3px solid transparent;
}
.NavigationItem-anchor-0d89cda3c91c2ca361ca0f8dfc5292cb:hover {
    cursor: pointer;
  }
.NavigationItem-anchor-0d89cda3c91c2ca361ca0f8dfc5292cb > * {
    margin: 0 calc(0.5 * var(--mini-unit)) 0 0;
  }
.NavigationItem-anchor-0d89cda3c91c2ca361ca0f8dfc5292cb > *:last-child {
    margin: 0;
  }
.NavigationItem-active-00ccd546336f11c6bd014b3aac526ac4 {
  font-weight: var(--font-weight-primary-bold);
  border-bottom: 3px solid #419EA7;
  color: #419EA7;
}

.Table-root-ff578e3ccfdf427fdc75ef9b0b1ab942 {
  border-collapse: collapse;
  box-sizing: border-box;
}

.Table-fullWidth-53e62978a7274502b18672b4c7754d0e {
  width: 100%;
}

.TableBody-root-1139ad78db6ecaf366a1f86c363b06a7 {
}

.TableHead-root-d08410994a34547db706322115044aa8 {
  background: var(--palette-grey-200);
  box-sizing: border-box;
}

.TableRow-root-434778d569f41b708a72528aa8fed4e3 {
  box-sizing: border-box;
}
.TableRow-body-d6af8cb387d558acfc892e7a203a62dd:nth-child(even) {
  background-color: var(--palette-grey-100);
}
.TableRow-body-d6af8cb387d558acfc892e7a203a62dd:hover {
  background-color: #E2FAF7;
}

.TableCell-root-ec462b1c1c40a473d5b4ca29f0d2883b {
  /* acts like min-width in a cell */
  height: calc(4.5 * var(--mini-unit));
  text-align: left;
  padding: var(--mini-unit) calc(1.5 * var(--mini-unit));
  box-sizing: border-box;
}
.TableCell-header-35a219cb5602eb97c5c84b81da501506 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-2);
    line-height: 1.14;
  color: var(--palette-text-500);
  padding: var(--spacing-3);
}
.TableCell-body-37ac22caba58c11b401eae6af20c0a17 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.14;
  color: var(--palette-text-500);
}
.TableCell-alignCenter-85879b12561ebc18e02313653d3e5ef0 {
  text-align: center;
}
.TableCell-alignEnd-c263c231b2fcbc5383049b4c4a5037d4 {
  text-align: right;
}

.TabBar-root-d443066dc1f0bff735e39fff14d1fa15 {
  display: flex;
  align-items: flex-end;
  padding: 0;
  margin: 0;
}
.TabBar-default-44236bcedef62155d397ed98ac4e70e5 {
  border-bottom: 1px solid var(--palette-grey-100);
}
.TabBar-primary-0e31c5dc75331ca71a5fce24bfa24f55 {
  border-bottom: 1px solid var(--palette-grey-300);
}
.TabBar-secondary-9bb139fcd0c52f4ee1cf435282b1705f {
  border-bottom: 1px solid var(--palette-divider);
}
.TabBar-streamSecondary-d2a78fd4fb5f8e2d5daaaceca9b6ef84 {
  border-bottom: 1px solid var(--palette-divider);
}
.TabBar-streamPrimary-176d57e0d98a8b07a2fbe7676c4facc2 {
  border-bottom: 1px solid var(--palette-grey-300);
}

.Tab-root-f3e878389453bcda280e7c10ec6a1260 {
  display: inline-block;
  list-style: none;
}
.Tab-button-6d68dce02925fd18461776edb2692b7e {
  box-sizing: border-box;
  border-bottom: 0;
  list-style: none;
}
.Tab-button-6d68dce02925fd18461776edb2692b7e:hover {
    cursor: pointer;
  }
.Tab-root-f3e878389453bcda280e7c10ec6a1260:first-child .Tab-primary-e4e4b664189cb938323d3875b0137cce {
  border-top-left-radius: var(--round-corners);
}
.Tab-root-f3e878389453bcda280e7c10ec6a1260:last-child .Tab-primary-e4e4b664189cb938323d3875b0137cce {
  border-top-right-radius: var(--round-corners);
}
.Tab-default-5f623c68b3a1e7fbead1c4eb4080c1d6 {
  margin-right: var(--spacing-3);
  margin-bottom: -1px;

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  color: var(--palette-text-100);
  padding-bottom: calc(var(--spacing-1) + 2px);
}
.Tab-default-5f623c68b3a1e7fbead1c4eb4080c1d6.Tab-active-c7162dccdcf7f0eceb82b59dc555281d {
    color: #419EA7;
    font-weight: var(--font-weight-primary-bold);
    border-bottom: 2px solid #419EA7;
    padding-bottom: var(--spacing-1);
  }
.Tab-primary-e4e4b664189cb938323d3875b0137cce {
  position: relative;
  background: var(--palette-grey-200);
  color: var(--palette-grey-500);
  border: 1px solid var(--palette-grey-300);
  padding: calc(0.5 * var(--mini-unit)) calc(var(--mini-unit) * 2);
}
.Tab-primary-e4e4b664189cb938323d3875b0137cce.Tab-active-c7162dccdcf7f0eceb82b59dc555281d {
    background-color: var(--palette-common-white);
    color: var(--palette-text-900);
    border-bottom: 0;
    border-top-width: calc(0.5 * var(--mini-unit));
    border-top-color: #2C7B8C;
    border-radius: 0;
    z-index: 10;
  }
.Tab-secondary-3c5eced02ada0af648b0c668363e8e22 {
  margin-right: var(--spacing-3);
  margin-bottom: -1px;

  font-family: var(--font-family-primary);
  color: var(--palette-text-100);
  padding-bottom: var(--spacing-2);
}
.Tab-secondary-3c5eced02ada0af648b0c668363e8e22.Tab-active-c7162dccdcf7f0eceb82b59dc555281d {
    color: #419EA7;
    font-weight: var(--font-weight-primary-bold);
    border-bottom: 3px solid #419EA7;
    padding-bottom: calc(var(--spacing-2) - 3px);
  }
.Tab-streamSecondary-71947cf2508d85c99d4c643d3fcbe591 {
  margin-right: var(--spacing-3);
  margin-bottom: -1px;

  font-family: var(--font-family-primary);
  color: var(--palette-text-500);
  padding-bottom: var(--spacing-2);
}
.Tab-streamSecondary-71947cf2508d85c99d4c643d3fcbe591.Tab-active-c7162dccdcf7f0eceb82b59dc555281d {
    color: var(--palette-primary-500);
    font-weight: var(--font-weight-primary-bold);
    border-bottom: 3px solid var(--palette-primary-500);
    padding-bottom: calc(var(--spacing-2) - 3px);
  }
.Tab-streamPrimary-788f1d302542d0297b23c1040eae710a {
  position: relative;

  margin-right: 0px;
  margin-bottom: -1px;

  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);

  background: var(--palette-grey-100);
  border: 1px solid var(--palette-grey-300);
  border-bottom-width: 0px;

  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: var(--font-weight-secondary-regular);
  font-size: var(--font-size-4);
  line-height: 1.11;

  color: var(--palette-text-500);
}
.Tab-streamPrimary-788f1d302542d0297b23c1040eae710a.Tab-active-c7162dccdcf7f0eceb82b59dc555281d {
    z-index: 10;

    background-color: var(--palette-background-body);
    border-bottom: 0;
    border-radius: 0;

    color: var(--palette-primary-500);
    font-weight: var(--font-weight-secondary-bold);

    overflow: visible;
  }
.Tab-streamPrimary-788f1d302542d0297b23c1040eae710a.Tab-active-c7162dccdcf7f0eceb82b59dc555281d::before {
  position: absolute;

  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: 4px;

  background-color: var(--palette-primary-500);
  color: var(--palette-primary-500);
  content: "active";
  overflow: hidden;
}
.Tab-root-f3e878389453bcda280e7c10ec6a1260:not(:first-child) .Tab-streamPrimary-788f1d302542d0297b23c1040eae710a {
  border-left-width: 0px;
}
.Tab-uppercase-81c7065751ea2e24c05e9d59b012a56e {
  text-transform: uppercase;
}

.Tag-root-ea52cee21a7a5e5a747c616d057e4413 {
  font-size: var(--font-size-2);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  color: var(--palette-text-000);
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
  white-space: nowrap;
  border-radius: 2px;
  display: inline-block;
}
.Tag-colorPrimary-c70a1dd66e065cf1977797fe5e0c9d09 {
  background-color: #2C7B8C;
}
.Tag-colorGrey-27f2501ebf8cf82b64f1be65de4c7234 {
  background-color: var(--palette-grey-500);
}
.Tag-colorError-adbbb888dfaeeff1e330fe67423bcd36 {
  background-color: var(--palette-error-500);
}
.Tag-colorDarkest-7336ee6f644ef0e72fd7bcd7986d293e {
  background-color: #11435D;
}
.Tag-colorStreamBlue-3e6f02a3642b36006d8c08009a912a62 {
  background-color: var(--palette-primary-500);
}
.Tag-variantPill-b21b373dbd52afcc072741b725cb730b {
  border-radius: 20px;
  padding: 0px 10px;
  background-color: inherit;
}
.Tag-variantPill-b21b373dbd52afcc072741b725cb730b.Tag-colorGrey-27f2501ebf8cf82b64f1be65de4c7234 {
    border: 1px solid var(--palette-grey-500);
    color: var(--palette-grey-500);
  }
.Tag-variantPill-b21b373dbd52afcc072741b725cb730b.Tag-colorPrimary-c70a1dd66e065cf1977797fe5e0c9d09 {
    border: 1px solid #2C7B8C;
    color: #2C7B8C;
  }
.Tag-variantPill-b21b373dbd52afcc072741b725cb730b.Tag-colorError-adbbb888dfaeeff1e330fe67423bcd36 {
    border: 1px solid var(--palette-error-500);
    color: var(--palette-error-500);
  }
.Tag-variantPill-b21b373dbd52afcc072741b725cb730b.Tag-colorDarkest-7336ee6f644ef0e72fd7bcd7986d293e {
    border: 1px solid #11435D;
    color: #11435D;
  }
.Tag-variantPill-b21b373dbd52afcc072741b725cb730b.Tag-colorStreamBlue-3e6f02a3642b36006d8c08009a912a62 {
    border: 1px solid var(--palette-primary-500);
    color: var(--palette-primary-500);
  }
.Tag-uppercase-03b2d8acc2edbf5dbd181b4119f2dfc5 {
  text-transform: uppercase;
}

.Textarea-root-898460491fb68a0d4e9fef2fff7599f2 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.3;
  padding: var(--spacing-2);
  box-sizing: border-box;
  border-radius: var(--round-corners);
  color: var(--palette-text-500);
  background-color: var(--palette-background-input);
  border: 1px solid var(--palette-grey-500);
}
  .Textarea-root-898460491fb68a0d4e9fef2fff7599f2:-moz-read-only {
    background-color: var(--palette-background-input-disabled);
  }
  .Textarea-root-898460491fb68a0d4e9fef2fff7599f2:read-only {
    background-color: var(--palette-background-input-disabled);
  }
  .Textarea-root-898460491fb68a0d4e9fef2fff7599f2:disabled {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-text-input-disabled);
    color: var(--palette-text-input-disabled);
  }
  .Textarea-root-898460491fb68a0d4e9fef2fff7599f2:focus {
    outline: none;
    border: 1px solid #59C3C3;
    box-shadow: inset 0px 0px 0px 1px #59C3C3;
  }

.Textarea-fullwidth-3952b85a91a0c36786e9877df4a2c4eb {
  width: 100%;
}

.TextFieldAdornment-root-fe1e76000611aa81af1aee001fabc8d5 {
  color: var(--palette-text-500);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.125;
}

.TextLink-root-89aacc344fdc7bf9fd198247baafded8 {
  color: var(--palette-primary-700);
  text-decoration: underline;
}

.TextLink-icon-7ef0d8cdf5a8ae129947df55aa96eff2 {
  padding-left: calc(0.5 * var(--mini-unit));
  padding-bottom: 2px;
  text-decoration: none;
}

.Timestamp-root-f0b3ebf43486d31ba5226a30c0e02f22 {
  cursor: default;
}

.Timestamp-text-b943991e0f8e51bb4bdba60eabe40535 {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.Tooltip-root-d7eadcd668b07787753ccebda33cc7d7 {
  align-items: center;
  display: flex;
}

.Tooltip-tooltip-828faac4a4d2c640f1209f5605ca847b {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 220px;
}

.Tooltip-title-b9437d852a0e3dbf4cce0361b3f20047 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  color: var(--palette-text-000);
  margin: 0 0 var(--spacing-1) 0;
  font-size: var(--font-size-2);
  line-height: 1.285;
}

.Tooltip-contents-77a815eed87e2fe84fd18d0fd480a01e {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  color: var(--palette-text-000);
  margin: 0;
  font-size: var(--font-size-2);
  line-height: 1.285;
}

.Button-base-3818d3c9d093073437f9d4751fbaac4e {
  border-radius: var(--round-corners);
}

.Button-upperCase-39e13d1556aa43c60592c2fe6c61b3ca {
  text-transform: uppercase;
}

.Button-fontFamilyPrimary-5e7cc1810b6bcde618b0fd8adb2a5c64 {
  font-family: var(--font-family-primary);
}

.Button-fontFamilySecondary-c4110f03580dab66dc52e552ea5d3c03{
  font-family: var(--font-family-secondary);
}

.Button-fontWeightPrimaryBold-6312a258e419ba1d8ae87a93becd2f8d {
  font-weight: var(--font-weight-primary-bold);
}

.Button-fontWeightPrimarySemiBold-5526f4e404413a488f7dc9d46867bf11 {
  font-weight: var(--font-weight-primary-semi-bold);
}

.Button-fontWeightPrimaryRegular-eba78b261c2cf9a8b45ce3074bb5ac6b {
  font-weight: var(--font-weight-primary-regular);
}

.Button-fontWeightSecondaryBold-6bf2fce5ff198e6935d5af214518be1b {
  font-weight: var(--font-weight-secondary-bold);
}

.Button-fontWeightSecondarySemiBold-b0c8c8cde66f056a0e59ae5b59cc29b6 {
  font-weight: var(--font-weight-secondary-bold);
}

.Button-fontWeightSecondaryRegular-5a71177594373d21af863176f37fde2e {
  font-weight: var(--font-weight-secondary-regular);
}

.Button-fontSizeExtraSmall-8b8822a1dd34c501fe4e92a6f1924744 {
  font-size: var(--font-size-1);
  line-height: 1.33;
}

.Button-fontSizeSmall-4e1ba350ee1ef655df8ddab86350c1ee {
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.Button-fontSizeMedium-0048e1876a3b66988e83e21fb1cd7c97 {
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.Button-fontSizeLarge-7f769af29603d6cb9ae90b2fefc29a30 {
  font-size: var(--font-size-3);
  line-height: 1;
}

.Button-textAlignLeft-2407eb1fd77d1ee6176c137ef4a24d7c {
  text-align: left;
}

.Button-textAlignCenter-73a2738f6e39888509e0aca47606b75d {
  text-align: center;
}

.Button-textAlignRight-cc1ddc7ac7704fb41b01612db94cf695 {
  text-align: right;
}

.Button-paddingSizeExtraSmall-1a4f75aa7b8e2ce3a8d168ec4e60330e {
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.Button-paddingSizeSmall-15a1ac3dc790b689d266a624f0506a81 {
  padding-left: var(--spacing-3);
  padding-right: var(--spacing-3);
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
}

.Button-paddingSizeMedium-ce5d6edfe8d7672cd5b1159fb72ccd71 {
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
}

.Button-paddingSizeLarge-f87a95dc2aea6846aa09d1af8ddfeeb8 {
  padding-left: var(--spacing-5);
  padding-right: var(--spacing-5);
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
}

.Button-filled-9c4ddf06cede7ce005be0601ee415057 {
  border-style: solid;
  border-width: 1px;
}

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorPrimary-c458ebec28203790ae35527d521f560a {
    color: var(--palette-text-000);
  }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorPrimary-c458ebec28203790ae35527d521f560a:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorPrimary-c458ebec28203790ae35527d521f560a:not(:disabled) {
      background-color: var(--palette-primary-500);
      border-color: var(--palette-primary-500);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorPrimary-c458ebec28203790ae35527d521f560a:hover, .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorPrimary-c458ebec28203790ae35527d521f560a.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      background-color: var(--palette-primary-600);
      border-color: var(--palette-primary-600);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorPrimary-c458ebec28203790ae35527d521f560a:active, .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorPrimary-c458ebec28203790ae35527d521f560a.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      background-color: var(--palette-primary-700);
      border-color: var(--palette-primary-700);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorPrimary-c458ebec28203790ae35527d521f560a.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      color: var(--palette-grey-400);
      background-color: var(--palette-grey-200);
      border-color: var(--palette-grey-200);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162 {
    color: var(--palette-text-000);
  }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:not(:disabled) {
      background-color: var(--palette-grey-500);
      border-color: var(--palette-grey-500);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:hover, .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      background-color: var(--palette-grey-600);
      border-color: var(--palette-grey-600);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:active, .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      background-color: var(--palette-grey-700);
      border-color: var(--palette-grey-700);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      color: var(--palette-grey-400);
      background-color: var(--palette-grey-200);
      border-color: var(--palette-grey-200);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5 {
    color: var(--palette-text-000);
  }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:not(:disabled) {
      background-color: var(--palette-error-500);
      border-color: var(--palette-error-500);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:hover, .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      background-color: var(--palette-error-600);
      border-color: var(--palette-error-600);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:active, .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      background-color: var(--palette-error-700);
      border-color: var(--palette-error-700);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      color: var(--palette-grey-400);
      background-color: var(--palette-grey-200);
      border-color: var(--palette-grey-200);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67 {
    color: var(--palette-text-000);
  }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:not(:disabled) {
      background-color: var(--palette-success-500);
      border-color: var(--palette-success-500);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:hover, .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      background-color: var(--palette-success-600);
      border-color: var(--palette-success-600);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:active, .Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      background-color: var(--palette-success-700);
      border-color: var(--palette-success-700);
    }

.Button-filled-9c4ddf06cede7ce005be0601ee415057.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      color: var(--palette-grey-400);
      background-color: var(--palette-grey-200);
      border-color: var(--palette-grey-200);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorPrimary-c458ebec28203790ae35527d521f560a {
    border-style: solid;
    border-width: 1px;
  }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorPrimary-c458ebec28203790ae35527d521f560a:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorPrimary-c458ebec28203790ae35527d521f560a:not(:disabled) {
      background-color: inherit;
      border-color: var(--palette-primary-500);
      color: var(--palette-primary-500);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorPrimary-c458ebec28203790ae35527d521f560a:hover, .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorPrimary-c458ebec28203790ae35527d521f560a.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      background-color: var(--palette-primary-500);
      border-color: var(--palette-primary-500);
      color: var(--palette-text-000);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorPrimary-c458ebec28203790ae35527d521f560a:active, .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorPrimary-c458ebec28203790ae35527d521f560a.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      background-color: var(--palette-primary-600);
      border-color: var(--palette-primary-600);
      color: var(--palette-text-000);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorPrimary-c458ebec28203790ae35527d521f560a.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      background-color: inherit;
      border-color: var(--palette-grey-400);
      color: var(--palette-grey-400);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162 {
    border-style: solid;
    border-width: 1px;
  }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:not(:disabled) {
      background-color: inherit;
      border-color: var(--palette-grey-500);
      color: var(--palette-grey-500);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:hover, .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      background-color: var(--palette-grey-500);
      border-color: var(--palette-grey-500);
      color: var(--palette-text-000);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:active, .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      background-color: var(--palette-grey-600);
      border-color: var(--palette-grey-600);
      color: var(--palette-text-000);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      background-color: inherit;
      border-color: var(--palette-grey-400);
      color: var(--palette-grey-400);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5 {
    border-style: solid;
    border-width: 1px;
  }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:not(:disabled) {
      background-color: inherit;
      border-color: var(--palette-error-500);
      color: var(--palette-error-500);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:hover, .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      background-color: var(--palette-error-500);
      border-color: var(--palette-error-500);
      color: var(--palette-text-000);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:active, .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      background-color: var(--palette-error-600);
      border-color: var(--palette-error-600);
      color: var(--palette-text-000);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      background-color: inherit;
      border-color: var(--palette-grey-400);
      color: var(--palette-grey-400);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67 {
    border-style: solid;
    border-width: 1px;
  }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:not(:disabled) {
      background-color: inherit;
      border-color: var(--palette-success-500);
      color: var(--palette-success-500);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:hover, .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      background-color: var(--palette-success-500);
      border-color: var(--palette-success-500);
      color: var(--palette-text-000);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:active, .Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      background-color: var(--palette-success-600);
      border-color: var(--palette-success-600);
      color: var(--palette-text-000);
    }

.Button-outlined-6e37911f3c0274594a54ff236c741cc9.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      background-color: inherit;
      border-color: var(--palette-grey-400);
      color: var(--palette-grey-400);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e {
  background-color: transparent;
}

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorPrimary-c458ebec28203790ae35527d521f560a:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorPrimary-c458ebec28203790ae35527d521f560a:not(:disabled) {
      color: var(--palette-primary-500);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorPrimary-c458ebec28203790ae35527d521f560a:hover, .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorPrimary-c458ebec28203790ae35527d521f560a.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      color: var(--palette-primary-600);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorPrimary-c458ebec28203790ae35527d521f560a:active, .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorPrimary-c458ebec28203790ae35527d521f560a.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      color: var(--palette-primary-700);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorPrimary-c458ebec28203790ae35527d521f560a.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      color: var(--palette-grey-400);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:not(:disabled) {
      color: var(--palette-grey-500);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:hover, .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      color: var(--palette-grey-600);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162:active, .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      color: var(--palette-grey-700);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSecondary-3bc2b7c29336486a6a4c1b67d199c162.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      color: var(--palette-grey-400);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:not(:disabled) {
      color: var(--palette-error-500);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:hover, .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      color: var(--palette-error-600);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5:active, .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      color: var(--palette-error-700);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorError-4d523e2995cfd81d5afc8af8e19851f5.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      color: var(--palette-grey-400);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:not(.Button-disabled-11fc2a834ab017309e2cece13844bc48),
    .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:not(:disabled) {
      color: var(--palette-success-500);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:hover, .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67.Button-mouseHover-2aca5cfb8e7f22311dae51df99ac525c {
      color: var(--palette-success-600);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67:active, .Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67.Button-active-fb31c35390ca9fca2c4d6b9f0eae63b8 {
      color: var(--palette-success-700);
    }

.Button-flat-409c47973ce75cd86d40ebe592ee565e.Button-colorSuccess-014acbe72f8a96a82284d66198de2f67.Button-disabled-11fc2a834ab017309e2cece13844bc48 {
      color: var(--palette-grey-400);
    }

.Button-underline-629a54c39ee697ba26a1bc41145a13ed {
  text-decoration: underline;
}

.Button-fullWidth-bb6f43f28b0b070474bc57cb08dd2865 {
  width: 100%;
}

.TooltipButton-button-0ba630d0554f642f7ba4764d2481d7b7 {
  line-height: 0;
  color: var(--palette-text-500);
  padding: 6px;
}

.InputLabel-root-e1dc122fe9eadd9c84dd7009473dc7d7 {
  display: block;

  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  line-height: 1.14;
  color: var(--palette-text-500);
}

.AppNotification-root-a3d470b132eacad79e974b2d549a4587 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  line-height: 1.2;
  padding: var(--spacing-2);
}

.AppNotification-inner-af41f5ffe7ea5f5da02b42b107eedf4a {
  max-width: 1280px;
  margin: 0 auto;
}

.AppNotification-success-edbdf9ef443569c9d02c40aa8ae2710e {
  background-color: var(--palette-success-300);
}

.HorizontalRule-root-fa23a9f3a031bedb24f08c67e4925903 {
  border: 0;
  border-bottom: 1px solid var(--palette-grey-300);
  padding-top: 1px;
}

.StepBar-root-be0577206c9e46b87d8101360509f56e {
  width: 100%;
  position: relative;
  display: block;
  padding: calc(2 * var(--mini-unit)) 0;
}

.Circle-root-cd1704b0a59bdb37f77a0e23cf03929c {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: inherit;
  border: 2px solid var(--palette-grey-400);
  box-sizing: border-box;
  border-radius: 100%;
  z-index: 1;
}

.Circle-active-8773e00cd87513d1891563c16254ddc1,
.Circle-completed-b1558cdba968a9a043e23d81c9236cbe {
  background-color: var(--palette-success-400);
  border-color: var(--palette-success-400);
}

.Circle-icon-8e410280dafb60b36b65aab02ccf80cd {
  color: var(--palette-text-000);
  font-weight: bold;
  margin-top: -8px;
  margin-left: 1px;
}

.Line-root-2bc29c8830e76e0281ce8f2ec4ccc670 {
  display: inline-block;
  width: 200px;
  height: 0px;
  border: 1px solid var(--palette-grey-400);
  box-sizing: border-box;
  z-index: 1;
}

.Line-completed-25e3e2a753e5f62efed6ac843b49ffdc {
  border-color: var(--palette-success-400);
}

.Step-root-97b60f138d36d2c3c68de86b41cf82b0 {
  position: relative;
}

.Step-text-2b9e02d5a1e21eafb417e94c3de74e8d {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.25;

  color: var(--palette-text-500);

  position: absolute;
  top: 26px;
  transform: translateX(-50%);
  left: 8%;
  white-space: nowrap;
}

.CheckIcon-base-6c068b4a8c7dd643d4abca8c16c4f7f7 path {
    fill: var(--palette-success-500)
  }

.InputDescription-root-59c7544127b704cb9ee707dc3063398b {
  color: var(--palette-text-100);

  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.125;
}

.Typography-root-ae2cf72d0f73e23a76f0423b4bb18528 {
  margin: 0;
  padding: 0;
}

.Typography-heading1-bb5011468c960c17ba227558b0d01c5f {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-heading2-7edb9d8dc76b799f74efaad397143281 {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-heading3-38de68b90ef020db79e84dc07b289c5c {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-heading4-f889247d684536f2784a1e754403d86d {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-heading5-261cc2b025c25472caaf69577461c0c8 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-header1-ee76379bbf911a6ee98fd20d2c37d31e {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-header2-60087e10c921a9babfeab68d289cc2d1 {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-header3-e85977a5779bb40e4d0e95af91b38ee9 {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-header4-17d5a2949d1f54175d4ba673fb48375f {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-header5-621740e2657e0dbe84658615ab72f1a6 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1;
  color: var(--palette-text-500);
}

.Typography-bodyCopy-76ca3e42adedd96425739ae411c093d3 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.125;
  color: var(--palette-text-500);
}

.Typography-bodyCopyBold-b6d5c64df2633c8514d528b7cc40fdd9 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  line-height: 1.125;
  color: var(--palette-text-500);
}

.Typography-bodyShort-309feb187f2cc88230e5bca6ac623c60 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.125;
  color: var(--palette-text-500);
}

.Typography-fieldDescription-2beaceb6e34a9c718f2007a1afb58357 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.14;
  color: var(--palette-text-100);
}

.Typography-button-82ed4b04181d509464d33d0c21791a37 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.285;
  color: var(--palette-text-500);
}

.Typography-buttonLarge-af6b391e059bec23ac2a37b0d8c988a7 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.25;
  color: var(--palette-text-500);
}

.Typography-detail-846dad6fff59ded8d5f239ea6ded6293 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.285;
  color: var(--palette-text-500);
}

.Typography-timestamp-21fa866af19f1cab0ea3b854989cc64e {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.285;
  color: var(--palette-text-100);
}

.Typography-alignLeft-e186a741f8768d5938880ac69823727e {
  text-align: left;
}

.Typography-alignCenter-b1c29eb274b215a1c17216c8ce865d88 {
  text-align: center;
}

.Typography-alignRight-28f11755524927ebd4fb2610d15558e1 {
  text-align: right;
}

.Typography-alignJustify-3d2313eb0df883bb3038ef34d40ad2b9 {
  text-align: justify;
}

.Typography-noWrap-44f44b717763f17c29379cb197e20093 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Typography-gutterBottom-e006ff938b822cde215dd457746ebcab {
  margin-bottom: 0.35em;
}

.Typography-paragraph-ffba1206381a2fbe63af11451d09c79f {
  margin-bottom: var(--mini-unit);
}

.Typography-colorPrimary-c834a8a1deae48355c82130571ff9464 {
  color: #2C7B8C;
}

.Typography-colorTextPrimary-d1f192e976886b9df4b288646aeba217 {
  color: var(--palette-text-500);
}

.Typography-colorTextSecondary-3dcdad2e79947e1dd9e8d5ea700ad9a9 {
  color: var(--palette-text-100);
}

.Typography-colorTextDark-cd712f5bf39e52d1c7397d72143d8976 {
  color: var(--palette-text-900);
}

.Typography-colorTextLight-7f5329fab92a455d53664ef2554cd4c7 {
  color: var(--palette-text-000);
}

.Typography-colorError-9278787c27dfa9c43aa10df51b2b99ee {
  color: var(--palette-error-500);
}

.Typography-colorErrorDark-af17e0509365fe462f48e9a6d5f370f3 {
  color: var(--palette-error-600);
}

.Typography-colorWarning-ce4924ba83a2deac3abc12b90b8702f7 {
  color: "#DC8400";
}

.Typography-colorSuccess-7accd480a1e5c959de6d17fb076d9237 {
  color: var(--palette-success-500);
}

.Typography-inputLabel-97d20b6cdd4eeef7480994c96d7a7c80 {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 0.89;
  color: var(--palette-text-500);
}

/* V2 Typography */

.Typography-bodyCommentV2-a1bb02c2d9ac03139d7c259893058716 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.45;
  color: var(--palette-text-500);
}

.TileSelector-root-ecd505f261967db51660ef0dd9941e26 {
}

.TileOption-input-5b96650ceb43ac7ba7d46f9d0bbf836c {
  cursor: pointer;
  position: absolute; /* take it out of document flow */
  opacity: 0; /* hide it */
}

.TileOption-label-fdcf1a2f7950a7a5173472911ed26c0b {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);

  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid var(--palette-grey-300);
  border-radius: var(--round-corners);
  color: var(--palette-text-500);
  padding: var(--spacing-1);
  line-height: 1;
  box-sizing: border-box;
  height: 100%;
}

.TileOption-checked-f88fab6075f60bdaa018e82a779d8d32 {
  border: 1px solid var(--palette-grey-700);
  background: var(--palette-grey-700);
  color: var(--palette-text-000);
}

.NetworkError-root-ee80d312c9af76ef6d59f05a020e39bc {
  background-color: var(--palette-error-300);

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  line-height: 1;
  color: var(--palette-text-500);

  border-radius: var(--round-corners);

  padding: var(--spacing-3);
}

.CallOut-root-e889291e7928622c431cbdb7706e6ac2 {
  position: relative;

  padding: var(--spacing-2);
}

.CallOut-topBorder-3c2b0a13918d6c1c1b12d648cefcba86 {
  border-top: 4px solid;
}

.CallOut-leftBorder-9db6e0efc4676b0b7514f820f421c015 {
  border-left: 4px solid;
}

.CallOut-container-b4f24bf1b9a6c46446539d88a865f039 {
  display: flex;
  flex-direction: row;
}

.CallOut-leftIcon-de2980785223d920515f1f4d737fefb1 {
  flex-basis: calc(var(--spacing-3) + var(--spacing-1) + 14px);
  margin-left: var(--spacing-1);
  margin-top: 0.5px;
}

.CallOut-content-e3de4b66c40e3387c51cd4ec9dd19194 {
  flex: 1 1;
}

.CallOut-actions-39c14776e51b4f6a7309d55c36500b82 {
  display: flex;
  flex-basis: calc(var(--spacing-2) + 14px);
  justify-content: center;
  align-items: flex-start;
}

.CallOut-title-38025ceb88291d77b56ce74d2e9e8de3 {
  font-family: var(--font-family-primary);
}

.CallOut-titleBold-13018c5d1e1563bb89fd271d6651b714 {
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-3);
  line-height: 1.25;
}

.CallOut-titleSemiBold-f6a10753e1d2a2ee8f1ac7647489b5df {
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.43;
}

.CallOut-titleMargin-0abc8bb794f11ca8c2041be3c235aeb0 {
  margin-bottom: var(--spacing-2);
}

.CallOut-body-83e5f92e2f29bcd5722a22a316a7881c {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.CallOut-icon-3c2defe8e908bbb4cf00712132615605.CallOut-mono-69977b1354c5ebaaa9eb4030c3284175 {
    color: var(--palette-text-500);
  }

.CallOut-icon-3c2defe8e908bbb4cf00712132615605.CallOut-success-290058f1ac1e70beba3fd3ed08cebcf1 {
    color: var(--palette-success-500);
  }

.CallOut-icon-3c2defe8e908bbb4cf00712132615605.CallOut-error-aa16e822b6b443c8f0723c6de20d0c43 {
    color: var(--palette-error-500);
  }

.CallOut-icon-3c2defe8e908bbb4cf00712132615605.CallOut-primary-8f1b3e8338b37331219a3c691fa00775 {
    color: var(--palette-primary-500);
  }

.CallOut-icon-3c2defe8e908bbb4cf00712132615605.CallOut-warning-ce7e3df2a4e22f39aa8209dbc63c05bb {
    color: var(--palette-warning-500);
  }

.CallOut-mono-69977b1354c5ebaaa9eb4030c3284175 {
  background-color: var(--palette-grey-100);
  border-color: var(--palette-grey-500);
  color: var(--palette-text-500);
}

.CallOut-success-290058f1ac1e70beba3fd3ed08cebcf1 {
  background-color: var(--palette-success-100);
  border-color: var(--palette-success-500);
  color: var(--palette-text-500);
}

.CallOut-error-aa16e822b6b443c8f0723c6de20d0c43 {
  background-color: #FFEAE9;
  border-color: #D53F3F;
  color: var(--palette-text-500);
}

.CallOut-primary-8f1b3e8338b37331219a3c691fa00775 {
  background-color: var(--palette-primary-100);
  border-color: var(--palette-primary-500);
  color: var(--palette-text-500);
}

.CallOut-warning-ce7e3df2a4e22f39aa8209dbc63c05bb {
  background-color: var(--palette-warning-100);
  border-color: var(--palette-warning-500);
  color: var(--palette-text-500);
}

.ExperimentalTag-root-fad96083e615a47f5ee7e09007009265 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-2);
  line-height: 1;

  border-radius: var(--round-corners);

  background-color: var(--palette-primary-100);
  color: var(--palette-primary-500);

  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);

  margin-right: var(--spacing-1);
}

.ExperimentalTag-button-ef05d0c09df96f55904648d3fe670c92 {
  color: var(--palette-text-100);
}

.StarRatingIcon-icons-cd42e9fee6167abae987e1fbb01c52b2 {
  color: var(--palette-warning-900);
}

.StarRatingIcon-interactive-e70be492a927bdce6e92327987a701af:hover {
  cursor: pointer;
}

.StarRatingIcon-visuallyhidden-4f42d4bd0ce706ed117623f6cd325a7a {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.StarRating-root-1144ed718fa0f8a1ee8ab41d13a376fd {
  display: inline-flex;
  position: relative;
  padding: 0;
  border: 0;
  margin: 0;
}

.TextArea-root-f3dd64082faeb1d9777823d2fbac4ef5 {
  display: flex;
  width: 100%;
  align-items: center;
}
.TextArea-input-73d4fde864d6f08bef2fd51a26fd418f {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;

  display: block;
  width: 100%;
  align-self: stretch;
  margin-top: 0;
  margin-bottom: 0;

  padding: var(--spacing-2);

  background-color: var(--palette-background-input);
  color: var(--palette-text-500);

  border-radius: var(--round-corners);
  border-style: solid;
}
.TextArea-input-73d4fde864d6f08bef2fd51a26fd418f:-moz-read-only {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-grey-400);
    color: var(--palette-text-input-disabled);
  }
.TextArea-input-73d4fde864d6f08bef2fd51a26fd418f:read-only,
  .TextArea-input-73d4fde864d6f08bef2fd51a26fd418f:disabled {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-grey-400);
    color: var(--palette-text-input-disabled);
  }
.TextArea-input-73d4fde864d6f08bef2fd51a26fd418f:focus {
    outline: none;
  }
.TextArea-colorRegular-9d5eb37a23aed132890f38cf4c6a6d10 {
  border: 1px solid var(--palette-grey-500);
}
.TextArea-colorRegular-9d5eb37a23aed132890f38cf4c6a6d10:focus {
    border: 1px solid #59C3C3;
    box-shadow: inset 0px 0px 0px 1px #59C3C3;
  }
.TextArea-colorStreamBlue-33594a066f86efdc3485638ec5279de0 {
  border: 1px solid var(--palette-grey-500);
}
.TextArea-colorStreamBlue-33594a066f86efdc3485638ec5279de0:focus {
    border: 1px solid var(--palette-primary-500);
    box-shadow: inset 0px 0px 0px 1px var(--palette-primary-500);
  }
.TextArea-colorError-2d8254dcbda433d715a9a271db25ad7f {
  border: 2px solid var(--palette-error-500);
}

.Tombstone-root-493327877672bb36df471c470554f499 {
  background-color: var(--palette-grey-200);

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
  color: var(--palette-text-500);

  padding: var(--spacing-2);

  text-align: center;
}
  @media (min-width: 321px) {.Tombstone-root-493327877672bb36df471c470554f499 {
    padding: var(--spacing-4)
}
  }

.Tombstone-fullWidth-1414f3bb0bf88a33347eef47c4a128a1 {
  width: 100%;
  box-sizing: border-box;
}

.ValidationMessage-root-fd223f23247c3dfaf5b01c9e422fd63b {
  font-family: var(--font-family-primary);

  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;

  color: var(--palette-error-500);
}

.ValidationMessage-icon-570d7694872d54c67308e8fc9b5e5ce0 {
  margin-right: var(--spacing-1);

  min-width: 16px;
}

.Link-root-dc6c27cf28d1dc5f01a91cb08f0bbbc2 {
  color: #2C7B8C;
}

.QueryError-heading-f7adc21d1564874a50bc5d941a8dca4f {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);

  padding-bottom: var(--spacing-1);
}

.QueryError-section-9b13375153f39bdeb8e50ea9cd5230f7 {
  padding-left: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.ApprovedIcon-root-99aa3f2fe144a5c3c6fcdcf9e05c97da {
  color: var(--palette-success-500);
}

.DecisionItem-root-39e6f7dcff049301587e18abcd9d29f5 {
  padding: var(--spacing-2) var(--spacing-1);
}

.DecisionItem-leftCol-7f104c0e937546bb06ea0ce25d200cd1 {
  display: flex;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 3px;
  margin-right: var(--spacing-2);
}

.DotDivider-root-6ca18efba13ab7e4aa6c18a2ea661cf4 {
  height: 100%;
  font-size: var(--font-size-1);
  line-height: 1.33;
  color: var(--palette-text-100);
  padding: 0 var(--spacing-1);
}

.Footer-root-c92fa28a48db4539ff822d3b1f0bffc8 {
  margin-top: var(--spacing-1);
}

.GoToCommentLink-root-49c2b4c6c8af346ce7814325e2fc938f {
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-1);
  line-height: 1.33;
  color: #2C7B8C;
  font-style: var(--font-style-underline);
}

.Info-root-996923fe659288e5cfc095813fdb3eec {
  /*
    Fallback begin

    Unfortunately Firefox / IE does not support
    `word-break: break-word` yet.
  */
  word-break: break-all;
  /* Fallback end */
  word-break: break-word;
  color: var(--palette-text-500);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-2);
  line-height: 1.285;
}

.Timestamp-root-1482bbb1138648119e6709b8049527fb {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-1);
  line-height: 1.33;
}

.Username-root-b6936c3b4f23f13aecf2f80789f87c8e {
  color: var(--palette-text-900);
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  line-height: 1.3;
  font-size: var(--font-size-3);
}

.RejectedIcon-root-92d42ee92fe2bde85885ab5ca594aa67 {
  color: var(--palette-error-500);
}

.DecisionList-root-ed4ee20024513c33238f841965b14c8b {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.DecisionList-root-ed4ee20024513c33238f841965b14c8b > * {
    border-bottom: 1px solid var(--palette-grey-300);
  }

.DecisionList-root-ed4ee20024513c33238f841965b14c8b > *:last-child {
    border-bottom: 0;
  }

.Empty-root-669fdc4fb7509635056a093bb9197971 {
  padding: calc(1.5 * var(--mini-unit)) calc(2 * var(--mini-unit));
}

.Empty-text-380a3852053bfe1f6c44281d715aab15 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.285;
}

.Main-root-2564150e67d0efa0fece25e5a850159b {
  max-height: calc(26 * var(--mini-unit));
  overflow: hidden;
  overflow-y: scroll;
}

.ShowMoreButton-root-24e45d531abed7fdbdd62d6b308084ee {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  line-height: 1.14;
  text-transform: uppercase;
  color: #2C7B8C;
  width: 100%;
  border-top: 1px solid var(--palette-grey-300);
  text-align: center;
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}
.ShowMoreButton-root-24e45d531abed7fdbdd62d6b308084ee:disabled {
    color: var(--palette-grey-300);
  }

.Title-root-c315e4819ab7d6e506759cc3f2422dcc {
  background: var(--palette-grey-200);
  padding: var(--spacing-1);
  color: var(--palette-text-500);
  border-bottom: 1px solid var(--palette-grey-300);
}
.Title-icon-5a7181691642698cdbe49243be5c1ca7 {
  font-size: var(--font-size-icon-md);
  margin-right: var(--spacing-2);
}
.Title-text-382164b17eab5bf8c2c1b8d3bb747e4c {
  text-transform: uppercase;
  font-weight: var(--font-weight-primary-bold);
  color: var(--palette-text-500);
  font-size: var(--font-size-2);
  line-height: 1;
  font-family: var(--font-family-primary);
}

.DecisionHistoryLoading-container-97e50de17f0d7f822588649cefc8a3b7 {
  padding: var(--mini-unit);
  min-height: 30px;
}

.DecisionHistoryButton-popover-261362d83ccfadf6963be93d2ad3620f {
  width: 350px;
}

.DecisionHistoryButton-historyIcon-d7a2bdbfab079bc87499dc572e5f56f0 {
  color: var(--palette-text-100);
  margin-right: 10px;
}

.UserMenu-button-44e761c0e96e2e464794526caed86fd0 {
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
}

.UserMenu-icon-df9e6b1f9cf890def9ba6512462dffae {
  color: var(--palette-text-100);
}

.UserMenu-buttonText-e0ef9bbbaf448021b2c56d8c7a87b874 {
  font-family: var(--font-family-secondary);
  color: var(--palette-text-100);
  font-size: var(--font-size-4);
  margin-left: 2px;
  margin-right: 1px;
}

.Version-version-02672e4fec76ad7f22ee5a96dd4a8ffe {
  font-size: 0.625rem;
  color: var(--palette-text-100);
  text-align: center;
  padding-bottom: var(--spacing-3);
}

body {
    padding: 0;
    margin: 0;
  }
  input::-ms-clear, input::-ms-reveal {
    display: none;
  }

.Main-root-30b900853bfc4cab938ee410715fa644 {
}

.Main-logoContainer-c6fe5584093376f5dabed03997202b03 {
  position: relative;
}

.NetworkError-root-fda2c69c8698a9fb78c1009e60464339 {
  padding: 20px;
}

.AuthBox-container-0939400effd5d2e8067fc9264b2d1f35 {
  width: 385px;
  background-color: var(--palette-grey-100);
  border: 1px solid var(--palette-grey-300);
  margin-top: 70px;
  padding: var(--spacing-6);
  box-sizing: border-box;
}

.Restricted-title-566c1afdc3f998c29c36a0d4344552e6 {
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  color: var(--palette-text-100);
  font-size: var(--font-size-3);
  line-height: 1.285;

  padding-bottom: var(--spacing-3);
}

.Restricted-username-35c7f0639e303dcd71a00bc1e1e20e74 {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  line-height: 1.17;
  color: var(--palette-text-900);

  text-align: center;

  padding-bottom: var(--spacing-5);
}

.Restricted-copy-207963a0ed98c8609d3663bb7105ab6f {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.125;
  color: var(--palette-text-500);

  text-align: center;
}

.Restricted-lockIcon-77120bdefc9ddfd70a678b9158195c6a {
  color: var(--palette-text-900);
  padding-bottom: var(--spacing-2);
}

.Restricted-noPermission-95c41a71ae87cf7e3067d962df553600 {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 0.89;
  color: var(--palette-text-900);

  text-align: center;

  padding-bottom: var(--spacing-5);
}

.Restricted-contactAdmin-67469e399a4e0f521b36d6f85d1b4430 {
  padding-top: var(--spacing-3);
}

.MainLayout-root-a12d17baf1af8d6299355700ffd5aefd {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 var(--mini-unit);
  box-sizing: border-box;
}

.NotAvailable-root-3052ca45c315a789807bd675368b9235 {
  color: var(--palette-text-100);
}

.ModalHeader-root-e2af92e197cc3ec598c48389eadb49f2 {
  font-size: var(--font-size-5);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.2;
  color: #353F44;
  margin: 0;
}

.ModalHeaderUsername-root-362d6c4a05c941988161e5f2a15eb952 {
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  color: #14171A;
  font-size: 1.4rem;
}

.ChangeStatusModal-root-2355da457a101ddf8e95f27a5fe21b2b {
  max-width: 500px;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-3)
    var(--spacing-3);
}

.UserStatusSitesListContainer-header-12b43635599eb028dbffd8eb2cd9b539 {
  margin-bottom: var(--spacing-3);
}

.UserStatusSitesListContainer-sitesToggle-4a0b7937b29434196f259e5a784f6e97 {
  margin-bottom: var(--spacing-3);
}

.BanModal-bodyText-70b846c6aeb32f7b520e5733a910a0d7 {
  font-size: var(--font-size-3);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.3;
  color: var(--palette-text-500);
}

.BanModal-textArea-5f39b0e3368b058d0afb6035276e65dd {
  height: calc(12 * var(--mini-unit));
}

.ModalBodyText-root-77fa507930304baf6987e2a8114ec497 {
  font-size: var(--font-size-3);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.3;
  color: #353F44;
}

.SuspendForm-radioButton-6a80c396f570f972f8a57392b30325be {
  margin: 0 var(--spacing-1) 0 0 !important;
}

.SuspendForm-textArea-3d1aefcfaac5d50d252d7fd97add900f {
  width: 100%;
  box-sizing: border-box;
  height: calc(12 * var(--mini-unit));
  padding: calc(0.5 * var(--mini-unit));
}

.SuspendForm-header-3d6235b679e420ee387d9156f4f833e1 {
  margin: 0 0 var(--spacing-3) 0 !important;
}

.SuspendForm-subTitle-09a6dea09b48b9b109ce4f644392135a {
  font-size: var(--font-size-4);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.11;
  color: var(--palette-text-500);
  margin: 0;
}

.SuspendForm-footer-3add076421146afc3fe6292f969cd869 {
  margin-top: var(--spacing-3);
}

.UserStatusChange-button-e543c03e2670ce73e7bf1007fadf2a68 {
  justify-content: space-between;
  display: flex;
}

.UserStatusChange-bordered-2ccf58414051f930ec5f329093ac8320 {
  padding: 0 var(--spacing-2);
  border: 1px solid var(--palette-grey-400);
}

.UserStatusChange-fullWidth-edd4bebed27ca2b4899005c9441ffe00 {
  width: 100%;
}

.UserStatusChange-dropdownButton-0fe75592f2264d0fe5e5705fc55595b5 {
  min-width: 80px;
}

.UserStatus-root-bddcaa15f2641be0058bc4c8ece045b5 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.UserStatus-success-c2f39fe06bfe8def9fae612516ac6c4d {
  color: var(--palette-success-500);
}

.UserStatus-warning-9e48eb212be055810463af1ea307a961 {
  color: var(--palette-error-500);
  font-weight: var(--font-weight-primary-semi-bold);
}

.UserStatus-error-40c7c497d87119392206e057d90a6492 {
  color: var(--palette-error-700);
  font-weight: var(--font-weight-primary-semi-bold);
}

.WarnForm-label-52cfe63c89c234c30a23f7cddc078ec6 {
  margin-bottom: 0;
}

.WarnForm-required-17eae30694427a0c6a0665ae1a14f78b {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
}

.FacebookButton-icon-aab49e3eb4a8e1fddcd4bccc436fe332 {
  margin-right: var(--spacing-2);

  height: 1rem;
  overflow: hidden;
}

.FacebookButton-button-4de310e819b15bf063fdcabb8bf4e306 {
  border-style: solid;
  border-width: 1px;

  color: var(--palette-text-000);
}

.FacebookButton-button-4de310e819b15bf063fdcabb8bf4e306:not(.FacebookButton-disabled-0849a3e2e52aea81548cbec52398b2d5),
  .FacebookButton-button-4de310e819b15bf063fdcabb8bf4e306:not(:disabled) {
    background-color: #3B5998;
    border-color: #3B5998;
  }

.FacebookButton-button-4de310e819b15bf063fdcabb8bf4e306:hover, .FacebookButton-button-4de310e819b15bf063fdcabb8bf4e306.FacebookButton-mouseHover-39b900abdd7c9a6fc4ea2b2e4ad6ee2a {
    background-color: #4467b0;
    border-color: #4467b0;
  }

.FacebookButton-button-4de310e819b15bf063fdcabb8bf4e306:active, .FacebookButton-button-4de310e819b15bf063fdcabb8bf4e306.FacebookButton-active-81457280c87396115b77911b389f85b2 {
    background-color: #6583c3;
    border-color: #6583c3;
  }

.FacebookButton-button-4de310e819b15bf063fdcabb8bf4e306.FacebookButton-disabled-0849a3e2e52aea81548cbec52398b2d5 {
    color: var(--palette-grey-400);
    background-color: var(--palette-grey-200);
    border-color: var(--palette-grey-200);
  }

.GoogleButton-icon-4a73060e63df819a469148440f092ef9 {
  margin-right: var(--spacing-2);

  height: 1rem;
  overflow: hidden;
}

.GoogleButton-button-06e56362c5d3772bde25983a6792b47c {
  border-style: solid;
  border-width: 1px;

  color: var(--palette-text-000);
}

.GoogleButton-button-06e56362c5d3772bde25983a6792b47c:not(.GoogleButton-disabled-41ae595d90c12931c9cae78ecb712b46),
  .GoogleButton-button-06e56362c5d3772bde25983a6792b47c:not(:disabled) {
    background-color: #db4437;
    border-color: #db4437;
  }

.GoogleButton-button-06e56362c5d3772bde25983a6792b47c:hover, .GoogleButton-button-06e56362c5d3772bde25983a6792b47c.GoogleButton-mouseHover-d05f4e3ae2ee0bab683d0a50a9a7e4a7 {
    background-color: #e05f54;
    border-color: #e05f54;
  }

.GoogleButton-button-06e56362c5d3772bde25983a6792b47c:active, .GoogleButton-button-06e56362c5d3772bde25983a6792b47c.GoogleButton-active-de2d1d5ec4738b5b90daee9f3ff6e042 {
    background-color: #e57a71;
    border-color: #e57a71;
  }

.GoogleButton-button-06e56362c5d3772bde25983a6792b47c.GoogleButton-disabled-41ae595d90c12931c9cae78ecb712b46 {
    color: var(--palette-grey-400);
    background-color: var(--palette-grey-200);
    border-color: var(--palette-grey-200);
  }


.OIDCButton-button-36921c002cee2f3ffc3742d860a15a33 {
  border-style: solid;
  border-width: 1px;

  color: var(--palette-text-000);
}

  .OIDCButton-button-36921c002cee2f3ffc3742d860a15a33:not(.OIDCButton-disabled-3df7c2df5a94a9b373a6506fedee69b5),
  .OIDCButton-button-36921c002cee2f3ffc3742d860a15a33:not(:disabled) {
    background-color: var(--palette-grey-500);
    border-color: var(--palette-grey-500);
  }

  .OIDCButton-button-36921c002cee2f3ffc3742d860a15a33:hover, .OIDCButton-button-36921c002cee2f3ffc3742d860a15a33.OIDCButton-mouseHover-093c8047f46c3a50063509a67226e4cf {
    background-color: var(--palette-grey-400);
    border-color: var(--palette-grey-400);
  }

  .OIDCButton-button-36921c002cee2f3ffc3742d860a15a33:active, .OIDCButton-button-36921c002cee2f3ffc3742d860a15a33.OIDCButton-active-970168f8d70d02209083155ddf93f403 {
    background-color: var(--palette-grey-300);
    border-color: var(--palette-grey-300);
  }

  .OIDCButton-button-36921c002cee2f3ffc3742d860a15a33.OIDCButton-disabled-3df7c2df5a94a9b373a6506fedee69b5 {
    color: var(--palette-grey-400);
    background-color: var(--palette-grey-200);
    border-color: var(--palette-grey-200);
  }

.Markdown-root-3d2b06281c90101cf5bd89f8e574c33c {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.375;
}

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c b,
  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c strong {
    font-weight: var(--font-weight-primary-bold);
  }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c h1 {
    font-size: var(--font-size-6);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c h2 {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c h3 {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c h4 {
    font-size: var(--font-size-3);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c h5 {
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c *:first-child {
    margin-top: 0;
  }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c *:last-child {
    margin-bottom: 0;
  }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c blockquote {
    background-color: rgba(255, 255, 255, 0.66);
    padding: var(--mini-unit);
    margin: calc(2 * var(--mini-unit)) 0 calc(2 * var(--mini-unit))
      var(--mini-unit);
    border-radius: var(--round-corners);
  }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c blockquote::after {
      content: none;
    }

  .Markdown-root-3d2b06281c90101cf5bd89f8e574c33c blockquote::before {
      content: none;
    }

.FadeInTransition-appear-b3abb7c0dce18ce94cd11bce795666e6 {
  opacity: 0;
  pointer-events: none;
}
.FadeInTransition-appearActive-43d448a68d7d7897f91e1d8b7304a69f {
  opacity: 1;
  transition: opacity 400ms;
}

.DurationField-value-5d97792811cb215975f924173c09097c {
  width: calc(6 * var(--mini-unit));
}

.DurationField-select-39880b90b61b5ed75f804a47c9c344b8 {
  height: 100%;
  min-width: calc(17 * var(--mini-unit));
}

.DurationField-unit-9584d6745c00ac2711825260bb2bfad9 {
  line-height: 36px;
}

.MemberBioContainer-title-c9cb5a481682ed3d605c1f0bf86fcb19 {
  text-transform: uppercase;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-3);
  margin: 0;
  line-height: 1.3;
  color: var(--palette-text-500);
}
.MemberBioContainer-contents-98c26993f09b792f8485eccbd04ede60 {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  margin: 0;
  line-height: 1.285;
}

.RecentHistory-title-cdf7abf67a1983cacaec37774d15a9ef {
  text-transform: uppercase;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-3);
  margin: 0;
  line-height: 1.3;
  color: var(--palette-text-500);
}
.RecentHistory-subTitle-ea13700a4791c6b3f1710a0522aea643 {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  margin: 0;
  line-height: 1.285;
}
.RecentHistory-info-532bf7961f49f8ae941a18e976182dd7 {
}
.RecentHistory-amount-ed12bfd439f2a8a9d071661287a35cdd {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-6);
  line-height: 1;
  color: var(--palette-text-500);
  margin: 0;
}
.RecentHistory-amountLabel-5fcce8c89b98261fb747f1d9be1955b2 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  color: var(--palette-text-500);
  margin: 0;
}
.RecentHistory-triggered-84a4a1f65c72d3013af906c261b840ba {
  color: var(--palette-error-500);
}
.RecentHistory-tooltip-fa221805f8f6e4672289faaf6acd374a {
  color: var(--palette-text-500);
  padding: 3px;
}

.UsernameChangeAction-tableLight-1c429fafd85acd6676439d6730159131 {
  font-weight: var(--font-weight-primary-regular);
}

.UsernameChangeAction-usernameCell-095490ee750d4b7362a4096d25465fe9 {
  line-height: 1.2857142857142858em;
}

.UserDrawerAccountHistory-tableHeader-6582eb514937a6f9c31385260e06966e {
  border-width: 0px;
  border-style: none;
  background-color: var(--palette-grey-200);
}

.UserDrawerAccountHistory-row-c1866eeffe9480b048616f2771d26714:first-child:hover {
  border-top: none;
}

.UserDrawerAccountHistory-row-c1866eeffe9480b048616f2771d26714:nth-child(even) {
  /* NOTE: differs from pallate colors */
  background-color: rgba(242, 242, 242, 0.4);
}

.UserDrawerAccountHistory-row-c1866eeffe9480b048616f2771d26714:nth-child(even):hover {
  /* NOTE: differs from pallate colors */
  background-color: rgba(242, 242, 242, 0.4);
  box-shadow: none;
}

.UserDrawerAccountHistory-row-c1866eeffe9480b048616f2771d26714:nth-child(odd):hover {
  background-color: transparent;
  box-shadow: none;
}

.UserDrawerAccountHistory-rowBanned-697fc297d551edf0de1aa099a21797e0 {
  background-color: var(--palette-error-100);
}

.UserDrawerAccountHistory-rowBanned-697fc297d551edf0de1aa099a21797e0:hover {
  background-color: var(--palette-error-100);
}

.UserDrawerAccountHistory-date-7abb028711e73280c0dae5abddcd7a70 {
  min-width: 80px;
}

.UserDrawerAccountHistory-action-2d0c2d810024cb01a9cbae93dccb3443 {
  min-width: 150px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserDrawerAccountHistory-user-cfbfd49f873fab0add8ad4013939447e {
  min-width: 125px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserDrawerAccountHistory-description-1d5b9ec640d857007d9ea9c773663173 {
  min-width: 150px;
}

.UserDrawerAccountHistory-user-cfbfd49f873fab0add8ad4013939447e > * {
  vertical-align: sub;
}

.UserDrawerAccountHistoryQuery-spinner-6efa59757539e139c1c3a31257bb00b5 {
  text-align: center;
}

.UserDrawerAccountHistoryQuery-callout-748918ddc2b462392291fe3fad1ef5ee {
  width: 100%;
  font-family: var(--font-family-primary);
  align-content: center;
  text-align: center;
}

.ModeratorNote-root-08582b93a94b65a9089dcff1a9f6bb14 {
}
.ModeratorNote-body-b24b4b448f970946c681f4236300f299 {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.3;
  background-color: var(--palette-grey-100);
  border-radius: 4px;
  padding: var(--spacing-3);
  color: var(--palette-text-500);
  margin-bottom: var(--spacing-2);
}
.ModeratorNote-bodyType-998fb7529634ce0fbfbb0193a422617a {
}
.ModeratorNote-leftBy-9b0b0fc2a8e9bcf89ab7791064a5136c {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.14;
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-1);
  position: relative;
  color: var(--palette-text-500);
}
.ModeratorNote-leftBy-9b0b0fc2a8e9bcf89ab7791064a5136c:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: ;
  position: absolute;
  border-radius: 50%;
  left: var(--spacing-1);
  background-color: var(--palette-text-100);
  top: 50%;
}
.ModeratorNote-username-18957f515b25e22683ad268ef8fa5254 {
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary-bold);
  font-size: var(--font-size-3);
  line-height: 1;
  color: var(--palette-text-900);
}
.ModeratorNote-footerLeft-92d24a0de8e6d076d660aa4814ba708f {
  padding-left: var(--spacing-3);
}

.UserDrawerNotesContainer-root-097faf54172fc29702f5bbbbf0be8a06 {
}

.UserDrawerNotesContainer-textArea-9ec14cc0e81c90f21eee9dc5edf69655 {
  width: 100%;
  margin-bottom: var(--spacing-2);
}

.UserDrawerNotesContainer-textArea-9ec14cc0e81c90f21eee9dc5edf69655:focus {
  outline: none;
}

.UserDrawerNotesContainer-form-3a940d4373d444cbae851946dcbbc5cc {
  padding: var(--spacing-2) 0;
  margin-bottom: var(--spacing-4);
}

.UserDrawerNotesQuery-root-b2697a107492b20468caadb38db2255e {

}

.UserDrawerNotesQuery-spinner-01c4ad001dcfd75fd8a4ef4e5507a3d1 {
  text-align: center;
}

.UserDrawerNotesQuery-callout-0d249e6c4944697e03ed23b63e6ec507 {
  width: 100%;
  font-family: var(--font-family-primary);
  align-content: center;
  text-align: center;
}

.Media-embed-f0a005747aec8ff4740534460ddf01dc {
  margin: var(--spacing-2) 0;
}

.Media-toggle-736e10d4b8e2c46d6b6367e765a01f3a {
  position: relative;
}

.Media-toggleTrigger-0052ea2f91897e1b64b405936a4933fd {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(101, 105, 107, 0.6);
}

.Media-playIcon-3b31f35d70c6fdbace642d752ad79017 {
  color: var(--palette-text-000);
}

.Media-playText-69643c43a8c17d2f15f7b871a0b40c0f {
  color: var(--palette-text-000);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  text-transform: uppercase;
  margin: 0;
}

.Media-image-1717ee80819a6ec723bf89131f842244 {
  display: block;
  max-width: 100%;
}

.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.45;
  color: var(--palette-text-900);
  word-wrap: break-word;
}
.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 b,
  .CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 strong {
    font-weight: var(--font-weight-primary-bold);
  }
.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 i,
  .CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 em {
    font-style: italic;
  }
.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 blockquote {
    background-color: var(--palette-grey-100);
    padding: var(--mini-unit);
    margin: calc(2 * var(--mini-unit)) 0 calc(2 * var(--mini-unit))
      var(--mini-unit);
    border-radius: var(--round-corners);
  }
.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 blockquote::after {
      content: none;
    }
.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 blockquote::before {
      content: none;
    }
.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 mark {
    background-color: var(--palette-warning-500);
    padding: 0 2px;
  }
.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 mark a {
      color: #2C7B8C;
    }
.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 a {
    color: #2C7B8C;
    background-color: var(--palette-warning-500);
    padding: 0 2px;
  }
.CommentContent-root-e1ad1e8c0ecd6e55234ac00013b534e6 a:hover {
      color: #11435D;
    }
.CommentContent-highlight-e7be294cf136a48974aa96f922f4581d {
  white-space: pre-wrap;
}

.InReplyTo-icon-f96e5c17d8be04fd17bda74cc06f08b9 {
  color: var(--palette-text-100);
}
.InReplyTo-inReplyTo-a8a5f91449e2801627fdb96dc2c6f101 {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height:  1.14;
}
.InReplyTo-username-0851b5f3f08e633d3691f8a197377a20 {
  color: var(--palette-text-500);
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary-bold);
  font-size: var(--font-size-3);
  line-height: 1;

  padding: var(--spacing-1);
}
.InReplyTo-username-0851b5f3f08e633d3691f8a197377a20:hover {
    background-color: var(--palette-grey-200);
    border-radius: var(--round-corners);
    border-style: none;
  }
.InReplyTo-username-0851b5f3f08e633d3691f8a197377a20:active {
    background-color: var(--palette-grey-200);
    border-radius: var(--round-corners);
    border-style: none;
  }
.InReplyTo-usernameButton-40459510ac4157bf9ec76f1c36a5fc51 {
  vertical-align: baseline;

  margin-left: calc(-1 * var(--spacing-1));
}

.Username-root-a15f00d1717262c6a5eb0e6a81bbdc51 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  line-height: 1;
  color: var(--palette-text-900);
}

.UsernameButton-root-ce81f86e21ff780b3e2628f394b918d5 {
  margin-right: var(--mini-unit);
  padding: var(--spacing-1);
  margin-left: calc(-1 * var(--spacing-1));

  line-height: 1;
}

.UsernameButton-root-ce81f86e21ff780b3e2628f394b918d5:hover {
    background-color: var(--palette-grey-200);
    border-radius: var(--round-corners);
    border-style: none;
  }

.UsernameButton-root-ce81f86e21ff780b3e2628f394b918d5:active {
    background-color: var(--palette-grey-200);
    border-radius: var(--round-corners);
    border-style: none;
  }

.ApproveButton-root-ed872641380398fbbb61bfd2d76eace7 {
  border: 1px solid var(--palette-success-500);
  box-sizing: border-box;
  border-radius: var(--round-corners);
  width: 65px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--palette-success-500);
}

.ApproveButton-root-ed872641380398fbbb61bfd2d76eace7:not(:disabled):active {
    background-color: var(--palette-success-500);
    color: var(--palette-text-000);
  }

.ApproveButton-readOnly-d2d53b96a2a6402cc5f05b202d241073 {
  background-color: transparent;
  border-color: #CBD1D2);
  color: #CBD1D2);
}

.ApproveButton-readOnly-d2d53b96a2a6402cc5f05b202d241073:hover {
    cursor: not-allowed;
  }

.ApproveButton-readOnly-d2d53b96a2a6402cc5f05b202d241073 {
  background-color: transparent;
  border-color: #CBD1D2;
  color: #CBD1D2;
}

.ApproveButton-readOnly-d2d53b96a2a6402cc5f05b202d241073:hover {
    cursor: not-allowed;
  }

.ApproveButton-invert-bec371b5119ea5cb28579f12758ba011 {
  background-color: var(--palette-success-500);
  border-color: var(--palette-success-500);
  color: var(--palette-text-000);
}

.ApproveButton-icon-7d65908a962e29d9e77e61df9629aae4 {
  font-weight: var(--font-weight-primary-semi-bold);
  color: inherit;
}

.CommentAuthorContainer-authorStatus-3cd41f7121bc8de9dea80ee6fb903e95 {
  padding-right: var(--spacing-2);
}

.FeatureButton-root-63483568ac164c6de958cf7e75309e93 {
  position: absolute;
  right: 0px;
  top: 2px;

  border: 1px solid var(--palette-grey-500);
  box-sizing: border-box;
  border-radius: var(--round-corners);
  padding: 2px var(--spacing-2) 2px var(--spacing-2);
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--palette-grey-500);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.2;
}

  .FeatureButton-root-63483568ac164c6de958cf7e75309e93:active {
    background-color: #E2FAF7;
    border-color: #2C7B8C;
    color: #2C7B8C;
  }

  .FeatureButton-root-63483568ac164c6de958cf7e75309e93:disabled {
    cursor: not-allowed;
    background-color: transparent;
    border-color: var(--palette-grey-300);
    color: var(--palette-grey-300);
  }

.FeatureButton-invert-46ff98c03bbe49ddd248d07b647e4493 {
  background-color: #2C7B8C;
  border-color: #2C7B8C;
  color: var(--palette-text-000);
}

.FeatureButton-invert-46ff98c03bbe49ddd248d07b647e4493:disabled {
    color: var(--palette-text-000);
    border-color: var(--palette-grey-300);
    background-color: var(--palette-grey-300);
  }

.Markers-detailsButton-a6ae1e20e7aef49da9238785e0870a5d {
  font-size: var(--font-size-1);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.28;
  height: 100%;
  padding: 2px 0px;
  margin-left: var(--mini-unit);

  align-self: flex-end;
  text-transform: uppercase;
}
.Markers-detailsButtonColorRegular-7814dde31312453978f64410183e644b {
  border-color: var(--palette-text-500);
}
.Markers-detailsText-f45b8ba17e62a636eb2d1ca5f524eb58 {
  margin: 0;
}

.FlagDetailsCategory-category-4bf455ca4ed87c7378034cb1a63f77a3 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);

  color: var(--palette-text-500);
}

.ToxicityLabel-root-ce26a135ac5460c62648e05600383ccf {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.285;
  color: var(--palette-text-500);

  padding-left: var(--spacing-2);
  padding-bottom: var(--spacing-1);
  padding-top: var(--spacing-1);
}
.ToxicityLabel-likely-f4d723c5f1edb0b6b9bf696f99d5ba80 {
  color: var(--palette-error-500);
}

.AutomatedActionsContainer-name-d0745bdb12086d103218a287044822a1 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);

  color: var(--palette-text-500);
}

.AutomatedActionsContainer-body-3b7e4430c478204ffa904dc347195219 {
  padding-left: var(--spacing-2);
}

.AutomatedActionsContainer-title-17a22d5275cb73253205229f83076aec {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);

  padding-bottom: var(--spacing-1);
  padding-right: var(--spacing-1);

  line-height: 1.285;

  color: var(--palette-text-500);
}

.AutomatedActionsContainer-section-a7aa53d715736dcdda22ee35357e915c {
  padding-bottom: var(--spacing-1);
}

.AutomatedActionsContainer-marker-0dff263e1ac0d4dbf1798094dd6fb2ee {
  margin-right: var(--spacing-1);
}

.AutomatedActionsContainer-detailsDivider-c5dfe286d673fa3cea471dae11c579e9 {
  border-top: 1px solid #F4F7F7;
}

.FlagDetailsEntry-user-6771eb35acfda013a1c24b58225b849e {
  color: var(--palette-text-900);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  line-height: 1.14;
  padding-right: calc(0.5 * var(--mini-unit));
}
.FlagDetailsEntry-details-79980ccc0b787e035573467fefcf5b05 {
  color: var(--palette-text-500);

  display: block;
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.14;
}
.FlagDetailsEntry-flagger-fa294515257df8459655f3d649856b2d {
  margin-right: var(--mini-unit);
  padding: var(--spacing-1);
  margin-left: calc(-1 * var(--spacing-1));
}
.FlagDetailsEntry-flagger-fa294515257df8459655f3d649856b2d:hover {
    background-color: var(--palette-grey-200);
    border-radius: var(--round-corners);
    border-style: none;
  }
.FlagDetailsEntry-flagger-fa294515257df8459655f3d649856b2d:active {
    background-color: var(--palette-grey-300);
    border-radius: var(--round-corners);
    border-style: none;
  }

.LinkDetailsContainer-label-5ae276a9674597ca833328ddc66d6a93 {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  color: var(--palette-text-500);
}

.LinkDetailsContainer-buttonContainer-5ef7b8dd27561bcf97f5470da856f134 {
  display: inline-block;
  padding-right: var(--spacing-2);
}

.ModerateCardDetailsContainer-button-d246dff9dcef8f297e5285fc7c213015 {
  text-transform: uppercase;
  font-size: var(--font-size-2);
}

.RejectButton-root-b7f0b9f10c3e6f50c9555a525bb407f1 {
  border: 1px solid var(--palette-error-500);
  box-sizing: border-box;
  border-radius: var(--round-corners);
  width: 65px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--palette-error-500);
}

.RejectButton-root-b7f0b9f10c3e6f50c9555a525bb407f1:not(:disabled):active {
    background-color: var(--palette-error-500);
    color: var(--palette-text-000);
  }

.RejectButton-readOnly-ba5361ebd4043b221d9a50122da6f414 {
  background-color: transparent;
  border-color: #CBD1D2);
  color: #CBD1D2);
}

.RejectButton-readOnly-ba5361ebd4043b221d9a50122da6f414:hover {
    cursor: not-allowed;
  }

.RejectButton-readOnly-ba5361ebd4043b221d9a50122da6f414 {
  background-color: transparent;
  border-color: #CBD1D2;
  color: #CBD1D2;
}

.RejectButton-readOnly-ba5361ebd4043b221d9a50122da6f414:hover {
    cursor: not-allowed;
  }

.RejectButton-invert-9fd9ac739b32b8390a89b8dbfc148573 {
  background-color: var(--palette-error-500);
  color: var(--palette-text-000);
  border-color: var(--palette-error-500);
}

.RejectButton-icon-3a2532da5a9f3b78141a939cd6296d6f {
  color: inherit;
}

.ModerateCard-topBar-f69aae20913058da8b44139518c4b930 {
  position: relative;
  margin-bottom: var(--spacing-1);
}
.ModerateCard-topBarMini-7a16ce31ab33d2205ed8ca1216e26e48 {
  margin-bottom: var(--spacing-4);
}
.ModerateCard-inReplyTo-953458071789e78760f0dd8ec071c920 {
}
.ModerateCard-footer-ed4ee181209efa48e3ad3746da938c4c {
  margin-top: calc(2 * var(--mini-unit));
}
.ModerateCard-mainContainer-ece51700c927b5741680bded641656fd {
  flex-grow: 1;

  margin-right: calc(2 * var(--spacing-5));
}
.ModerateCard-ratingsArea-cbaf1fda79291037f16644f24f306078 {
  margin-bottom: var(--spacing-1);
}
.ModerateCard-contentArea-cd0903448b0880767daf67eec7cb1ca1 {
  position: relative;
}
.ModerateCard-content-e0d48672d0674649e64d93b1d9b492c5 {
  min-height: 3em;
}
.ModerateCard-aside-f1e588be8919a14ee3e3c45d421412a8 {
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;
  padding-top: 25px;
}
.ModerateCard-asideWithoutReplyTo-34e17f16dabd0ae69cd0b218b5489dfd {
  padding-top: 10px;
}
.ModerateCard-asideMini-b1771f779917bb159bbffe4f62eeff9a {
  /*
    No reply to field, so we align below the fattest element
    which is the feature button.
  */
  padding-top: calc(var(--font-size-2) + 7px + var(--spacing-4));
}
.ModerateCard-asideMiniWithReplyTo-ef8d4d6112aa38dcd60e4221123e0a14 {
  /*
    We have reply to field, so we align below the fattest
    element plus the reply to field.
  */
  padding-top: calc(2 * var(--font-size-2) + 5px + var(--spacing-4));
}
.ModerateCard-decision-57b908cb7a498fa64920f0a4064e8600 {
  color: var(--palette-text-500);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  line-height: 1.14;
  text-transform: uppercase;
}
.ModerateCard-separator-e83621c3b2218de94d0ed3db7b3cfddf {
  position: absolute;
  top: 0;
  right: calc(-1 * var(--spacing-5));
  bottom: 0;
  width: 2px;
}
.ModerateCard-ruledSeparator-32e1185c37fd604d33e9ffdbcaa35eeb {
  border-right: 1px solid var(--palette-grey-400);
}
.ModerateCard-root-66896568e937851ad1dbe59876e8c8b3 {
  transition: background 100ms, box-shadow 100ms;
}
.ModerateCard-root-66896568e937851ad1dbe59876e8c8b3:focus {
  outline: none;
}
.ModerateCard-dangling-af2c1afa3129d83d10bbdbf984c30ecf {
  background-color: var(--palette-grey-200);
  box-shadow: none;
}
.ModerateCard-link-f391d22f3f89ed78f10b3ec1dfe67af4 {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.33;
  color: #2C7B8C;
  text-transform: uppercase;
}
.ModerateCard-viewContext-276134ba3d9e6dcdb20838f157f44cf6 {
  padding-bottom: var(--spacing-1);
}
.ModerateCard-storyLabel-f70d85d490fb0016b60dbd95759bec19 {
  color: var(--palette-grey-500);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.33;
  margin-bottom: var(--spacing-1);
  text-transform: uppercase;
}
.ModerateCard-commentOn-b6e2cd9d19f3b56a884c9404ec346402 {
  font-size: var(--font-size-2);
  font-family: var(--font-family-primary);
  line-height: 1.14;
  margin-bottom: var(--spacing-1);
}
.ModerateCard-siteName-6d0fd9861864ba610dd8727d9f081036 {
  font-weight: var(--font-weight-primary-regular);
}
.ModerateCard-storyTitle-f66ab8485e5ad0db3286d8088cad482e {
  font-weight: var(--font-weight-primary-semi-bold);
  color: var(--palette-text-500);
}
.ModerateCard-borderless-18f8eeccb2b6c9a1e83156e9a812c66e {
  border-width: 0px;
  box-shadow: none;
  padding: var(--spacing-2) 0;
}
.ModerateCard-miniButton-234c8b543078d075bf1a7b963c77e42c {
  width: 40px;
  height: 40px;
}
.ModerateCard-timestamp-d7a9c5e3c0a36bf6945633a46451d42d {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-1);
  line-height: 1.33;
}
.ModerateCard-edited-0ea7fa9deaf1b88147d10eeb495e0307 {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-1);
  line-height: 1.33;
  padding-left: var(--spacing-2);
}
.ModerateCard-selected-94d077af8a7885633b5406318679e995 {
  box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.25);
}
.ModerateCard-authorStatus-9d14b3cfccc30f112ebf756b5e2f9043 {
  padding-right: var(--spacing-2);
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-1);
  line-height: 1.33;
  padding-left: var(--spacing-2);
}
.ModerateCard-deleted-ebaa9ee8a011463dbe24be93a5995495 {
  background: var(--palette-grey-200);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.285;
}

.ModeratedByContainer-moderatedBy-e6076890c2478e9740da2ca622117c34 {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.33;
  color: var(--palette-text-100);
  text-transform: uppercase;
}
.ModeratedByContainer-moderatedByUsername-a0dca67c280a05a2a1489b0503e3c4c3 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  line-height: 1.14;
  color: var(--palette-text-900);
  text-align: center;
  padding-top: 1px;
}

.UserHistoryDrawerAllComments-footer-f7617aa8e2707678790e9bf4b1996232 {
  text-align: center;
}

.UserHistoryDrawerAllComments-loadMore-87ebee5760cc43f32166b53ea2b34665 {
  width: 100%;
  background-color: #FFFFFF;

  border-width: 1px;
  border-style: solid;
  border-color: var(--palette-grey-300);
  border-radius: var(--round-corners);

  margin-top: var(--spacing-1);
}

.UserHistoryDrawerAllCommentsQuery-root-72082059c840d4e2bb36e3ce91a5d4bb {
  text-align: center;
}

.UserHistoryDrawerAllCommentsQuery-callout-4209dce6edc8c462bcf031762ceb8b22 {
  width: 100%;
  font-family: var(--font-family-primary);
  align-content: center;
  text-align: center;
}

.UserHistoryDrawerRejectedComments-footer-4986d2e8cb1cb1f0cd4956fd109aa873 {
  text-align: center;
}

.UserHistoryDrawerRejectedComments-loadMore-94f7e108e385e26c94d3fc4cb5106073 {
  width: 100%;
  background-color: #FFFFFF;

  border-width: 1px;
  border-style: solid;
  border-color: var(--palette-grey-300);
  border-radius: var(--round-corners);

  margin-top: var(--spacing-1);
}

.UserHistoryDrawerRejectedCommentsQuery-root-6e741b0f8a61b7198f362a568ce4550f {
  text-align: center;
}

.UserHistoryDrawerRejectedCommentsQuery-callout-d70e59bc2cf22546ce190fe6932e0821 {
  width: 100%;
  font-family: var(--font-family-primary);
  align-content: center;
  text-align: center;
}

.Tabs-root-b90b6aff99cced88489b49ec09d1bddb {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;

  overflow: hidden;
}

.Tabs-tabBar-66e8e9dbe27ed8883e45d0d0643a2d6a {
  flex: none;
  border-bottom: 0px;
}

.Tabs-tab-77be0cbcc32dbffa6e096e6c3ae3529b {
}

.Tabs-activeTab-4c1ff50fb1f76f23407322ed9cac1617 {
}

.Tabs-tabIcon-fcd7d0c4c9d87bc6b65a7444269e4006 {
  margin-right: var(--spacing-1);
}

.Tabs-tabContent-66980007c733cbbf59ad35ba00537872 {
  flex: auto;
  overflow: hidden;
}

.Tabs-container-c94c1036816937d67d5f8feb19e5c388 {
  height: calc(100% - var(--spacing-2) - var(--spacing-3));

  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;

  overflow: hidden;

  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-3);
}

.Tabs-scrollable-88807861259d897f3093344422ee576b {
  overflow-x: hidden;
  overflow-y: auto;
}

.Tabs-redDot-c78f298a220fe2e044e21ff7c898b4b9 {
  background-color: #59C3C3;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-left: 2px;
}

.UserStatusDetailsContainer-root-97f0f3ae719687c312f51241d24503bc {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  color: var(--palette-text-500);
  line-height: 1.285;
  margin: 0 0 var(--spacing-1) 0;
}

.UserStatusDetailsContainer-root-97f0f3ae719687c312f51241d24503bc strong {
  font-weight: var(--font-weight-primary-bold);
}

.UserHistoryDrawerContainer-comments-c43b986befcfb5b8ac01833e571c861a {
  flex: auto;
  overflow: auto;
}
.UserHistoryDrawerContainer-close-736ffc73af057d1c740fbf024cbc8a9c {
  position: absolute;
  width: 40px;
  height: 40px;
  left: -40px;

  padding: 0px;

  background-color: #FFFFFF;

  border-width: 1px;
  border-right-width: 0px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 4px 0px 0px 4px;
}
.UserHistoryDrawerContainer-username-b26cbf93d6ee9e3589fbcde6ef76a4cb {
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary-bold);
  font-size: var(--font-size-6);
  line-height: 0.67;
}
.UserHistoryDrawerContainer-userDetail-0dc2b4bbcaab1f4525afc76d9dd1738f {
}
.UserHistoryDrawerContainer-userDetailValue-6ffd44b439c1e8dd6c0d14fd73b22b8e {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;
  color: var(--palette-text-500);
}
.UserHistoryDrawerContainer-icon-01a63a024f66d334d24950f90ebf5fd5 {
  padding-top: 2px;
}
.UserHistoryDrawerContainer-copy-2135bf1b4ec484e736132b448a8473f7 {
}
.UserHistoryDrawerContainer-divider-af72b006869d82e7e86e9ef492db8e35 {
  border-bottom: 1px solid var(--palette-grey-300);
}
.UserHistoryDrawerContainer-userStatus-0d22109ac2c77961a27e898b4f84476d {
}
.UserHistoryDrawerContainer-userStatusLabel-cf3bd203e09474fb380b18c69e00d3d1 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
  color: var(--palette-text-500);
  margin-right: var(--spacing-1);
}
.UserHistoryDrawerContainer-userStatusChange-70d718c31898a785dae24f1d3a1eeaf9 {
}
.UserHistoryDrawerContainer-notAvailable-54803435580dde90e8ea2b5ad0623f27 {
  color: var(--palette-text-100);
}

.UserHistoryDrawerQuery-root-99e1fe014bfea35f5d568dd70086da5a {
  width: 100%;
  font-family: var(--font-family-primary);
  align-content: center;
  text-align: center;
}

.UserHistoryDrawer-root-201f8afdf8d9d34ca876ff028fa73d5d {
  position: fixed;
  top: 0;
  right: 0;

  width: 624px;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  flex-direction: column;

  background-color: #FFFFFF;
}

.EmptyMessage-root-fcae0e843321cbcbf0487ebbb91fa17b {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.3;
  text-align: center;
}

.ButtonPadding-root-f43cc5a0b0a69775434c6743bbe7bf5d {
  padding-top: 3px;
}

.UserRoleChangeButton-active-5f22bd6991d1e1d8f3a2a5ca10845378 {
  font-weight: bold;
}

.UserRoleText-root-0204fdb6d942e636a9e0e26a97dc68fe {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  color: var(--palette-text-500);
}

.UserRoleText-commenter-79102eea7ac6772df4d8c7611017fae2 {
  color: var(--palette-text-500);
  font-weight: var(--font-weight-primary-regular);
}

.SiteModeratorActions-button-c0d7bd7e6b8ab887387d51661f66f66a {
  width: 100%;
  justify-content: space-between;
  padding: 0;
}

.SiteModeratorActions-modal-43e91ca03e2fdab9c746870ff9538db4 {
  max-width: 500px;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-3) var(--spacing-3);
}

.SiteModeratorModalSiteField-listGroup-2cd14c4eebc941500e0703fb30f519eb {
  max-height: 250px;
}

.SiteModeratorModal-root-4014f53ab4e71cfb512c21a501481a32 {
  max-width: 500px;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-3)
    var(--spacing-3);
}

.UserRoleChange-button-b514e243ef079f508eebac36a7ee6ed9 {
  width: 100%;
  justify-content: space-between;
  padding: 0;
}

.UserRow-usernameColumn-30b26a2e9dbee3343f82c7312395d72d {
  vertical-align: top;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 15px;
}
.UserRow-emailColumn-e03489fe942a0fc5dbb8fe0e05f01d70 {
  vertical-align: top;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 15px;
}
.UserRow-memberSinceColumn-8203b2742bff5460bd44948e75f33b39 {
  vertical-align: top;
  white-space: nowrap;
  padding-top: 15px;
  padding-bottom: 15px;
}
.UserRow-roleColumn-9e12f6841b8fa3e139cbc300ecbab91b {
}
.UserRow-statusColumn-9fe437396e195a9e6567d8a828b1de80 {
}
.UserRow-usernameButton-a49eb0208cbf83e7c283e6c06b8fc013 {
  padding: 0px;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
  word-break: break-word;
  white-space: break-spaces;
}
.UserRow-username-73996f0090701dfa2e124670d5b01ce9 {
  display: inline-block;
}
.UserRow-deleted-74ae34404dc3a37db64ecab3d24c0f8e {
  display: inline-block;
  padding-left: var(--spacing-2);
  color: #DC8400;
}
.UserRow-notAvailable-d69e94590a39d2cff750b507f9250db7 {
  color: var(--palette-text-100);
}

.UserTable-usernameColumn-e3be5f4b251f46b3c0c4a99b90cc76b3 {
  width: 20%;
}
.UserTable-emailColumn-abe8d15dc639a4a9185de651c42578d6 {
  width: 35%;
}
.UserTable-memberSinceColumn-9211d028d08dbb87100f9e062e78f928 {
  width: 15%;
}
.UserTable-roleColumn-e9e1812d728218276f8630af82044bf1 {
  width: 15%;
}
.UserTable-statusColumn-7abb6045cda3adf43b6439d8cb893262 {
  width: 15%;
}

.InviteUsersForm-bodyText-c1e41556efc24ac7aefa2635d56f2ebf {
  font-size: var(--font-size-3);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.3;
  color: var(--palette-text-500);
}

.Success-box-c0390e6974e109f3520f896e035cfb2b {
  width: 100%;
}

.Success-title-81680bd5265688eb379d0c1f2ff84e62 {
  font-size: var(--font-size-5);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.2;
  color: var(--palette-text-500);
  margin: 0;
}

.InviteUsersModal-root-663b6f893a7046e7a6bb886d86d4ff62 {
  width: 400px;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-3)
    var(--spacing-3);
}

.InviteUsersModal-title-83c5b02ff0fe738a59f14245bb3bcae7 {
  font-size: var(--font-size-5);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.2;
  color: var(--palette-text-500);
  margin: 0;
}

.InviteUsersModal-clearfix-4d17e425d27387b9305b99d80e2cb7f4:after {
  content: "";
  display: table;
  clear: both;
}

.InviteUsers-button-6f6ea37c48bbdf1c0f867bbd962cd3e3 {
  padding: var(--spacing-2);
}

.UserTableFilter-textField-adc7458111f2f320c554f280e4f455c6 {
  width: calc(45 * var(--mini-unit));
}

.UserTableFilter-adornment-8277ed3f37c9fe011680aa001f648bf2 {
  padding: 0 var(--mini-unit);
}

.UserTableFilter-selectField-31fe6826f16fde33b18ed546bc19b84e {
  min-width: calc(16 * var(--mini-unit));
}

.Community-root-56fec3df7547e8a9804adb7fefb54834 {
  max-width: 950px;
  margin-top: calc(3 * var(--mini-unit));
  margin-bottom: calc(3 * var(--mini-unit));
}

.Link-link-7742314bd2ff8a94e293d336ac2d4a60 {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-4);
  line-height: 1.11;
  font-weight: var(--font-weight-primary-regular);
  display: inline-block;
  padding: var(--spacing-2) var(--spacing-2)
    var(--spacing-2) calc(var(--spacing-3) + 4px);
  border-left: 1px solid var(--palette-grey-300);
  text-decoration: none;
}
.Link-link-7742314bd2ff8a94e293d336ac2d4a60:hover {
    cursor: pointer;
    border-left: 2px solid #F77160;
    padding-left: calc(var(--spacing-3) + 3px);
    color: var(--palette-text-500);
  }
.Link-linkActive-1e5420d4502ef3f6027056df1756e409, .Link-linkActive-1e5420d4502ef3f6027056df1756e409:hover {
  border-left: 4px solid #F77160;
  padding-left: var(--spacing-3);
  color: var(--palette-text-500);
  font-weight: var(--font-weight-primary-bold);
}

.Navigation-root-bfcb0ab9572b0233f0f0b57d7e3b9d62 {
}

.Navigation-ul-0eb92570881014092307089872e930e4 {
  list-style: none;
  padding: 0;
}

.Layout-root-1c7e2767b3a816be88ddb636c5e906cd {
  padding: calc(3 * var(--mini-unit)) calc(5 * var(--mini-unit))
    calc(5 * var(--mini-unit)) calc(5 * var(--mini-unit));
}

.Main-root-ee4df41be5890ed4a7fdbd78d281d5eb {
  width: 100%;
  max-width: calc(84 * var(--mini-unit));
}

.SideBar-root-40f2f998be552543a529d4ae5bf505a5 {
  width: calc(31 * var(--mini-unit));
  padding-right: calc(3 * var(--mini-unit));
  position: sticky;
  top: 0;
  align-self: flex-start;
}

.ConfigBox-root-52ff2c988205367d9bba45a3ab8a6482 {
}

.ConfigBox-title-45d5d4d4ce0573617a20733934b1b7a2 {
  background: #11435D;
  width: calc(100% - 2 * var(--spacing-2));
  padding: var(--spacing-2);
}

.ConfigBox-content-2e616cf462a31dd1593200adb40f2d16 {
  padding: var(--spacing-3) var(--spacing-2) 0 var(--spacing-2);
}

.Header-root-679b074143d16304f2df8a607120a15f {
  border-style: none;
  margin: 0;
  color: var(--palette-text-000);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-bold);
  line-height: 1;
  font-family: var(--font-family-primary);
}

.ExternalLink-root-37cd0cd222e0efb1ec3c174368b58612 {
  color: #2C7B8C;
}

.HelperText-root-5d52e088f3cb7b07c43b0a4e835f888b {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.285;
}

.Subheader-root-d988f12e7da805d60846b39bf80daaf9 {
  border-bottom: 1px solid var(--palette-grey-300);
  color: var(--palette-text-500);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-bold);
  padding-bottom: var(--spacing-2);
  margin: 0;
  line-height: var(--lh-reset);
}

.AccountFeaturesConfig-radioButtons-7f09bad41c1f52beeec347cbf5077729 {
  display: flex;
  padding-left: var(--spacing-4);
  align-items: flex-start;
}
  .AccountFeaturesConfig-radioButtons-7f09bad41c1f52beeec347cbf5077729 > *:not(:last-child) {
    margin-right: var(--spacing-5);
  }

.HorizontalRule-root-a6cdc1d64003eb06d1505c8b23d2fb4d {
  border: 0;
  border-bottom: 1px solid var(--palette-grey-300);
  padding-top: 1px;
}

.StatusField-status-279d8ec33ada81f4d6820cd1e27141cc {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.14;

  border-radius: 2px;
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
}

.StatusField-active-54512e8a3c76318cb37cae23323e2cb8 {
  background-color: var(--palette-success-500);
  color: var(--palette-text-000);
}

.StatusField-expiring-e83a84a1236c7fc95bfb2f820dfacd6c {
  background-color: var(--palette-warning-500);
  color: var(--palette-text-500);

  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.StatusField-expired-17a2ae63b1a664a658e9f0d63405be54 {
  background-color: var(--palette-error-500);
  color: var(--palette-text-000);

  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.StatusField-icon-71480f3b2e8e64277520522ef59585a5 {
  padding-right: var(--spacing-1);
}

.DateField-label-f97a8438e39bfbc68cdaaf0baf5a71da {
  padding-bottom: var(--spacing-2);
}

.DateField-date-8e0f97f01a4f6b155c4e160e5601b357 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.RotationDropdown-rotate-d8f44f103fd71376e21c06648d9415c3 {
  margin-right: var(--spacing-1)
}

.SSOSigningSecretCard-label-71abc341912ab9c87dd9e7fc42778109 {
  padding-bottom: var(--spacing-2);
}

.SSOSigningSecretCard-keySection-a85e517607e978383a5995661a484589 {
  flex-grow: 1;
  min-width: 50px;

  padding-right: var(--spacing-3);
}

.SSOSigningSecretCard-statusSection-c2e7a5fb8038b1307e060fc69702380d {
  margin-right: var(--spacing-3);
}

.SSOSigningSecretCard-secretSection-01c3b71ca0aa992d8f06d49614199717 {
  flex-grow: 1;
  min-width: 50px;
}

.SSOSigningSecretCard-action-7f8ccdc45915d189ca401a0d546b6a01 {
  margin-right: var(--spacing-1)
}

.icon-icon-041641edcc3b8257d6dffb307e557bf3 {
  font-family: "Material Icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  letter-spacing: 0;

  /* Enable Ligatures */
  font-feature-settings: "liga", normal, "discretionary-ligatures";
  -ms-font-feature-settings: "liga" 1;
  font-variant-ligatures: "discretionary-ligatures";

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac {
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.375;
  color: var(--palette-text-500);
}
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac i
  em {
    font-style: italic;
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac b,
  .MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac strong {
    font-weight: var(--font-weight-primary-semi-bold);
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac h1 {
    font-size: var(--font-size-6);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac h2 {
    font-size: var(--font-size-5);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac h3 {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac h4 {
    font-size: var(--font-size-3);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac h5 {
    font-size: var(--font-size-2);
    font-weight: var(--font-weight-primary-semi-bold);
    font-family: var(--font-family-primary);
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac *:first-child {
    margin-top: 0;
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac *:last-child {
    margin-bottom: 0;
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac blockquote {
    background-color: rgba(0, 0, 0, 0.075);
    padding: var(--mini-unit);
    margin: calc(2 * var(--mini-unit)) 0 calc(2 * var(--mini-unit))
      var(--mini-unit);
    border-radius: var(--round-corners);
  }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac blockquote::after {
      content: none;
    }
.MarkdownEditor-wrapper-9ceeea9e2b746d5387ff3e13f4f974ac blockquote::before {
      content: none;
    }
.MarkdownEditor-iconBold-1aaa5aea06abd2cad6d8b309ef148776 {
}
.MarkdownEditor-iconBold-1aaa5aea06abd2cad6d8b309ef148776::before {
    content: "format_bold";
  }
.MarkdownEditor-iconItalic-986e967c490d5b432ccefd9d8aaec0ae {
}
.MarkdownEditor-iconItalic-986e967c490d5b432ccefd9d8aaec0ae::before {
    content: "format_italic";
  }
.MarkdownEditor-iconTitle-e69056169539d031daaca99d9800673a {
}
.MarkdownEditor-iconTitle-e69056169539d031daaca99d9800673a::before {
    content: "title";
  }
.MarkdownEditor-iconQuote-dc943094139865470c2002aa2db51685 {
}
.MarkdownEditor-iconQuote-dc943094139865470c2002aa2db51685::before {
    content: "format_quote";
  }
.MarkdownEditor-iconUnorderedList-6fa26e85668595c6924ae97da4438c1e {
}
.MarkdownEditor-iconUnorderedList-6fa26e85668595c6924ae97da4438c1e::before {
    content: "format_list_bulleted";
  }
.MarkdownEditor-iconOrderedList-65b790fa42a5837e4d45441f2f384498 {
}
.MarkdownEditor-iconOrderedList-65b790fa42a5837e4d45441f2f384498::before {
    content: "format_list_numbered";
  }
.MarkdownEditor-iconLink-ab080df725ceb9489e296e260a7f26ca {
}
.MarkdownEditor-iconLink-ab080df725ceb9489e296e260a7f26ca::before {
    content: "link";
  }
.MarkdownEditor-iconImage-8129b812da17943a858c6102cb655b3b {
}
.MarkdownEditor-iconImage-8129b812da17943a858c6102cb655b3b::before {
    content: "insert_photo";
  }
.MarkdownEditor-iconPreview-cba4512b8f7f143c18abae99eacdae4c {
}
.MarkdownEditor-iconPreview-cba4512b8f7f143c18abae99eacdae4c::before {
    content: "remove_red_eye";
  }
.MarkdownEditor-iconSideBySide-39d7e7582d9b6481017f2ef4411e804c {
}
.MarkdownEditor-iconSideBySide-39d7e7582d9b6481017f2ef4411e804c::before {
    content: "chrome_reader_mode";
  }
.MarkdownEditor-iconFullscreen-7418a7f4fc2764c769dee8644a8f6a35 {
}
.MarkdownEditor-iconFullscreen-7418a7f4fc2764c769dee8644a8f6a35::before {
    content: "fullscreen";
  }
.MarkdownEditor-iconGuide-2c6b72d7fb279fa896a5830c8ac90507 {
}
.MarkdownEditor-iconGuide-2c6b72d7fb279fa896a5830c8ac90507::before {
    content: "help_outline";
  }
/*
 * These are modified styles taken from https://github.com/NextStepWebs/simplemde-markdown-editor and
 * put through http://sebastianpontow.de/css2compass/.
 */
/*
 * simplemde v1.11.2
 * Copyright Next Step Webs, Inc.
 * @link https://github.com/NextStepWebs/simplemde-markdown-editor
 * @license MIT
 */
.CodeMirror {
    font-family: monospace;
    color: black;
    position: relative;
    overflow: hidden;
    background: white;
    height: auto;
    min-height: 200px;
    border: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px;
    font: inherit;
    z-index: 1;
  }
.CodeMirror pre {
      padding: 0 4px;
      border-radius: 0;
      border-width: 0;
      background: transparent;
      font-family: inherit;
      font-size: inherit;
      margin: 0;
      white-space: pre;
      word-wrap: normal;
      line-height: inherit;
      color: inherit;
      z-index: 2;
      position: relative;
      overflow: visible;
      font-feature-settings: none;
      font-variant-ligatures: none;
    }
.CodeMirror span {
      /*TODO: vertical-align: text-bottom;*/
    }
.CodeMirror .CodeMirror-code .cm-tag {
        color: #63a35c;
      }
.CodeMirror .CodeMirror-code .cm-attribute {
        color: #795da3;
      }
.CodeMirror .CodeMirror-code .cm-string {
        color: #183691;
      }
.CodeMirror .CodeMirror-code .cm-header-1 {
        font-size: 200%;
        line-height: 200%;
      }
.CodeMirror .CodeMirror-code .cm-header-2 {
        font-size: 160%;
        line-height: 160%;
      }
.CodeMirror .CodeMirror-code .cm-header-3 {
        font-size: 125%;
        line-height: 125%;
      }
.CodeMirror .CodeMirror-code .cm-header-4 {
        font-size: 110%;
        line-height: 110%;
      }
.CodeMirror .CodeMirror-code .cm-comment {
        background: rgba(0, 0, 0, 0.05);
        border-radius: var(--round-corners);
      }
.CodeMirror .CodeMirror-code .cm-link {
        color: #7f8c8d;
      }
.CodeMirror .CodeMirror-code .cm-url {
        color: #aab2b3;
      }
.CodeMirror .CodeMirror-code .cm-strikethrough {
        text-decoration: line-through;
      }
.CodeMirror .CodeMirror-code .cm-tab {
        display: inline-block;
        text-decoration: inherit;
      }
.CodeMirror .CodeMirror-code .CodeMirror-ruler {
        border-left: 1px solid #ccc;
        position: absolute;
      }
.CodeMirror .CodeMirror-code .cm-header {
        font-weight: bold;
      }
.CodeMirror .CodeMirror-code .cm-strong {
        font-weight: bold;
      }
.CodeMirror .CodeMirror-code .cm-em {
        font-style: italic;
      }
.CodeMirror .CodeMirror-code .cm-link {
        text-decoration: underline;
      }
.CodeMirror .CodeMirror-code .cm-strikethrough {
        text-decoration: line-through;
      }
.CodeMirror .CodeMirror-code .cm-invalidchar {
        color: #f00;
      }
.CodeMirror .CodeMirror-selected {
      background: #d9d9d9;
    }
.CodeMirror .CodeMirror-placeholder {
      opacity: 0.5;
    }
.CodeMirror div.CodeMirror-secondarycursor {
      border-left: 1px solid silver;
    }
.CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word) {
      background: rgba(255, 0, 0, 0.15);
    }
.CodeMirror-lines {
    padding: 4px 0;
    cursor: text;
    min-height: 1px;
  }
.CodeMirror-scrollbar-filler {
    background-color: white;
    position: absolute;
    z-index: 6;
    display: none;
    right: 0;
    bottom: 0;
  }
.CodeMirror-gutter-filler {
    background-color: white;
    position: absolute;
    z-index: 6;
    display: none;
    left: 0;
    bottom: 0;
  }
.CodeMirror-gutters {
    border-right: 1px solid #ddd;
    background-color: #f7f7f7;
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100%;
    z-index: 3;
    box-sizing: content-box;
  }
.CodeMirror-guttermarker {
    color: black;
  }
.CodeMirror-guttermarker-subtle {
    color: #999;
  }
.CodeMirror-cursors {
    visibility: hidden;
    position: relative;
    z-index: 3;
  }
.CodeMirror-cursor {
    border-left: 1px solid black;
    border-right: none;
    width: 0;
    position: absolute;
  }
@-webkit-keyframes MarkdownEditor-blink-3dd20724821bae0896bc824efb552409 {
    0% {
    }
    50% {
      background-color: transparent;
    }
    100% {
    }
  }
@keyframes MarkdownEditor-blink-3dd20724821bae0896bc824efb552409 {
    0% {
    }
    50% {
      background-color: transparent;
    }
    100% {
    }
  }
.CodeMirror-scroll {
    overflow: scroll !important;
    margin-bottom: -30px;
    margin-right: -30px;
    padding-bottom: 30px;
    height: 100%;
    outline: none;
    position: relative;
    min-height: 200px;
    box-sizing: content-box;
  }
.CodeMirror-sizer {
    position: relative;
    border-right: 30px solid transparent;
    box-sizing: content-box;
  }
.CodeMirror-vscrollbar {
    position: absolute;
    z-index: 6;
    display: none;
    right: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
.CodeMirror-hscrollbar {
    position: absolute;
    z-index: 6;
    display: none;
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    overflow-x: scroll;
  }
.CodeMirror-gutter {
    white-space: normal;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: -30px;
    *zoom: 1;
    *display: inline;
    box-sizing: content-box;
  }
.CodeMirror-gutter-wrapper {
    position: absolute;
    z-index: 4;
    background: none !important;
    border: none !important;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
.CodeMirror-gutter-background {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 4;
  }
.CodeMirror-gutter-elt {
    position: absolute;
    cursor: default;
    z-index: 4;
  }
.CodeMirror-code {
    outline: none;
  }
.CodeMirror-measure {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
.CodeMirror-measure pre {
      position: static;
    }
.CodeMirror-focused .CodeMirror-selected {
      background: #d7d4f0;
    }
.CodeMirror-focused div.CodeMirror-cursors {
      visibility: visible;
    }
.CodeMirror-selected {
    background: #d9d9d9;
  }
.CodeMirror-line::-moz-selection {
    background: #d7d4f0;
  }
.CodeMirror-line::selection {
    background: #d7d4f0;
  }
.CodeMirror-line > span::-moz-selection {
      background: #d7d4f0;
    }
.CodeMirror-line > span::selection {
      background: #d7d4f0;
    }
.CodeMirror-line > span > span::-moz-selection {
        background: #d7d4f0;
      }
.CodeMirror-line > span > span::selection {
        background: #d7d4f0;
      }
@media print {
    .CodeMirror div.CodeMirror-cursors {
      visibility: hidden;
    }
  }
.CodeMirror-fullscreen {
    background: #fff;
    position: fixed !important;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    z-index: 10;
  }
.CodeMirror-sided {
    width: 50% !important;
  }
.editor-toolbar {
    position: relative;
    opacity: 0.6;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 0 10px;
    border-top: 1px solid #bbb;
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
.editor-toolbar:after {
      display: block;
      content: " ";
      height: 1px;
      margin-top: 8px;
    }
.editor-toolbar:before {
      display: block;
      content: " ";
      height: 1px;
      margin-bottom: 8px;
    }
.editor-toolbar:hover {
      opacity: 0.8;
    }
.editor-toolbar.fullscreen {
      width: 100%;
      height: 50px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding-top: 10px;
      padding-bottom: 10px;
      box-sizing: border-box;
      background: #fff;
      border: 0;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 1;
      z-index: 10;
    }
.editor-toolbar.fullscreen::before {
      width: 20px;
      height: 50px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0,
        rgba(255, 255, 255, 0) 100%
      );
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
    }
.editor-toolbar.fullscreen::after {
      width: 20px;
      height: 50px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 1) 100%
      );
      position: fixed;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
    }
.editor-toolbar a {
      display: inline-block;
      text-align: center;
      text-decoration: none !important;
      color: #2c3e50 !important;
      height: 30px;
      margin: 0;
      border: 1px solid transparent;
      border-radius: 3px;
      cursor: pointer;
      outline: 0;
      margin-right: 2px;
      font-size: 1.5em;
      width: 25px;
    }
.editor-toolbar a.active {
        background: #fcfcfc;
        border-color: #95a5a6;
      }
.editor-toolbar a:hover {
        background: #fcfcfc;
        border-color: #95a5a6;
      }
.editor-toolbar a:active {
        background: #eee;
      }
.editor-toolbar a:before {
        line-height: 30px;
      }
.editor-toolbar i.separator {
      display: inline-block;
      width: 0;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #fff;
      color: transparent;
      text-indent: -10px;
      margin: 0 6px;
    }
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
      pointer-events: none;
      background: #fff;
      border-color: transparent;
      text-shadow: inherit;
    }
@media only screen and(max-width: 700px) {
    .editor-toolbar a.no-mobile {
      display: none;
    }
  }
.editor-statusbar {
    padding: 8px 10px;
    font-size: 12px;
    color: #959694;
    text-align: right;
  }
.editor-statusbar span {
      display: inline-block;
      min-width: 4em;
      margin-left: 1em;
    }
.editor-statusbar .lines:before {
      content: "lines: ";
    }
.editor-statusbar .words:before {
      content: "words: ";
    }
.editor-statusbar .characters:before {
      content: "characters: ";
    }
.editor-preview {
    padding: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fafafa;
    z-index: 7;
    overflow: auto;
    display: none;
    box-sizing: border-box;
  }
.editor-preview > p {
      margin-top: 0;
    }
.editor-preview pre {
      background: #eee;
      margin-bottom: 10px;
    }
.editor-preview table td {
        border: 1px solid #ddd;
        padding: 5px;
      }
.editor-preview table th {
        border: 1px solid #ddd;
        padding: 5px;
      }
.editor-preview-side {
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 50%;
    top: 50px;
    right: 0;
    background: #fafafa;
    z-index: 10;
    overflow: auto;
    display: none;
    box-sizing: border-box;
    border: 1px solid #ddd;
  }
.editor-preview-side > p {
      margin-top: 0;
    }
.editor-preview-side pre {
      background: #eee;
      margin-bottom: 10px;
    }
.editor-preview-side table td {
        border: 1px solid #ddd;
        padding: 5px;
      }
.editor-preview-side table th {
        border: 1px solid #ddd;
        padding: 5px;
      }
.editor-preview-active-side {
    display: block;
  }
.editor-preview-active {
    display: block;
  }
.CodeMirror-overwrite .CodeMirror-cursor {
  }
.CodeMirror-wrap pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
  }
.cm-tab-wrap-hack:after {
    content: "";
  }
span.CodeMirror-selectedtext {
    background: none;
  }
.editor-wrapper input.title:focus {
      opacity: 0.8;
    }
.editor-wrapper input.title:hover {
      opacity: 0.8;
    }

.AnnouncementFormModal-root-ffc42c4eaab57d471566805f1e8f6efa {
  min-width: 500px;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-3)
    var(--spacing-3);
}

.AnnouncementFormModal-title-15070acc4b5357b6121fa82a424082ca {
  font-size: var(--font-size-5);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.2;
  color: var(--palette-text-500);
  margin: 0;
}

.CommentLengthConfig-commentLengthTextInput-b4dfa14910d687d339f5a941d97b7141 {
  width: 150px;
}

.CommentLengthConfig-annotation-1cc5e675656a23c7809465d0f93eeb7b {
  color: var(--palette-text-500);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  line-height: 1.125;
}

.MediaLinksConfig-ratingDesc-bd4cc679c87c2a7456422488e93c5d76 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  color: var(--palette-grey-500);
  padding-left: 26px;
}

.ReactionButton-readOnly-6058d265939b4a012ffbb05d25f02783 {
  cursor: not-allowed !important;
  color: var(--palette-text-500) !important;
  opacity: 1 !important;
}

.ReactionButton-button-f00bbe879a882dd9a0213477cbfbe691 {
  padding-left: 0;
}

.ReactionButton-reacted-984ed93b65a07b9144f8ad6073a638f1 {
  font-weight: var(--font-weight-primary-bold);
}

.ReactionButton-icon-17968b95c8d22a2a68cb7eb449f342fc {
  margin-right: var(--spacing-1);
}

.ReactionButton-totalReactions-5c75d792070e447916aec4f5cebb1972 {
  margin-left: var(--spacing-1);
}

.ReactionConfig-textInput-a50fa6cbc99d292d3a0f9fc4a6ab66c2 {
  min-width: 300px;
}

.ReactionConfig-reactionButton-8bab77b9ccee8a1670210b7a8e9926a7 {
  cursor: not-allowed !important;
  color: var(--palette-grey-500) !important;
}

.RTEConfig-disabledLabel-74ed855fc140506c466007da4020785a {
  color: var(--palette-grey-400);
}
.RTEConfig-spoilerDesc-f83a8d202f2f890307ee24e1a5ff7ff2 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  font-family: var(--font-family-primary);
  color: var(--palette-grey-400);
  padding-left: 26px;
}

.StaffConfig-textInput-6c4201a367352e22676d5a737951900f {
  min-width: 300px;
}

.GeneralConfigContainer-root-6046ad4ade2dcdefb52043f9df819070 {
  padding-top: var(--spacing-4);
}

.NewCommentersConfig-thresholdTextField-14b3215148bbd10b295fc0a74932bdbf {
  width: calc(6 * var(--mini-unit));
}

.PerspectiveConfig-thresholdTextField-bc7de229e863c7fdda03b812740ed6b8 {
  width: calc(6 * var(--mini-unit));
}

.RecentCommentHistoryConfig-thresholdTextField-dbff762c4c4b94d37737feaf1ef89c5a {
  width: calc(6 * var(--mini-unit));
}

.WordListTextArea-textArea-8fff59b7c82ac324d86e6e9ffd125776 {
  width: 100%;
  height: calc(23 * var(--mini-unit));
  padding: calc(0.5 * var(--mini-unit));
}

.BannedWordListConfig-textArea-3ba4424e536d273cf80424c221924191 {
  margin-top: var(--mini-unit);
}

.SuspectWordListConfig-textArea-5b38f68c18eb918cfa3fc36a02027124 {
  margin-top: var(--mini-unit);
}

.SlackChannel-description-64e2fabfc7077520ed7c585a85dd1eeb {
  padding-bottom: var(--spacing-1);
}

.SlackChannel-enabledCheckbox-adabfde1b638122b28f40310fdab5f9f {
  margin-right: var(--spacing-1);
}

.SlackChannel-trigger-a3cfe4a12849cdc5b81328b5c984450e {
  padding-left: var(--spacing-1);
  padding-bottom: var(--spacing-2);
}

.SlackChannel-triggerHelp-3df579ec8d180da5825948513bde5a74 {
  padding-left: 26px;
  margin: 0;
}

.SlackChannel-button-b748059558552738ec53518f55b631a7 {
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.SlackChannel-removeButton-3befa96bf96a19584837d991ef222520 {
  margin-bottom: var(--spacing-1);
}

.SlackChannel-buttonIcon-a65b11ba673c2aebcea6d9dd0fc2ea11 {
  padding-right: var(--spacing-1);
}

.SlackChannel-content-f1c28c6a06fdcbab29f4d1873f937a96 {
  padding: 0 var(--spacing-2);
}

.SlackChannel-textField-1a46c2e8b83722fc23e77fc43922c717 {
  padding-bottom: var(--spacing-4);
}

.SlackChannel-notificationsLabel-6e9ff72c7171154ebbcc4acc5e7f65c2 {
  padding-bottom: var(--spacing-1);
}

.SlackChannel-notificationToggles-d98eddc5e3a8c57ca2cf283d6b7ed488 {
  padding-bottom: var(--spacing-3);
}

.SlackConfigContainer-icon-2765892a8b78e27ed286de2c4472c588 {
  padding-right: var(--spacing-2);
}

.SlackConfigContainer-button-dd605026e8747f7cfe67be514f71ea22 {
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.ExperimentalCallOut-icon-bd96336a3706fa1d62c1b4f72f8c777b {
  margin-right: var(--spacing-1);
}

.ExperimentalCallOut-title-26e6e68aaeae84c652eb5f78f29813e3 {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}


.StatusMarker-success-bd529c544f252414bf0fb1a37455cbfc {
  background-color: var(--palette-success-500);
  border-color: var(--palette-success-500);
  color: var(--palette-text-000);
}

.StatusMarker-error-672d27d77d338489c12e3cec31da9de3 {
  background-color: var(--palette-error-700);
  border-color: var(--palette-error-700);
  color: var(--palette-text-000);
  font-weight: var(--font-weight-primary-semi-bold);
}

.WebhookEndpointRow-urlColumn-0fc16975bf2b810bc4f5a920fe5dfb2b {
  width: 100%;
}

.WebhookEndpointRow-detailsButton-b4aff51ecd00ebae5acb9e3aea6abb9d {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.EventsSelectField-list-b33536a38366af197a9ead89153ee019 {
  max-height: 295px;
}

.EventsSelectField-event-93c90b173ece1784f2127aa621233f15 {
  font-family: monospace;
}

.RotateSigningSecretModal-root-c0c4933b8652dfb800591d30787c5a0d {
  width: 500px;
}

.RotateSigningSecretModal-title-f02ed7dcd166d6a3c09c673418ccc830 {
  font-size: var(--font-size-5);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.2;
}

.StatusMarker-success-476cb21001e826716be0dd3884fa3227 {
  background-color: var(--palette-success-500);
  border-color: var(--palette-success-500);
  color: var(--palette-text-000);
}

.StatusMarker-error-a2a6f3d4530b1b4bc312d32fdbe3c91b {
  background-color: var(--palette-error-700);
  border-color: var(--palette-error-700);
  color: var(--palette-text-000);
  font-weight: var(--font-weight-primary-semi-bold);
}

.ExternalModerationPhaseRow-urlColumn-6baedf9a095c65a4f6a38ea17f3cb8c8 {
  width: 100%;
}

.ExternalModerationPhaseRow-detailsButton-35522e030af7b04b080fcdfa52d9c4ce {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
}

.RotateSigningSecretModal-root-f2bfab35e48b9f705058e226048a8fa9 {
  width: 500px;
}

.RotateSigningSecretModal-title-056ac5dde6e9e8a2440db7bd84d228a9 {
  font-size: var(--font-size-5);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.2;
}

.EmbedCode-textArea-9417c7df1242733fb25beea589575695 {
  width: 100%;
  box-sizing: border-box;
  padding: calc(0.5 * var(--mini-unit));
  min-height: 400px;
  font-family: monospace;
}

.EmbedCode-copyArea-84880b9fbb7ad25d18899a8d745f4a05 {
  text-align: right;
}

.SiteDashboardTimestamp-timestamp-dc338b784a113010c123b9dfecba072e {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-1);
  color: var(--palette-text-500);
  margin-top: var(--spacing-1);
}

.SiteDashboardTimestamp-root-857f8644017e942979d7f0b6f3f16957 {
  text-align: left;
}

.DashboardBox-root-0642b753c5a8e162bdc7bb3abd19f07a {
  border: 1px solid var(--palette-grey-300);
  border-radius: 4px;
  padding: var(--spacing-3);
  font-family: var(--font-family-primary);
  flex: 1 1;
}

.TodayCompareValue-valueBoxCompareValue-683734b2c5e6ca05d30fbefd7151d9ef {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
}

.TodayCompareValue-valueBoxCompareName-32dd6cb7e076fa0c3092c514502b97fa {
  font-size: var(--font-size-1);
}

.TodayValue-valueBoxValue-042097ed86d5f19de7820391c9c365e6 {
  font-size: var(--font-size-7);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 0.57;
}

.TodayValue-valueBoxName-9e025092b29fc7503040da5e91f83b99 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
}

.Loader-root-9a1a9c60b4e5bacbc2eeb0a8b7981a27 {
  height: 100%;
}

.TodayDashboardBox-root-d219d2388cbd97dd5796148d4b2427d6 {
  flex: 1 1;
  min-height: 105px;
}

.TodayDashboardBox-icon-e87f9bc7325b5050f5e7c3829ecc4173 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
}

.TodayDashboardBox-tealIcon-ae7e850569bc66a231f40f7ce957ce19 {
  background-color: #E2FAF7;
  color: #419EA7;
}

.TodayDashboardBox-redIcon-19bd689aa1b73eb2e994fdd45838e72e {
  background-color: var(--palette-error-100);
  color: var(--palette-error-500);
}

.TodayDashboardBox-greyIcon-a7b6b8680eb6a2ef257c4ae7b9666589 {
  background-color: var(--palette-grey-200);
  color: var(--palette-grey-500);
}

.TodayDashboardBox-blueIcon-aa8749092a2c5981d34322cbf364c73c {
  background-color: #D2DCF6;
  color: #17255A;
}

.TodayDashboardBox-outer-3122eae9aa040b740d676e14fc7be523 {
  height: 100%;
}

.TodayDashboardBox-inner-1f6116980ecf962c97969ea343861543 {
  height: 100%;
}

.DashboardComponentHeading-root-0300b57851089b7e620cf143aca2bded {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  margin-top: 0;
}

.Today-heading-93b80309dde6691f89d01963010d0574 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-4);
}

.Today-valueBoxValue-8712997af80d307efeead33e1bc83f40 {
  font-size: var(--font-size-7);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 0.57;
}

.Today-valueBoxName-b540ccc61d12e2093b9b58d6015278de {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
}

.Today-valueBoxCompareValue-83434b5af2e2136c308aa0193cde927f {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
}

.Today-valueBoxCompareName-3032e5d36a7b5f098810fb787697f7c1 {
  font-size: var(--font-size-1);
}

.Today-icon-067873320b6373a424b878419cce6c0d {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Today-forumIcon-8b14b4740058d350bfdd7e669f26bec8 {
  background-color: #E2FAF7;
  color: #419EA7;
}

.SignupActivity-heading-c7b42f007413b6800a73d57cdf37a405 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
}

.SignupActivity-chart-e4f5ec5d33a13acca203bf6e0410d86b {
  font-family: var(--font-family-primary);
}

.CommentActivityTooltip-root-847327af30df9e2f972740b3a6969679 {
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid var(--palette-grey-300);
  padding: var(--spacing-3);
}

.CommentActivityTooltip-time-f528baf42d4c84b675d12ebf4e166612 {
  color: var(--palette-text-100);
  font-size: var(--font-size-2);
  margin: 0;
  font-weight: var(--font-weight-primary-semi-bold);
}

.CommentActivityTooltip-count-f58ea037cfc69b3a22deae6458ef0d1d {
  color: #419EA7;
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-primary-bold);
  margin: 0;
}

.CommentActivityTooltip-comments-b270af29096e5418e3d1f69882fe7e10 {
  color: var(--palette-text-500);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-primary-semi-bold);
  margin: 0;
}

.CommentActivity-heading-c874ea3a8e2ec388cf23490e3f5683fa {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
}

.CommentActivity-chart-cada6e657b174d0bcccc5978c5ed79cc {
  font-family: var(--font-family-primary);
}

.CommentActivity-loader-41f30bbbf504548d19c5fdb58b345c87 {
  height: 300px;
}

.CommentActivity-legend-f3e151e474878ed03689a32a467ce938 {
  color: #D44A46;
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
  margin-bottom: 0;
  position: relative;
  padding-left: var(--spacing-6);
}

.CommentActivity-legend-f3e151e474878ed03689a32a467ce938:before {
    width: 24px;
    height: 1px;
    background: #D44A46;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
  }

.Dashboard-columns-ba863bef87da67c002b059aeb767d53b {
  width: 50%;
}

.DashboardSiteContainer-button-149ca344ce68868785313fa007964f05 {
  color: #2C7B8C;
}

.DashboardContainer-root-b479264a2df83d43c9dc35b478bb01be {
  margin-top: var(--spacing-4);
}

.DashboardContainer-icon-aabe9ecd1395503b7224a52d90bd3072 {
  color: var(--palette-text-100);
  margin-top: var(--spacing-1);
  margin-left: var(--spacing-1);
}

.DashboardContainer-header-b2402c29f496c42add9d22b27f8150b3 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-6);
  color: #419EA7;
  margin: 0;
}

.Bar-root-77adf4291f7df8392a742a526ddbca0b {
  min-height: calc(var(--mini-unit) * 8);
  padding: var(--mini-unit);
  background-color: var(--palette-text-500);
  box-sizing: border-box;
}

.SubBar-root-01545d990c7a860e0226a06b9123eda5 {
  min-height: calc(var(--mini-unit) * 4);
  border-bottom: 1px solid var(--palette-divider);
}

.Title-root-4d359482fd3a012a66fe197a1072c1b2 {
  color: var(--palette-text-000);
}

.Subtitle-root-a7eea672e8c632bce9e832123066a1cd {
  color: var(--palette-text-000);
}

.Main-root-e06382b37f3dd44e0d8677097a5d38bf {
  width: 100%;
  padding: calc(2.5 * var(--mini-unit)) calc(1.5 * var(--mini-unit));
  box-sizing: border-box;
}

.ForgotPasswordContainer-container-768ee5e71f6d798674d18ab62affb24c {
  width: 350px;
  background-color: var(--palette-grey-100);
  border: 1px solid var(--palette-grey-300);
  margin-top: 70px;
  box-sizing: border-box;
}

.ForgotPasswordContainer-textLink-62596f092a07ae8eb2bc40a9e22fa211 {
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  color: #2C7B8C;
  font-size: var(--font-size-2);
  line-height: 1.14;
  text-decoration: underline;
}

.InviteCompleteForm-root-5f7783df07c0471b4393c9ed22184f57 {
  max-width: calc(42 * var(--mini-unit));
  margin: calc(3 * var(--mini-unit)) auto 0;
}

.InviteLayout-root-f5ce55ca5e1c7d80f4f070a60e90a47b {
}

.InviteLayout-logoContainer-1a8022a21303bcc838e5271a2d3e9cfc {
  position: relative;
}

.Success-root-a488ac59b99a018b9652ac2afa9208d2 {
  text-align: center;
}

.Success-link-b136db068545d1a8c865876a7bb195fe {
  display: block;
  font-size: var(--font-size-6);
  font-family: var(--font-family-primary);
}

.CompleteAccountBox-container-a3544645f6cf923e5680a2b1d6f9aa0c {
  width: 350px;
  background-color: var(--palette-grey-100);
  border: 1px solid var(--palette-grey-300);
  margin-top: 70px;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.CompleteAccountBox-header-357ed84454db16557ced7a20952b262e {
  background-color: var(--palette-text-500);
  padding: calc(1.5 * var(--mini-unit));
  color: var(--palette-text-100);
}

.CompleteAccountBox-heading3-7f9190d12e318fad7f81e55fb62ec374 {
  margin-bottom: calc(0.25 * var(--mini-unit));

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-2);
  line-height: 1.285;
  color: var(--palette-text-000);

  margin-bottom: var(--spacing-1);

  text-align: center;
}

.CompleteAccountBox-title-887e0efa7c7f8d11f0b0fa0f95175e1d {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-4);
  line-height: 1.11;
  color: var(--palette-text-000);

  text-align: center;
}

.CompleteAccountBox-main-e65935be9afb44ee8d896f721e9f8070 {
  padding: calc(2.5 * var(--mini-unit)) calc(2 * var(--mini-unit))
    calc(2 * var(--mini-unit)) calc(2 * var(--mini-unit));

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.125;
  color: var(--palette-text-100);
}

.HorizontalSeparator-root-f48f5b5741c35792cbd8f77870498670 {
  position: relative;
}
.HorizontalSeparator-hr-894cbe96ecd93b73bf4163942ea87299 {
  position: absolute;
  border: 0;
  border-top: 1px solid var(--palette-divider);
  width: 100%;
  margin: 0;
}
.HorizontalSeparator-text-16427a67a0be490848b2caa087dc0562 {
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-primary);
  line-height: 1.11;
  position: relative;
  background-color: var(--palette-grey-100);
  color: var(--palette-text-100);
  padding: 0 var(--mini-unit);
}

.HorizontalSeparator-root-36386ab6107387cc422481cec756c341 {
  position: relative;
}
.HorizontalSeparator-hr-f16a525d8c077c544fc887621b1cffee {
  position: absolute;
  border: 0;
  border-top: 1px solid var(--palette-divider);
  width: 100%;
  margin: 0;
}
.HorizontalSeparator-text-d29097a55af02077a56a235bd9ab9279 {
  position: relative;
  background-color: #f5f5f5;
  padding: 0 var(--mini-unit);

  font-size: var(--font-size-4);
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-primary);
  line-height: 1.11;
  color: var(--palette-text-100);
}

.SignInWithEmail-textLink-d1a90c5e92819eccab759563f1eefabc {
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  color: #2C7B8C;
  font-size: var(--font-size-1);
  line-height: 1.33;
  text-decoration: underline;
}

.SignInWithEmail-container-428dde0b5409779561b685067cf70253 {
  padding-bottom: var(--spacing-5);
}

.SignInWithEmail-field-4dcb524d6a04e0f59e4045ea8ab44cf7 {
  padding-bottom: var(--spacing-4);
}

.SignInWithEmail-field-4dcb524d6a04e0f59e4045ea8ab44cf7 {
  padding-bottom: var(--spacing-4);
}

.Version-container-2e9cba84c105d54ea41f90bd1ad78eec {
  width: 385px;
  background-color: var(--palette-grey-100);
  border: 1px solid var(--palette-grey-300);
  border-top: 0;
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);
  box-sizing: border-box;

  text-align: center;
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  line-height: 1.33;
  color: var(--palette-text-100);
}

.SignIn-title-3174c598f9cc400fcddd12b2ba0fd826 {
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  color: var(--palette-text-100);
  font-size: var(--font-size-3);
  line-height: 1.125;

  padding-bottom: var(--spacing-3);
}

.SignIn-orSeparator-fb6645fc583e8d69e945239817e886f0 {
  padding-bottom: var(--spacing-5);
}

.SignIn-loginButton-54822ecada325ece25982ef52598c01e {
  padding-bottom: var(--spacing-4);
}

.HotkeysModal-root-ed8fd6390b54335723ad9314d8f0f4a4 {
  min-width: 475px;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-6)
    var(--spacing-3);
}
.HotkeysModal-hotKeyContainer-d90557faae5e4158af5acc49296dd583 {
  width: 65px;
}
.HotkeysModal-title-7e313f4ad9472916ca4602951b8c62da {
  font-size: var(--font-size-5);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  line-height: 1.2;
  color: var(--palette-text-500);
  margin: 0;
}
.HotkeysModal-subTitle-87c024b737bc670f53d1a3d8a36258a3 {
  font-size: var(--font-size-4);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.11;
  color: var(--palette-text-500);
  margin: 0;
}
.HotkeysModal-hotKeyAction-babcf3a1540c77bc2bb4caf94aaaa376 {
  font-size: var(--font-size-3);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.3;
  color: var(--palette-text-500);
  margin: 0;
}
.HotkeysModal-hotKey-a690c156c370acc0b2266b093f4fba88 {
  background-color: #EFEFEF;
  border: 1px solid var(--palette-grey-400);
  color: var(--palette-text-500);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  border-radius: 3px;
  padding: var(--spacing-1);
  display: inline-block;
  line-height: 1;
}

.Field-root-c2204883079e35fcae0b1c324b5c0234 {
  width: calc(94 * var(--mini-unit));
  height: calc(4 * var(--mini-unit));
}

.Field-hasSiteSelector-399ec98032983fa6e9fc72139501f1aa {
  width: calc(84 * var(--mini-unit));
}

.Field-begin-c2ecf4c808fec975afa3027a82362548 {
  background-color: var(--palette-background-input);
  min-width: calc(4 * var(--mini-unit));
  border-top-left-radius: var(--round-corners);
  border-bottom-left-radius: var(--round-corners);
  flex-shrink: 0;
  pointer-events: none;
}

.Field-adornmentLeft-8789a3b98aac9721c68dc6dcd77ea2ba {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Field-beginStories-a22a3af53c89a6f4c44601456b2c0932 {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.5;
  color: var(--palette-text-500);
  text-transform: uppercase;
  padding-right: calc(0.25 * var(--mini-unit));
}

.Field-searchIcon-d75f4b50ed15c395c061b780f045e437 {
  padding: 0 calc(0.5 * var(--mini-unit)) 0 calc(0.75 * var(--mini-unit));
  font-weight: var(--font-weight-primary-semi-bold);
  color: var(--palette-text-500);
}

.Field-end-eef418095288166b35de5c11bf22a424 {
  min-width: calc(5 * var(--mini-unit));
  background-color: var(--palette-background-input);
  border-top-right-radius: var(--round-corners);
  border-bottom-right-radius: var(--round-corners);
  flex-shrink: 0;
}

.Field-searchButton-7635101da5bde35b3e74cc7ea1f4c957 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.28;

  background-color: #11435D;
  color: var(--palette-text-000);
  border-left: 1px solid #11435D;
  border-radius: var(--round-corners);

  padding: 0 calc(1 * var(--mini-unit));
  height: calc(4 * var(--mini-unit) - 4px);
  margin-right: var(--spacing-1);
}

.Field-searchButton-7635101da5bde35b3e74cc7ea1f4c957:disabled {
    cursor: pointer;
  }

.Field-input-d784c5944c6d4b4ae20e6a784a9a4478 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.25;

  position: relative;
  display: block;
  padding: calc(0.5 * var(--mini-unit));
  box-sizing: border-box;
  width: 100%;
  align-self: stretch;
  color: var(--palette-text-100);
  border: 0;
  background-color: var(--palette-background-input);
  margin: 0;
}

.Field-input-d784c5944c6d4b4ae20e6a784a9a4478:focus {
    outline: none;
  }

.Field-input-d784c5944c6d4b4ae20e6a784a9a4478::-moz-placeholder {
    color: var(--palette-text-100);
    opacity: 0.5;
  }

.Field-input-d784c5944c6d4b4ae20e6a784a9a4478:-ms-input-placeholder {
    color: var(--palette-text-100);
    opacity: 0.5;
  }

.Field-input-d784c5944c6d4b4ae20e6a784a9a4478::placeholder {
    color: var(--palette-text-100);
    opacity: 0.5;
  }

.Field-input-d784c5944c6d4b4ae20e6a784a9a4478:-moz-read-only {
    opacity: 0.5;
  }

.Field-input-d784c5944c6d4b4ae20e6a784a9a4478:read-only {
    opacity: 0.5;
  }

.Field-input-d784c5944c6d4b4ae20e6a784a9a4478:disabled {
    opacity: 0.5;
  }

.Field-inputWithTitle-d98258b7c470526f1fff9bf30de7fb1c::-moz-placeholder {
    font-weight: var(--font-weight-primary-bold);
    color: var(--palette-text-500);
    opacity: 1;
  }

.Field-inputWithTitle-d98258b7c470526f1fff9bf30de7fb1c:-ms-input-placeholder {
    font-weight: var(--font-weight-primary-bold);
    color: var(--palette-text-500);
    opacity: 1;
  }

.Field-inputWithTitle-d98258b7c470526f1fff9bf30de7fb1c::placeholder {
    font-weight: var(--font-weight-primary-bold);
    color: var(--palette-text-500);
    opacity: 1;
  }

.Group-root-d413367d5b3d46e3daf4fc7d558d890f {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Group-title-c8e703722d7582b958c5cbe39356ecb7 {
  display: flex;
  align-items: center;
  height: calc(5 * var(--mini-unit));
  padding-left: calc(1.5 * var(--mini-unit));
  background: #11435D;
  color: var(--palette-text-000);

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.25;
  text-transform: uppercase;
}

.Group-light-53d8108fbd4e63e2db4eba5c2b0d0e2f {
  background: var(--palette-grey-500);
}

.Bar-root-887bc4ac7b5ced270973332d6729f874 {
  height: calc(6 * var(--mini-unit));
  background-color: #419EA7;
  margin-top: -1px;
  border-bottom: 0;
}

.Bar-bumpZIndex-7c04d3f070b7775b92db151e855e8e1c {
  z-index: 100;
}

.Bar-popover-fa32fe1d07620fbaceed1ed69150fbd1,
.Bar-popoverNarrow-2e295a2daf9b2e6d337e2262cacb6171 {
  border: 0;
}

.Bar-popover-fa32fe1d07620fbaceed1ed69150fbd1 {
  width: calc(94 * var(--mini-unit));
}

.Bar-popoverNarrow-2e295a2daf9b2e6d337e2262cacb6171 {
  width: calc(84 * var(--mini-unit));
}

.Bar-listBox-90c5c7a9188f3bf880df00a2322381e2 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Bar-groupIcon-02688b999a142e9afac2f52ca57fbc05 {
  margin-right: var(--spacing-2);
}

.ModerateAllOption-root-2514596fd05133043f0f9450550a05aa:not(:first-child) {
    border-top: 1px solid var(--palette-divider);
  }
  .ModerateAllOption-root-2514596fd05133043f0f9450550a05aa[aria-selected="true"] {
    outline-width: 3px;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }

.ModerateAllOption-link-f89cbdcb38d4148927eb9c62709586e2 {
  justify-content: center;
  min-height: calc(4 * var(--mini-unit));
  font-family: var(--font-family-primary);
  font-weight: var(--font-family-primary-semi-bold);
  font-size: var(--font-size-3);
  line-height: 1;
}

.ModerateAllOption-icon-502f2634d2c763c47820c446638c4a48 {
  font-weight: var(--font-weight-primary-semi-bold);
  margin-top: -2px;
}

.ModerateAllOption-button-a937dea2fb2dfc5db096b44ed1f929fc {
  border-radius: 0;
}

.Option-root-414d1c76465f1f11678171323f4c390c:not(:first-child) {
    border-top: 1px solid var(--palette-divider);
  }

.Option-root-414d1c76465f1f11678171323f4c390c[aria-selected="true"] .Option-container-ab75347143c07be52d014d2074ce0a07 {
    outline-width: 3px;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }

.Option-container-ab75347143c07be52d014d2074ce0a07 {
  min-height: calc(4 * var(--mini-unit));
  padding: var(--mini-unit) calc(2.5 * var(--mini-unit));
  box-sizing: border-box;
}

.Option-container-ab75347143c07be52d014d2074ce0a07:hover {
    background: var(--palette-grey-100);
  }

.Option-title-400a3d593d00d887ba10fbbe60e772d5 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  line-height: 1;
  color: var(--palette-text-900);
}

.Option-titleWithDetails-5d43b48d2e17d948ebefd8ba15759ccd {
  font-size: var(--font-size-3);
}

.Option-details-24e34c3a5f8a9dc94abacefa038964a7 {
  padding-top: 8px;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.14;
  color: var(--palette-text-100);
}

.Option-link-d3db30934880d8296bbbd9ab6ee0ae6b {
  display: block;
  text-decoration: none;
}

.OptionDetail-bold-ec82ca93df6db1aceed8b2a80eb03ccf {
  font-weight: var(--font-weight-primary-bold);
}

.OptionDetail-muted-5a27e32d01226227fa606a0a7160accc {
  font-weight: var(--font-weight-primary-bold);
  color: var(--palette-grey-400);
}

.SeeAllOption-root-3a0509e721758213308bb28659c25fcc[aria-selected="true"] {
    outline-width: 3px;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }

.SeeAllOption-link-d269016047850b4ae2d648687008efc8 {
  display: flex;
  justify-content: center;
  align-items: center;

  background: #11435D;
  min-height: calc(3 * var(--mini-unit));

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  line-height: 1;
  color: #EFEFEF;

  text-decoration: none;
  text-transform: uppercase;
}

.SeeAllOption-link-d269016047850b4ae2d648687008efc8:hover {
    background: #2C7B8C;
  }

.SeeAllOption-icon-1d4056f608fdd1a708bf99a032137b4f {
  font-weight: var(--font-weight-primary-semi-bold);
  padding-left: calc(0.5 * var(--mini-unit));
  line-height: 1.2307692307692308em;
  margin-top: -2px;

  color: #EFEFEF;
}

.QueueSort-root-ddab32569348ca1580840dd7f9eb443e {
  float: right;
}

.PaginatedSelect-root-e5324484a0d07cea10363511fab22b7a {
  margin-top: var(--spacing-2);
}

.PaginatedSelect-dropdown-17bb9fddec60accceafe3529c1d3282d {
  max-height: 15em;
  width: calc(20 * var(--mini-unit));
  overflow: auto;
}

.PaginatedSelect-wrapper-2d15e03d78c072298de487c0210ff14f {
  overflow: hidden;
  /* adjust for button line-height being > 1 */
  margin-top: -2px;
}

.PaginatedSelect-button-0b7968054da11bfa11f9fdeb0b6d8160 {
  color: var(--palette-text-500) !important;
  border-width: 0;
  width: calc(20 * var(--mini-unit));
  margin-right: calc(var(--spacing-1) / 2);
  font-size: var(--font-size-3);
  line-height: 1.2;
  justify-content: space-between;
  background: var(--palette-background-input);
}

.PaginatedSelect-buttonIconLeft-3e942f7655e5bffa41dba38891da4ef1 {
  width: 20px;
  margin-right: calc(var(--spacing-1) / 2);
}

.PaginatedSelect-buttonIconRight-3b8e018f5aadb127c013657b72cd26f4 {
  width: 16px;
}

.PaginatedSelect-buttonIconLeft-3e942f7655e5bffa41dba38891da4ef1,
.PaginatedSelect-buttonIconRight-3b8e018f5aadb127c013657b72cd26f4 {
  /* adjust for button line-height being > 1 */
  margin-top: -2px;
}

.PaginatedSelect-buttonText-8ba602465aedd2fd7930f59a61c15281 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.SectionSelectorSection-root-3f23db56aa3e71a240e9f3096116c6d0 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-1);
  color: var(--palette-text-500);
  line-height: 1.33;
  display: block;
  text-decoration: none;
  padding: var(--spacing-2) var(--spacing-4);
}

.SectionSelectorSection-root-3f23db56aa3e71a240e9f3096116c6d0:hover {
  background: #E2FAF7;
}

.SectionSelectorSection-active-ea51b7550341a30663e814949d8d1771 {
  font-weight: var(--font-weight-primary-bold);
}

.SectionSelector-button-4145d11e628ab5a84c404b49ccbf41bb {
  height: calc(4 * var(--mini-unit));
}

.SectionSelector-buttonText-fc09be073fe9a41854872baa09e92243 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.SiteSelectorSite-root-40bb1feeaa5a65e3ea40d438b315d557 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-1);
  color: var(--palette-text-500);
  line-height: 1.33;
  display: block;
  text-decoration: none;
  padding: var(--spacing-2) var(--spacing-4);
}

.SiteSelectorSite-root-40bb1feeaa5a65e3ea40d438b315d557:hover {
  background: #E2FAF7;
}

.SiteSelectorSite-active-710e93f1c4419f64cf94a667baad897d {
  font-weight: var(--font-weight-primary-bold);
}

.SiteSelector-button-a768f623065f2348f5081e8296c5fae2 {
  height: calc(4 * var(--mini-unit));
}

.SiteSelector-buttonText-741f83411ea18424936ff7af2dd10f35 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Moderate-background-b8bf42a497fe427be301596e0da9095f {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--palette-grey-100);
}

.Moderate-main-fa0ba1a124d25ce628b50909bc80d1eb {
  margin: var(--spacing-5) 0 var(--spacing-7) 0;
  display: flex;
  justify-content: center;
}

.Moderate-subBar-9dc4ad9bf1c9797c31409a6b3d670edc {
  background-color: var(--palette-grey-100);
}

.EmptyMessage-root-c4cf2b04880ae2b1792d0bf552eb34a7 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.3;
  text-align: center;
}

.Circle-sizeReg-52dff6e34977626dc332a4e6082f2491 {
  width: 8px;
  height: 8px;
}

.Circle-sizeSmall-5a3ea7702eae5015a7245e79cf319c3d {
  width: 4px;
  height: 4px;
}

.Circle-colorReg-878e2d0c21a3740c155a4bcb260c0ffe {
  color: var(--palette-grey-500);
}

.Circle-colorLight-7f08ea675154c8f13d7264dece0f6a99 {
  color: var(--palette-grey-400);
}

.Circle-root-8936e96b685dc68df6e025037e362095 {
  position: relative;
}

.Circle-circle-c66540763cd628c4c4072a71cf895565 {
  fill: currentColor;
  stroke: currentColor;
  position: absolute;
  left: 0;
  top: 0;
}

.Line-root-5346cd092505c8e4b13ed1fa3653f163 {
  border-left: 1px solid var(--palette-grey-500);
  border-right: 1px solid var(--palette-grey-500);
  flex: 1 1;
}

.Line-dotted-08bd07501cd9a47666ed8b68f0cefacc {
  border-left-style: dotted;
}

.ConversationModalCommentContainer-root-76fddb282d33e8e0651971031ee159e0 {
  flex: 1 1;
}
.ConversationModalCommentContainer-line-192392d785764fdc7a8cc0757479930c {
  border-color: var(--palette-grey-400);
}
.ConversationModalCommentContainer-adornments-405fdbe2d9b553ca535470601df4285a {
  margin-right: var(--spacing-2);
}
.ConversationModalCommentContainer-circle-7fe62ab1aae289cf41fb00728f951d39 {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}
.ConversationModalCommentContainer-highlightedCircle-6349d1892230017e20d8869e6f3782d3 {
  margin-top: var(--spacing-1);
}
.ConversationModalCommentContainer-highlighted-e623922efa6091c3c9b77cf44b067651 {
  background-color: #E2FAF7;
  padding: var(--spacing-2);
  padding-top: var(--spacing-1);
  margin-bottom: var(--spacing-2);
}
.ConversationModalCommentContainer-commentText-62d66cd5e36a093554abab2ec762aff9 {
  color: var(--palette-text-500);
}
.ConversationModalCommentContainer-showReplies-e325d78d39b6975efba164a3605db504 {
  padding-left: var(--spacing-2);
}

.ConversationModalContainer-root-848411bf0c848366612c453b5afb9788 {
}
.ConversationModalContainer-topCircle-8be6d98a1d53dec34d6d560c2ecc34dc {
  margin-right: var(--spacing-2);
}
.ConversationModalContainer-bottomCircleContainer-5d2b23d01294c823dce60ea0f23cb21d {
  width: 8px;
  padding-top: var(--spacing-1);
}

.ConversationModalHeaderContainer-title-83f2a1c92fde51f203538f69c9e0dd4a {
  font-size: var(--font-size-4);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.11;
  color: var(--palette-text-900);
  margin: 0;
}

.ConversationModalHeaderContainer-conversationTitle-e5d207bfd44e9b58b54bf9c17d63e8bb {
  font-weight: var(--font-weight-primary-semi-bold);
  text-transform: uppercase;
  display: block;
  margin-bottom: var(--spacing-2);
}

.ConversationModalRepliesContainer-comment-54fae55436f20d7a83b91276d17ddcdd {
  padding-left: calc(var(--spacing-4));
}

.ConversationModalRepliesContainer-footer-5a4be3ada0a843087a8f56606f0043e1 {
  padding-left: calc(var(--spacing-4));
}

.ConversationModalQuery-root-e56db2685c49e29b266383f02c139c09 {
  padding: 0;
  width: 635px;
}

.ConversationModalQuery-body-3bb9e9d32a35692932954dc1b33cb847 {
  padding: var(--spacing-4);
}

.QueueWrapper-exitTransition-e45ae2887b4cd75d5e6f5c5de5f0a5d6 {
  opacity: 1;
  transition: 300ms opacity;
}

.QueueWrapper-exitTransitionSingle-ca92b983e461f1253247ac2cb15d73d9 {
  opacity: 1;
}

.QueueWrapper-exitTransitionActive-28cfd16555695485ce08e003d697b150 {
  opacity: 0;
}

.QueueWrapper-exitTransitionDone-64a9e8dda869a876db2de370bdb08b4c {
  opacity: 0;
}

.Queue-root-63888c8d2c386d08e0a8cf47279a63fa {
  width: calc(94 * var(--mini-unit));
  position: relative;
}

.Queue-viewNewButtonContainer-0f760f25abd8c77979a62b3d1b8e109a {
  position: absolute;
  width: 100%;
}

.Queue-viewNewButton-4647243c9afeb56a472f20b83ab09972 {
  position: absolute;
  z-index: 10;
  top: -16px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.ForReviewQueueRowContainer-timeColumn-8c5eb1b5ca5b9257b27a73a19706dcf9 {
  vertical-align: top;
  white-space: nowrap;
}

.ForReviewQueueRowContainer-commentContainer-9294770ee53a12580c22fb5d1f6db70a {
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.4;
}

.ForReviewQueueRowContainer-reportedByContainer-a2371668b3107e35fc44b2b64384e775 {
  width: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.4;
}

.ForReviewQueueRowContainer-descriptionColumn-10d98b6989fd87fe6b1f1eaf92c5963c {
  vertical-align: top;
  word-break: break-word;
}

.ForReviewQueueRowContainer-column-bb1d0d4aea43bbfae9d4f13cf73686c0 {
  vertical-align: top;
}

.ForReviewQueueRowContainer-reviewedColumn-1bd370c0dd983570f25e06f4617476b5 {
  vertical-align: top;
  padding-top: 8px;
}

.ReviewButton-root-652a88586806f5e6e3450e6d0feb42ee {
  border: 2px solid var(--palette-grey-400);
  box-sizing: border-box;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
    color: var(--palette-text-000);
}
.ReviewButton-root-652a88586806f5e6e3450e6d0feb42ee:not(:disabled):active {
    background-color: var(--palette-primary-600);
  }
.ReviewButton-readOnly-83a33f95be5319a406d1ccc4c795ccb4 {
  background-color: transparent;
  border-color: var(--palette-grey-400);
}
.ReviewButton-readOnly-83a33f95be5319a406d1ccc4c795ccb4:hover {
    cursor: not-allowed;
  }
.ReviewButton-readOnly-83a33f95be5319a406d1ccc4c795ccb4 {
  background-color: transparent;
  border-color: #CBD1D2;
}
.ReviewButton-readOnly-83a33f95be5319a406d1ccc4c795ccb4:hover {
    cursor: not-allowed;
  }
.ReviewButton-checked-0bed9c1cdf10485ab6484131f87e392c {
  background-color: var(--palette-primary-600);
  border-color: var(--palette-primary-600);
  color: var(--palette-text-000);
}
.ReviewButton-icon-a448377aa85c046d3ac259598770a0fd {
  font-weight: var(--font-weight-primary-semi-bold);
  color: inherit;
}

.ForReviewQueueRoute-table-11f5dc4747d885d8c6f8d190ad78bad8 {
  width: 900px;
}

.ForReviewQueueRoute-column-52ffe019e0f47e715a8d2b53671e4348 {
  white-space: nowrap;
}

.ForReviewQueueRoute-background-d7326568d3d2cdd337a435954d07f8df {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: white;
}

.NotFound-root-fb069542eaffcde9277a105cfb203425 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-5);
  line-height: 1.4;
}

.SingleModerate-background-7695a431f769658c46220675adde42d3 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--palette-grey-100);
}
.SingleModerate-main-acec6970e689266132b271d00cd72fcf {
  margin: calc(2 * var(--mini-unit)) 0 calc(4 * var(--mini-unit)) 0;
  display: flex;
  justify-content: center;
}
.SingleModerate-subBar-8d3468bcfd1a2c563f4b1166363e0594 {
  height: calc(4 * var(--mini-unit));
  background-color: #11435D;
  margin-top: -1px;
}
.SingleModerate-subBarBegin-a65e0526fb057fc65a2040e434c6ebc5 {
  position: absolute;
  left: 0;
  font-family: var(--font-family-primary);
  text-decoration: none;
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  text-transform: uppercase;
  color: var(--palette-text-000);
}
.SingleModerate-subBarTitle-c2f2ecee7cada25b7b57cee8df97bf3b {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  text-transform: uppercase;
  color: var(--palette-text-000);
}

.SiteFilterOption-root-91d274fb6b4bd155f916d214c25d2a29 {
  display: block;
  font-weight: var(--font-weight-primary-regular);
  padding: var(--spacing-2);
}

.SiteFilterOption-active-e7c5d7da260267a71a7732f660d7f464 {
  font-weight: var(--font-weight-primary-bold);
}

.SiteFilterSelected-root-6ab1eec9e154181d88e75427db3609b6 {
  font-weight: var(--font-weight-primary-regular);
}

.SiteFilter-buttonText-c824eea747b4ad43741b9506b7d241eb {
  font-weight: var(--font-weight-primary-regular);
}

.SiteFilter-root-698a71cc3147b463e4fbfb79db786f0a {
  border-radius: var(--round-corners);
  border: 1px solid var(--palette-grey-500);
  height: 34px;
}

.EmptyMessage-root-8936ac34fcda4ec6e81c9231f497b669 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.3;
  text-align: center;
}

.NoMatchMessage-root-99754c6976d1c7223f40262047219037 {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1.3;
  text-align: center;
}

.StoryStatusText-root-9766aa9fa324fb26d3ca67ac870eea87 {
  font-weight: var(--font-weight-primary-regular);
  color: var(--palette-text-100);
}

.StoryStatusText-open-923bcf3bd7f6f74b225ff4baecd51d08 {
  font-weight: var(--font-weight-primary-regular);
  color: var(--palette-success-500);
}

.StoryRow-titleColumn-8ab3fb49769f2665e1373b9a6ef0c6bd {
  vertical-align: top;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 15px;
}
.StoryRow-statusColumn-398ccb5b6db8bfba98fd2deccded9b8d {
}
.StoryRow-siteColumn-71834f584890ac799e85f0ec576af027 {
}
.StoryRow-meta-52ef6c53dccdc95e690f79aab1b98998 {
  color: var(--palette-text-100);
}
.StoryRow-metaElement-6c3f7e1a117b6ca0bc750d6848817fe7 {
  margin-right: var(--spacing-1);
}
.StoryRow-authorName-a3a9efa33658d15af02631754dd2b3ea {
  font-weight: var(--font-weight-primary-semi-bold);
}
.StoryRow-readingNow-8b88f77365f3f65f9610bb235989f4c4 {
  color: var(--palette-success-500);
}
.StoryRow-reportedCountColumn-d7d4f65fc3becf6284b7c87ed55dbc16,
.StoryRow-pendingCountColumn-8c729a2ef39f11a4f194b2d2913beecb,
.StoryRow-totalCountColumn-a3a40381cdc906036c06cd4d85db875f,
.StoryRow-actionsColumn-8777ff3ab0c274fac67a38697a4ba32a {
  text-align: center;
}
.StoryRow-boldColumn-abfbd7c657511904512d66fd3322e11e {
  font-weight: var(--font-weight-primary-semi-bold);
}

.StoryTable-titleColumn-8ff6be78f6cf87d7e20de3e53f11a6ae {
  width: 60%;
}

.StoryTable-titleColumnNarrow-de10cfad47123a22e775b4875160f456 {
  width: 42.5%;
}

.StoryTable-statusColumn-1bf7cf7064ed742347142d4066e4f8ca {
  width: 14%;
}

.StoryTable-reportedCountColumn-4fe772478e8c1f63f752f78a1548b728 {
  width: 8%;
}

.StoryTable-pendingCountColumn-df38f0aeaf0b7248f0d8268ebade2690 {
  width: 8%;
}

.StoryTable-totalCountColumn-1eeca25dc7cd2f7b9751b7fe9cfaedc9 {
  width: 8%;
}

.StoryTable-actionsColumn-0d948a162cfe54ad926649e85243d98d {
  width: 8%;
}

.StoryTable-clickToModerate-73b06e9d7795ebe8ed1c7596e290d54e {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-semi-bold);
  color: var(--palette-text-100);
}

.StoryTable-reportedCountColumn-4fe772478e8c1f63f752f78a1548b728,
.StoryTable-pendingCountColumn-df38f0aeaf0b7248f0d8268ebade2690,
.StoryTable-totalCountColumn-1eeca25dc7cd2f7b9751b7fe9cfaedc9 {
  text-align: center;
}

.StoryTableFilter-textField-fbd66ba97cebbd8188de7685bbdfeba3 {
  width: calc(45 * var(--mini-unit));
}

.StoryTableFilter-adornment-67018438087934911d19c33e418f1c89 {
  padding: 0 var(--mini-unit);
}

.Stories-root-35a62e9ebca01004a515f7718e3719a4 {
  max-width: 950px;
  margin-top: calc(3 * var(--mini-unit));
  margin-bottom: calc(3 * var(--mini-unit));
}

@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/media/MaterialIcons-Regular.570eb83859dc23dd0eec423a49e147fe.woff2)
      format("woff2"),
    url(../../assets/media/MaterialIcons-Regular.012cf6a10129e2275d79d6adac7f3b02.woff)
      format("woff")
}
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-display: block;
    font-weight: 300;
    src: url(../../assets/media/source-sans-pro-latin-300.ede18477b85a5d781cd2f4001ecc5e67.woff2)
      format("woff2"),
    url(../../assets/media/source-sans-pro-latin-300.a5002963b0570a073e28156403c78670.woff)
      format("woff")
}
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-display: block;
    font-weight: 300;
    src: url(../../assets/media/source-sans-pro-latin-300italic.c179c8f264df4b31eab6c71a532f7599.woff2)
      format("woff2"),
    url(../../assets/media/source-sans-pro-latin-300italic.f939f20b37caaa8e99bcd2e0ef22436c.woff)
      format("woff")
}
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-display: block;
    font-weight: 400;
    src: url(../../assets/media/source-sans-pro-latin-400.899c8f78ce650d4009d42443897aa723.woff2)
      format("woff2"),
    url(../../assets/media/source-sans-pro-latin-400.5cc3aae674ea3b199313b3b83bd795bc.woff)
      format("woff")
}
@font-face {
    font-family: "Source Sans Pro";
    font-style: italic;
    font-display: block;
    font-weight: 400;
    src: url(../../assets/media/source-sans-pro-latin-400italic.dac2fca7ba8512baa24172f1171c961f.woff2)
      format("woff2"),
    url(../../assets/media/source-sans-pro-latin-400italic.86b2389fa562da6b9425271d1833d490.woff)
      format("woff")
}
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-display: block;
    font-weight: 600;
    src: url(../../assets/media/source-sans-pro-latin-600.c85615b296302af51e683eecb5e371d4.woff2)
      format("woff2"),
    url(../../assets/media/source-sans-pro-latin-600.a75563d7b9e5b1db163971b9a2e66216.woff)
      format("woff")
}
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-display: block;
    font-weight: 700;
    src: url(../../assets/media/source-sans-pro-latin-700.1a4bcb3ec9c508d478d4dbf6b56f6208.woff2)
      format("woff2"),
    url(../../assets/media/source-sans-pro-latin-700.b03f2ec28f8e60e61974dd8c57610e5b.woff)
      format("woff")
}
@font-face {
    font-family: "Manuale";
    font-style: normal;
    font-display: block;
    font-weight: 400;
    src: url(../../assets/media/manuale-latin-400.e7f73a8bdbf34a6f7a7c4cc89075b11f.woff2) format("woff2"),
    url(../../assets/media/manuale-latin-400.58d587cc2fdebda269555dd7521fafc9.woff) format("woff")
}
@font-face {
    font-family: "Manuale";
    font-style: normal;
    font-display: block;
    font-weight: 600;
    src: url(../../assets/media/manuale-latin-600.98a069ac73f149bca0b2f1bbd99188c3.woff2) format("woff2"),
    url(../../assets/media/manuale-latin-600.997a27166e6b866f69561066bb03ebf4.woff) format("woff")
}
@font-face {
    font-family: "Manuale";
    font-style: normal;
    font-display: block;
    font-weight: 700;
    src: url(../../assets/media/manuale-latin-700.238aa800b36e70821535f7e07e78a862.woff2) format("woff2"),
    url(../../assets/media/manuale-latin-700.9c69bf2458f8cd9d33c985e1a78f1ccc.woff) format("woff")
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-display: block;
    font-weight: 400;
    src: url(../../assets/media/nunito-latin-400.508e414e3d3bc41666826fee46c7d881.woff2) format("woff2"),
    url(../../assets/media/nunito-latin-400.e5875b853d135f2a82ceae7ac537b6f4.woff) format("woff")
}
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-display: block;
    font-weight: 700;
    src: url(../../assets/media/nunito-latin-700.a22acb48f45d03d672bf5b9389363a83.woff2) format("woff2"),
    url(../../assets/media/nunito-latin-700.7d90308f9bdf7321be5e28d017a5ade5.woff) format("woff")
}
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: block;
    font-weight: 400;
    src: url(../../assets/media/open-sans-latin-400.33543c5cc5d88f5695dd08c87d280dfd.woff2) format("woff2"),
    url(../../assets/media/open-sans-latin-400.de0869e324680c99efa1250515b4b41c.woff) format("woff")
}
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: block;
    font-weight: 600;
    src: url(../../assets/media/open-sans-latin-600.819af3d3abdc9f135d49b80a91e2ff4c.woff2) format("woff2"),
    url(../../assets/media/open-sans-latin-600.449d681cd6006390e1bee3c3a660430b.woff) format("woff")
}
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-display: block;
    font-weight: 700;
    src: url(../../assets/media/open-sans-latin-700.0edb76284a7a0f8db4665b560ee2b48f.woff2) format("woff2"),
    url(../../assets/media/open-sans-latin-700.1f85e92d8ff443980bc0f83ad7b23b60.woff) format("woff")
}
:root {
    --round-corners: 3px;
    --palette-background-body: #FFFFFF;
    --palette-background-popover: #FFFFFF;
    --palette-background-tooltip: #65696B;
    --palette-background-input: #FFFFFF;
    --palette-background-input-disabled: #EFEFEF;
    --palette-text-100: var(--palette-text-secondary, #65696B);
    --palette-text-500: var(--palette-text-primary, #353F44);
    --palette-text-900: var(--palette-text-dark, #14171A);
    --palette-text-000: var(--palette-text-light, #FFFFFF);
    --palette-text-placeholder: var(--palette-grey-lighter, #9FA4A6);
    --palette-text-input-disabled: var(--palette-grey-lighter, #9FA4A6);
    --palette-grey-100: var(--palette-grey-lightest, #F4F7F7);
    --palette-grey-200: var(--palette-grey-lightest, #EAEFF0);
    --palette-grey-300: var(--palette-grey-lighter, #CBD1D2);
    --palette-grey-400: var(--palette-grey-lighter, #9FA4A6);
    --palette-grey-500: var(--palette-grey-main, #65696B);
    --palette-grey-600: var(--palette-grey-dark, #49545C);
    --palette-grey-700: var(--palette-grey-darkest, #32404D);
    --palette-grey-800: var(--palette-grey-darkest, #202E3E);
    --palette-grey-900: var(--palette-grey-darkest, #132033);
    --palette-error-100: var(--palette-error-lightest, #FCE5D9);
    --palette-error-200: var(--palette-error-lighter, #FAC6B4);
    --palette-error-300: var(--palette-error-lighter, #F29D8B);
    --palette-error-400: var(--palette-error-light, #E5766C);
    --palette-error-500: var(--palette-error-main, #D53F3F);
    --palette-error-600: var(--palette-error-main, #B72E39);
    --palette-error-700: var(--palette-error-dark, #991F34);
    --palette-error-800: var(--palette-error-darkest, #7B142E);
    --palette-error-900: var(--palette-error-darkest, #660C2B);
    --palette-success-100: var(--palette-success-lightest, #D8F9D5);
    --palette-success-200: var(--palette-success-lighter, #ADF3AD);
    --palette-success-300: var(--palette-success-lighter, #7CDB85);
    --palette-success-400: var(--palette-success-light, #54B767);
    --palette-success-500: var(--palette-success-main, #268742);
    --palette-success-600: var(--palette-success-main, #1B743D);
    --palette-success-700: var(--palette-success-dark, #136138);
    --palette-success-800: var(--palette-success-darkest, #0C4E32);
    --palette-success-900: var(--palette-success-darkest, #07402E);
    --palette-warning-100: var(--palette-warning-main, #FFFACC);
    --palette-warning-500: var(--palette-warning-main, #FFE91F);
    --palette-warning-900: var(--palette-warning-main, #FFCC15);
    --palette-divider: rgba(0, 0, 0, 0.12);
    --palette-primary-100: #E2FAF7;
    --palette-primary-200: #C2F9EA;
    --palette-primary-300: #9FECDF;
    --palette-primary-400: #81DBD3;
    --palette-primary-500: #59C3C3;
    --palette-primary-600: #419EA7;
    --palette-primary-700: #2C7B8C;
    --palette-primary-800: #1C5B71;
    --palette-primary-900: #11435D;
    --font-family-primary: var(--font-family-sans-serif, "Open Sans", sans-serif);
    --font-family-secondary: var(--font-family-serif, "Nunito");
    --font-weight-primary-bold: var(--font-weight-bold, 700);
    --font-weight-primary-semi-bold: var(--font-weight-medium, 600);
    --font-weight-primary-regular: var(--font-weight-light, 300);
    --font-weight-secondary-bold: var(--font-weight-bold, 700);
    --font-weight-secondary-regular: var(--font-weight-light, 300);
    --font-size-1: 0.75rem;
    --font-size-2: 0.875rem;
    --font-size-3: 1rem;
    --font-size-4: 1.125rem;
    --font-size-5: 1.25rem;
    --font-size-6: 1.5rem;
    --font-size-7: 1.75rem;
    --font-size-8: 2rem;
    --font-size-9: 2.25rem;
    --font-size-icon-xl: 2.25rem;
    --font-size-icon-lg: 1.5rem;
    --font-size-icon-md: 1.125rem;
    --font-size-icon-sm: 0.875rem;
    --font-size-icon-xs: 0.75rem;
    --shadow-popover: var(--elevation-main, 1px 0px 4px rgba(0, 0, 0, 0.25));
    --spacing-1: 4px;
    --spacing-2: 8px;
    --spacing-3: 12px;
    --spacing-4: 16px;
    --spacing-5: 24px;
    --spacing-6: 32px;
    --spacing-7: 44px;
    --spacing-8: 60px;
    --spacing-9: 84px;
    --mini-unit-small: 4;
    --mini-unit-large: 8;
    --mini-unit: calc(1px * var(--mini-unit-small))
}
@media (min-width: 320px) {
    :root {
        --mini-unit: calc(1px * var(--mini-unit-large))
    }
}

/*# sourceMappingURL=admin.f2ea00e264213048fc99062d214ae2f2.css.map*/